import * as Sentry from "@sentry/react";
import Iframe, { iframeLoader } from "components/iframe";
import client from "libs/client";
import queryClient from "libs/queryClient";
import ForgottenPasswordPage from "pages/ForgottenPasswordPage";
import RedirectPage, { loader as redirectLoader } from "pages/RedirectPage";
import ResetPasswordPage from "pages/ResetPasswordPage";
import TeamsPage from "pages/TeamsPage";
import ClubsIFramePage from "pages/clubsIframe";
import NotFoundPage from "pages/errorPages/NotFoundPage";
import ErrorPage from "pages/errorPages/errorPage";
import LoginPage from "pages/login";
import { ProductManagement } from "pages/productManagement/Index";
import { RootPage, loader as rootLoader } from "pages/root";
import { UserPage } from "pages/userPage";
import { VideoUploadMetadataPage } from "pages/videoUploads/VideoUploadMetadataPage";
import { VideoUploadStatusPage } from "pages/videoUploads/VideoUploadStatusPage";
import { VideoUploadsAllPage } from "pages/videoUploads/VideoUploadsAllPage";
import { VideoUploadsErroredPage } from "pages/videoUploads/VideoUploadsErroredPage";
import { VideoUploadsFinishedPage } from "pages/videoUploads/VideoUploadsFinishedPage";
import { VideoUploadsInProcessingPage } from "pages/videoUploads/VideoUploadsInProcessingPage";
import { VideoUploadsLayoutPage } from "pages/videoUploads/VideoUploadsLayoutPage";
import VideoUploadsTestPage from "pages/videoUploads/VideoUploadsTestPage";
import { VideoUploadsWithoutApprovalPage } from "pages/videoUploads/VideoUploadsWithoutApprovalPage";
import { RouterProvider, createBrowserRouter, useLocation } from "react-router-dom";

function IFramePage() {
	const location = useLocation();
	return <Iframe url={location.search ? `${location.pathname}${location.search}` : location.pathname} />;
}

const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter);

export const router = sentryCreateBrowserRouter([
	//REDIRECTS
	{
		path: "/admin/club-amateur/:id/teams",
		loader: redirectLoader,
		element: <RedirectPage />,
	},
	{
		path: "/",
		element: <RootPage />,
		loader: rootLoader(queryClient, client),
		errorElement: <ErrorPage />,
		children: [
			{
				index: true,
				loader: iframeLoader,
				element: <ClubsIFramePage />,
			},
			{
				path: "/product-management",
				element: <ProductManagement />,
			},
			{
				path: "/video-test",
				element: <VideoUploadsTestPage />,
			},
			{
				path: "/admin/clubs-amateur",
				loader: iframeLoader,
				element: <IFramePage />,
			},
			{
				path: "/admin/videos",
				loader: iframeLoader,
				element: <IFramePage />,
			},
			{
				path: "/admin/clubs-pro",
				loader: iframeLoader,
				element: <IFramePage />,
			},
			{
				path: "/admin/club-amateur/:id",
				loader: iframeLoader,
				element: <IFramePage />,
			},
			{
				path: "/admin/clubs-amateur/add",
				loader: iframeLoader,
				element: <IFramePage />,
			},
			{
				path: "/admin/club-amateur/:id/users",
				loader: iframeLoader,
				element: <IFramePage />,
			},
			{
				path: "/admin/club-amateur/:id/users/:id/new-password",
				loader: iframeLoader,
				element: <IFramePage />,
			},
			{
				path: "/club/:id/teams",
				element: <TeamsPage />,
			},
			{
				path: "/admin/club-amateur/:id/match/:id",
				loader: iframeLoader,
				element: <IFramePage />,
			},
			{
				path: "/admin/club-amateur/:id/planification-match",
				loader: iframeLoader,
				element: <IFramePage />,
			},
			{
				path: "/admin/videos/match/:id",
				loader: iframeLoader,
				element: <IFramePage />,
			},
			{
				path: "/admin/club-amateur-nonpartner/:cid/match/:mid",
				loader: iframeLoader,
				element: <IFramePage />,
			},
			{
				path: "/admin/club-amateur-nonpartner/:id",
				loader: iframeLoader,
				element: <IFramePage />,
			},
			{
				path: "/admin/club-amateur-nonpartner/:id/games",
				loader: iframeLoader,
				element: <IFramePage />,
			},
			{
				path: "/admin/club-pro/:id",
				loader: iframeLoader,
				element: <IFramePage />,
			},
			{
				path: "/admin/club-pro/:id/users",
				loader: iframeLoader,
				element: <IFramePage />,
			},
			{
				path: "/admin/club-pro/:id/users/:id/new-password",
				loader: iframeLoader,
				element: <IFramePage />,
			},
			{
				path: "/admin/clubs-pro/add",
				loader: iframeLoader,
				element: <IFramePage />,
			},
			{
				path: "/admin/match/:id/edit/:id",
				loader: iframeLoader,
				element: <IFramePage />,
			},
			{
				path: "/users/:id",
				element: <UserPage />,
			},
			{
				path: "/video-uploads/:id/video",
				element: <VideoUploadMetadataPage />,
			},
			{
				path: "/video-uploads/:id/status",
				element: <VideoUploadStatusPage />,
			},
			{
				element: <VideoUploadsLayoutPage />,
				children: [
					{
						path: "/video-uploads/approval",
						element: <VideoUploadsWithoutApprovalPage />,
					},
					{
						path: "/video-uploads/in-processing",
						element: <VideoUploadsInProcessingPage />,
					},
					{
						path: "/video-uploads/finished",
						element: <VideoUploadsFinishedPage />,
					},
					{
						path: "/video-uploads/errored",
						element: <VideoUploadsErroredPage />,
					},
					{
						path: "/video-uploads/all",
						element: <VideoUploadsAllPage />,
					},
				],
			},
		],
	},
	{
		path: "/forgotten-password",
		element: <ForgottenPasswordPage />,
	},
	{
		path: "/new-password/:token",
		element: <ResetPasswordPage />,
	},
	{
		path: "/login",
		element: <LoginPage />,
	},
	{
		path: "/admin",
		element: <LoginPage />,
	},
	{
		path: "*",
		element: <NotFoundPage />,
	},
]);

export function navigate(dest: string) {
	router.navigate(dest);
}

export default () => <RouterProvider router={router} />;
