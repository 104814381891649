import type { HandleSingleSelectChange, SelectValue } from "ui-kit/src/MultipleSelect/types";
import Select from "react-select";

interface SelectProps {
	items?: { value: number | string; label: string; type?: string }[];
	placeholder?: string;
	value?: SelectValue;
	handleChange: HandleSingleSelectChange;
	isCleareable?: boolean;
	menuPortalTarget?: boolean;
}

export default function SelectComponent({ value, placeholder, items, handleChange, isCleareable = true, menuPortalTarget }: SelectProps) {
	const customStyles = {
		//@ts-ignore
		container: (provided) => ({
			...provided,
			width: "100%",
		}),
		// @ts-ignore
		menu: (provided) => ({
			...provided,
			borderRadius: "15px",
			overflow: "hidden",
		}),
		//@ts-ignore
		control: (provided) => ({
			...provided,
			borderRadius: "20px",
			cursor: "pointer",
		}),
		//@ts-ignore
		placeholder: (provided) => ({
			...provided,
			textTransform: "uppercase",
			fontSize: "12px",
			color: "#000937",
			textWrap: "nowrap",
		}),
		//@ts-ignore
		option: (provided, state) => ({
			...provided,
			cursor: "pointer",
			":hover": {
				backgroundColor: "#000937",
				color: "#FFFFFF",
			},
			backgroundColor: state.isFocused ? "#000937" : provided.backgroundColor,
			color: state.isFocused ? "#FFFFFF" : provided.color,
		}),
	};
	return (
		<Select
			menuPortalTarget={menuPortalTarget ? document.body : null}
			className="react-select"
			classNamePrefix="react-select"
			value={value}
			isClearable={isCleareable}
			styles={customStyles}
			onChange={handleChange}
			closeMenuOnSelect
			placeholder={placeholder}
			options={items}
		/>
	);
}
