import wretch, { WretchError } from "wretch";
import QueryStringAddon from "wretch/addons/queryString";
import * as z from "zod";
import qs from "qs";
import {
	useQuery,
	UseQueryOptions,
	UseQueryResult as UseBaseQueryResult,
	useMutation,
	UseMutationOptions,
	useInfiniteQuery,
	UseInfiniteQueryOptions,
	UseInfiniteQueryResult as UseBaseInfiniteQueryResult,
	QueryClient,
	QueryFunction,
	QueryKey,
} from "@tanstack/react-query";

const isProduction: boolean = process.env.NODE_ENV === "production";
const invariantPrefix: string = "Invariant failed";

// Throw an error if the condition fails
// Strip out error messages for production
// > Not providing an inline default argument for message as the result is smaller
function invariant(
	condition: any,
	// Can provide a string, or a function that returns a string for cases where
	// the message takes a fair amount of effort to compute
	message?: string | (() => string),
): asserts condition {
	if (condition) {
		return;
	}
	// Condition not passed

	// In production we strip the message but still throw
	if (isProduction) {
		throw new Error(invariantPrefix);
	}

	// When not in production we allow the message to pass through
	// *This block will be removed in production builds*

	const provided: string | undefined =
		typeof message === "function" ? message() : message;

	// Options:
	// 1. message provided: `${invariantPrefix}: ${provided}`
	// 2. message not provided: invariantPrefix
	const value: string = provided
		? `${invariantPrefix}: ${provided}`
		: invariantPrefix;
	throw new Error(value);
}

function error(err: unknown): WretchError {
	return err as WretchError;
}

type ErrorRecord = Record<string, string>;

interface GenericWretchError<T = ErrorRecord> extends WretchError {
	json: T;
}

export function toApiError<T extends ErrorRecord>(
	error: unknown,
): GenericWretchError<T> {
	return error as GenericWretchError<T>;
}

export interface ApiResponse<T> extends Pick<Response, "headers" | "url"> {
	data: T;
	status: number;
}

type UseQueryResult<TData = unknown, TError = unknown> = UseBaseQueryResult<
	TData,
	TError
> & {
	key: QueryKey;
	invalidate: () => Promise<void>;
};

type UseInfiniteQueryResult<
	TData = unknown,
	TError = unknown,
> = UseBaseInfiniteQueryResult<TData, TError> & {
	key: QueryKey;
	invalidate: () => Promise<void>;
};

export const JsonLoginRequest = z
	.object({
		email: z.string().nullable(),
		password: z.string().nullable(),
		accessToken: z.string().nullable(),
	})
	.partial();
export type JsonLoginRequest = z.infer<typeof JsonLoginRequest>;

export const Resource = z.enum([
	"product_management",
	"poles",
	"pole",
	"non_partner_clubs",
	"non_partner_club",
	"non_partner_players",
	"non_partner_player",
	"roles_permissions_management",
	"role_permission_management",
	"admins_creation",
	"admin_creation",
	"subscriptions",
	"subscription",
	"notifications",
	"notification",
	"specific_notiifcations",
	"specific_notiifcation",
	"schedule_notifications",
	"schedule_notification",
	"countries_expansion",
	"country_expansion",
	"player_accounts_management",
	"player_account_management",
	"feed_area",
	"single_feed_area",
	"clubs_amateur",
	"club_amateur_single",
	"club_amateur_users",
	"club_amateur_user_directors",
	"club_amateur_user_director",
	"club_amateur_user_coachs",
	"club_amateur_user_coach",
	"club_amateur_teams",
	"club_amateur_team",
	"club_amateur_team_players",
	"club_amateur_team_player",
	"club_amateur_games",
	"club_amateur_game",
	"game_uploads",
	"game_upload_single",
	"clubs_pro",
	"clubs_pro_single",
	"clubs_pro_users",
	"club_pro_user_director",
	"all",
]);
export type Resource = z.infer<typeof Resource>;

export const AdminManagementPermission = z.literal("set_permissions");
export type AdminManagementPermission = z.infer<
	typeof AdminManagementPermission
>;

export const BundlePermission = z.literal("assign_bundle");
export type BundlePermission = z.infer<typeof BundlePermission>;

export const GamePermission = z.enum([
	"game_teamsheet",
	"game_vip",
	"game_hidden",
	"game_view",
	"game_score",
	"game_analyze",
]);
export type GamePermission = z.infer<typeof GamePermission>;

export const NonPartnerPermission = z.enum([
	"migrate_to_nonpartner",
	"migrate_to_partner",
	"convert_to_partner",
]);
export type NonPartnerPermission = z.infer<typeof NonPartnerPermission>;

export const NotificationPermission = z.enum([
	"test_email",
	"SMS",
	"Email",
	"Firebase",
	"Notification",
]);
export type NotificationPermission = z.infer<typeof NotificationPermission>;

export const Permission = z.enum([
	"read",
	"list",
	"create",
	"edit",
	"email_edit",
	"delete",
]);
export type Permission = z.infer<typeof Permission>;

export const PolePermission = z.literal("assign_pole");
export type PolePermission = z.infer<typeof PolePermission>;

export const SubscriptionPermission = z.literal("assign_subscription");
export type SubscriptionPermission = z.infer<typeof SubscriptionPermission>;

export const SuperAdminPermission = z.literal("all");
export type SuperAdminPermission = z.infer<typeof SuperAdminPermission>;

export const UserPermission = z.object({
	id: z.number().int(),
	resource: Resource,
	permission: z.union([
		AdminManagementPermission,
		BundlePermission,
		GamePermission,
		NonPartnerPermission,
		NotificationPermission,
		Permission,
		PolePermission,
		SubscriptionPermission,
		SuperAdminPermission,
	]),
});
export type UserPermission = z.infer<typeof UserPermission>;

export const UserSubscriptionOptions = z.enum(["full", "partial", "none"]);
export type UserSubscriptionOptions = z.infer<typeof UserSubscriptionOptions>;

export const AdminSubMenuItem = z.object({ id: z.string(), name: z.string() });
export type AdminSubMenuItem = z.infer<typeof AdminSubMenuItem>;

export const AdminMenuItem = z.object({
	id: z.string(),
	name: z.string(),
	icon: z.string(),
	hasSubMenu: z.boolean(),
	subMenuItems: z.array(AdminSubMenuItem).default([]),
});
export type AdminMenuItem = z.infer<typeof AdminMenuItem>;

export const MenuData = z
	.object({
		admin_management: AdminMenuItem,
		non_partner_dataset: AdminMenuItem,
		poles: AdminMenuItem,
		subscriptions: AdminMenuItem,
		notifications: AdminMenuItem,
		country_expansion: AdminMenuItem,
		feed_area: AdminMenuItem,
		player_account_management: AdminMenuItem,
		game_uploads_management: AdminMenuItem,
	})
	.partial();
export type MenuData = z.infer<typeof MenuData>;

export const UserResponse = z.object({
	id: z.number().int(),
	email: z.string(),
	firstName: z.string(),
	lastName: z.string(),
	roles: z.array(z.string()),
	adminRoles: z.array(z.string()).optional(),
	role: z.string(),
	helpHash: z.string(),
	isVip: z.boolean(),
	position: z.string(),
	clubName: z.string(),
	clubLogo: z.string().nullish(),
	photo: z.string().nullable(),
	locale: z.string(),
	hasAcceptedTermsOfUse: z.boolean(),
	permissions: z.array(UserPermission).optional(),
	isSuperAdmin: z.boolean(),
	playerStatsSearchSubscription: UserSubscriptionOptions.nullable(),
	productManagementOptions: MenuData.nullish(),
	termsOfUseLatestVersion: z.number().int().nullish(),
});
export type UserResponse = z.infer<typeof UserResponse>;

export const JsonResetPasswordRequest = z.object({ newPassword: z.string() });
export type JsonResetPasswordRequest = z.infer<typeof JsonResetPasswordRequest>;

export const SelectedCountriesData = z.object({
	countries: z.object({
		africa: z.array(z.string()),
		asia: z.array(z.string()),
		europe: z.array(z.string()),
		northAmerica: z.array(z.string()),
		southAmerica: z.array(z.string()),
		oceania: z.array(z.string()),
	}),
	count: z.number().int(),
});
export type SelectedCountriesData = z.infer<typeof SelectedCountriesData>;

export const SingleCountryData = z.object({
	code: z.string(),
	name: z.string(),
	tooltip: z.string().nullable(),
	enabled: z.boolean(),
});
export type SingleCountryData = z.infer<typeof SingleCountryData>;

export const CountryData = z
	.object({
		africa: z.array(SingleCountryData),
		asia: z.array(SingleCountryData),
		europe: z.array(SingleCountryData),
		northAmerica: z.array(SingleCountryData),
		southAmerica: z.array(SingleCountryData),
		oceania: z.array(SingleCountryData),
	})
	.partial();
export type CountryData = z.infer<typeof CountryData>;

export const CountriesData = z.object({
	africa: z.array(z.string()),
	asia: z.array(z.string()),
	europe: z.array(z.string()),
	northAmerica: z.array(z.string()),
	southAmerica: z.array(z.string()),
	oceania: z.array(z.string()),
});
export type CountriesData = z.infer<typeof CountriesData>;

export const updateSelectedCountries_Body = z
	.object({ data: CountriesData })
	.partial();
export type updateSelectedCountries_Body = z.infer<
	typeof updateSelectedCountries_Body
>;

export const SearchLeagueResponse = z.object({
	label: z.string(),
	value: z.number().int(),
});
export type SearchLeagueResponse = z.infer<typeof SearchLeagueResponse>;

export const PermissionData = z
	.object({ id: z.number().int(), permission: z.string() })
	.partial();
export type PermissionData = z.infer<typeof PermissionData>;

export const PermissionTreeResponse = z
	.object({ label: z.string(), permission: z.array(PermissionData) })
	.partial();
export type PermissionTreeResponse = z.infer<typeof PermissionTreeResponse>;

export const assignPermissionToRole_Body = z.object({
	permissionIds: z.array(z.number().int()),
});
export type assignPermissionToRole_Body = z.infer<
	typeof assignPermissionToRole_Body
>;

export const RolesData = z
	.object({ id: z.number().int(), name: z.string() })
	.partial();
export type RolesData = z.infer<typeof RolesData>;

export const AddUpdateRole = z.object({
	id: z.number().int().nullable(),
	name: z.string(),
});
export type AddUpdateRole = z.infer<typeof AddUpdateRole>;

export const addUpdateRole_Body = z.object({ role: AddUpdateRole }).partial();
export type addUpdateRole_Body = z.infer<typeof addUpdateRole_Body>;

export const AdminUserData = z.object({
	email: z.string(),
	firstName: z.string(),
	id: z.number().int(),
	isActive: z.boolean(),
	lastName: z.string(),
	phoneNumber: z.string().nullable(),
	photo: z.string().nullable(),
	roleId: z.number().int().nullable(),
	roles: z.string().nullish(),
});
export type AdminUserData = z.infer<typeof AdminUserData>;

export const AdminData = z.object({
	email: z.string(),
	id: z.number().int().nullish(),
	firstName: z.string(),
	lastName: z.string(),
	phoneNumber: z.string().nullable(),
	photo: z.string().nullable(),
	roles: z.array(z.number().int()),
	status: z.boolean(),
});
export type AdminData = z.infer<typeof AdminData>;

export const addUpdateAdminUsers_Body = z.object({ user: AdminData }).partial();
export type addUpdateAdminUsers_Body = z.infer<typeof addUpdateAdminUsers_Body>;

export const uploadProfilePhoto_Body = z
	.object({ id: z.number().int().nullable(), file: z.string() })
	.partial();
export type uploadProfilePhoto_Body = z.infer<typeof uploadProfilePhoto_Body>;

export const AllPoleData = z.object({
	id: z.number().int().nullish(),
	location: z.string().optional(),
	timezone: z.string().optional(),
	currentBattery: z.number().int().optional(),
	serialNumber: z.string().optional(),
	assignedClub: z
		.array(
			z
				.object({
					id: z.number().int().describe("club ID"),
					name: z.string().describe("club name"),
				})
				.partial(),
		)
		.optional(),
	assignedClubCount: z.number().int().optional(),
	sync: z.number().int().nullish(),
	action: z.number().int().nullish(),
});
export type AllPoleData = z.infer<typeof AllPoleData>;

export const AddUpdatePole = z.object({
	id: z.number().int(),
	location: z.string(),
	timezone: z.string(),
	serialNumber: z.string(),
	oldId: z.number().int().nullish(),
});
export type AddUpdatePole = z.infer<typeof AddUpdatePole>;

export const addUpdatePole_Body = z.object({ pole: AddUpdatePole }).partial();
export type addUpdatePole_Body = z.infer<typeof addUpdatePole_Body>;

export const PoleData = z.object({
	id: z.number().int().nullish(),
	location: z.string().optional(),
	timezone: z.string().optional(),
	currentBattery: z.number().int().optional(),
	serialNumber: z.string().optional(),
	assignedClub: z
		.array(
			z
				.object({
					id: z.number().int().describe("club ID"),
					name: z.string().describe("club name"),
				})
				.partial(),
		)
		.optional(),
	assignedClubCount: z.number().int().optional(),
});
export type PoleData = z.infer<typeof PoleData>;

export const TimezoneResponse = z.array(z.string());
export type TimezoneResponse = z.infer<typeof TimezoneResponse>;

export const DefaultResponseOptions = z
	.object({
		label: z.union([z.string(), z.number()]).nullable(),
		value: z.union([z.string(), z.number()]).nullable(),
	})
	.partial();
export type DefaultResponseOptions = z.infer<typeof DefaultResponseOptions>;

export const assignPole_Body = z
	.object({
		assign_pole: z.object({ club_id: z.number().int(), assign_to: z.string() }),
	})
	.partial();
export type assignPole_Body = z.infer<typeof assignPole_Body>;

export const FilterData = z.object({
	label: z.union([z.string(), z.number()]),
	value: z.union([z.string(), z.number()]),
});
export type FilterData = z.infer<typeof FilterData>;

export const ContinentCountryData = z
	.object({ value: z.string(), label: z.string(), continent: z.string() })
	.partial();
export type ContinentCountryData = z.infer<typeof ContinentCountryData>;

export const ContinentCountryResponse = z
	.object({ label: z.string(), options: z.array(ContinentCountryData) })
	.partial();
export type ContinentCountryResponse = z.infer<typeof ContinentCountryResponse>;

export const ClubExternalLink = z
	.object({ link: z.string(), type: z.string() })
	.partial();
export type ClubExternalLink = z.infer<typeof ClubExternalLink>;

export const ClubData = z.object({
	id: z.number().int().nullish(),
	name: z.string(),
	country: z.string(),
	logo: z.string().nullish(),
	description: z.string().nullish(),
	contactName: z.string().nullish(),
	contactPhoneNumber: z.string().nullish(),
	contactEmail: z.string().nullish(),
	city: z.string().nullish(),
	zipcode: z.string().nullish(),
	address: z.string().nullish(),
	showCase: z.boolean().nullish(),
	region: z.string().nullish(),
	geographicalArea: z.string().nullish(),
	gamesCount: z.number().int().nullish(),
	playersCount: z.number().int().nullish(),
	clubExternalLinks: z.array(ClubExternalLink).nullish(),
});
export type ClubData = z.infer<typeof ClubData>;

export const addUpdateNPClub_Body = z
	.object({ nonPartnerClub: ClubData })
	.partial();
export type addUpdateNPClub_Body = z.infer<typeof addUpdateNPClub_Body>;

export const PlayerData = z.object({
	id: z.number().int().nullish(),
	firstName: z.string().optional(),
	lastName: z.string().nullish(),
	picture: z.string().nullish(),
	birthday: z.string().nullish(),
	nationality: z.string().nullish(),
	nationality2: z.string().nullish(),
	position: z.string().nullish(),
	strongestFoot: z.string().nullish(),
	playedInNationalTeam: z.boolean().nullish(),
	clubNonpartnerId: z.number().int().nullish(),
	npClubName: z.string().nullish(),
	npClubCountry: z.string().nullish(),
	npClubLogo: z.string().nullish(),
	tranLink: z.string().nullish(),
	federationLink: z.string().nullish(),
});
export type PlayerData = z.infer<typeof PlayerData>;

export const AddUpdatePlayer = z.object({
	id: z.number().int().nullish(),
	firstName: z.string(),
	lastName: z.string(),
	clubNonpartnerId: z.number().int().nullable(),
	picture: z.string().nullish(),
	birthday: z.string().nullish(),
	nationality: z.string().nullish(),
	nationality2: z.string().nullish(),
	position: z.string().nullish(),
	strongestFoot: z.string().nullish(),
	playedInNationalTeam: z.boolean().nullish(),
	tranLink: z.string().nullish(),
	federationLink: z.string().nullish(),
});
export type AddUpdatePlayer = z.infer<typeof AddUpdatePlayer>;

export const addUpdateNPPlayer_Body = z
	.object({ nonPartnerPlayer: AddUpdatePlayer })
	.partial();
export type addUpdateNPPlayer_Body = z.infer<typeof addUpdateNPPlayer_Body>;

export const ClubOptionData = z
	.object({
		country: z.string().nullable(),
		label: z.string().nullable(),
		logo: z.string().nullable(),
		value: z.number().int(),
	})
	.partial();
export type ClubOptionData = z.infer<typeof ClubOptionData>;

export const ClubPlayers = z
	.object({
		club: z.string().nullable(),
		clubCountry: z.string().nullable(),
		firstName: z.string().nullable(),
		id: z.number().int().nullable(),
		lastName: z.string().nullable(),
		picture: z.string().nullable(),
		teamName: z.string().nullable(),
		teamCategory: z.string().nullable(),
	})
	.partial();
export type ClubPlayers = z.infer<typeof ClubPlayers>;

export const executeNPClubMigration_Body = z
	.object({
		sourceClubId: z.number().int(),
		targetClubId: z.number().int(),
		sourcePlayerId: z.number().int().nullable(),
		isClubMigration: z.boolean(),
	})
	.partial();
export type executeNPClubMigration_Body = z.infer<
	typeof executeNPClubMigration_Body
>;

export const playerExistsTargetClub_Body = z
	.object({
		migrationId: z.number().int(),
		status: z.string(),
		targetPlayerId: z.number().int(),
	})
	.partial();
export type playerExistsTargetClub_Body = z.infer<
	typeof playerExistsTargetClub_Body
>;

export const getMigrationStatus_Body = z.object({
	sourceClubId: z.number().int(),
	targetClubId: z.number().int(),
	migrateAllPlayers: z.boolean(),
	migrationIds: z.array(z.number().int()),
});
export type getMigrationStatus_Body = z.infer<typeof getMigrationStatus_Body>;

export const createPartnerPlayerFromNonpartner_Body = z
	.object({
		partnerMigrationId: z.number().int(),
		sourcePlayerId: z.number().int(),
		teamId: z.number().int().nullable(),
	})
	.partial();
export type createPartnerPlayerFromNonpartner_Body = z.infer<
	typeof createPartnerPlayerFromNonpartner_Body
>;

export const partnerPlayerExistsTargetClub_Body = z
	.object({
		migrationId: z.number().int(),
		status: z.string(),
		targetPlayerId: z.number().int(),
		teamId: z.number().int().nullable(),
	})
	.partial();
export type partnerPlayerExistsTargetClub_Body = z.infer<
	typeof partnerPlayerExistsTargetClub_Body
>;

export const getPartnerMigrationStatus_Body = z.object({
	sourceClubId: z.number().int(),
	targetClubId: z.number().int(),
	migrationIds: z.array(z.number().int()),
	migrateAllPlayers: z.boolean().optional(),
});
export type getPartnerMigrationStatus_Body = z.infer<
	typeof getPartnerMigrationStatus_Body
>;

export const updateGameTeam_Body = z.object({
	gameId: z.number().int(),
	teamId: z.number().int().nullable(),
	targetClubId: z.number().int(),
});
export type updateGameTeam_Body = z.infer<typeof updateGameTeam_Body>;

export const beginMigration_Body = z.object({
	sourceClubId: z.number().int(),
	targetClubId: z.number().int(),
	sourceClubType: z.string(),
	targetClubType: z.string(),
	isClubMigration: z.boolean().optional(),
	sourcePlayerId: z.number().int().nullish(),
});
export type beginMigration_Body = z.infer<typeof beginMigration_Body>;

export const BeginMigration = z
	.object({
		status: z.union([z.string(), z.boolean()]).nullable(),
		percentage: z.number().int(),
		id: z.number().int(),
		targetPlayer: z
			.object({
				id: z.number().int().describe("ID of Target Player"),
				firstName: z.string().describe("First Name of Target Player"),
				lastName: z.string().describe("Last Name of Target Player"),
			})

			.nullable(),
		count: z.object({
			gamesCount: z.number().int().describe("count of migrated games"),
			playersCount: z.number().int().describe("count of players count"),
		}),
		deleteClub: z.boolean(),
		message: z.string(),
	})
	.partial();
export type BeginMigration = z.infer<typeof BeginMigration>;

export const clubMigrationWithZeroPlayers_Body = z.object({
	sourceClubId: z.number().int(),
	targetClubId: z.number().int(),
	sourceClubType: z.string(),
	targetClubType: z.string(),
});
export type clubMigrationWithZeroPlayers_Body = z.infer<
	typeof clubMigrationWithZeroPlayers_Body
>;

export const MigrateZeroPlayerClub = z.object({
	id: z.number().int(),
	migratedClub: z.string(),
	category: z.string(),
	date: z.string(),
	awayClub: z.string(),
	homeClub: z.string(),
	awayTeam: z.object({
		label: z.string().nullable(),
		value: z.number().int().nullable(),
	}),
	homeTeam: FilterData,
});
export type MigrateZeroPlayerClub = z.infer<typeof MigrateZeroPlayerClub>;

export const completeMigration_Body = z.object({
	sourceClubId: z.number().int(),
	targetClubId: z.number().int(),
	sourceClubType: z.string(),
	targetClubType: z.string(),
	migrationIds: z.array(z.number()).optional(),
	isClubMigration: z.boolean().optional(),
});
export type completeMigration_Body = z.infer<typeof completeMigration_Body>;

export const CompleteMigration = z.object({
	failed: z.object({
		skippedPlayers: z
			.array(
				z.object({
					team: z.string().describe("Team name").nullish(),
					status: z.string().describe("Status"),
					firstName: z.string().describe("First Name of Target Player"),
					lastName: z.string().describe("Last Name of Target Player").nullish(),
				}),
			)
			.describe("skipped players"),
		skippedPlayersCount: z.number().int().describe("Count of skipped players"),
	}),
	success: z.object({
		migratedPlayers: z
			.array(
				z.object({
					team: z.string().describe("Team id").nullish(),
					status: z.string().describe("Status"),
					firstName: z
						.string()
						.describe("First Name of Target Player")
						.optional(),
					lastName: z.string().describe("Last Name of Target Player").nullish(),
				}),
			)
			.describe("migrated players"),
		migratedGames: z
			.array(
				z.object({
					id: z.number().int().describe("Game Id"),
					category: z.string().describe("Game Category"),
					homeClub: z.string().describe("Home club name"),
					awayClub: z.string().describe("Away Club name"),
					date: z.string().describe("Game Date"),
					homeTeam: DefaultResponseOptions,
					awayTeam: DefaultResponseOptions,
					migratedClub: z
						.string()
						.describe("migrated club is home/away")
						.nullable(),
				}),
			)
			.describe("migrated games"),
		migratedPlayersCount: z
			.number()
			.int()
			.describe("Count of migrated players"),
		migratedGamesCount: z.number().int().describe("Count of migrated games"),
	}),
});
export type CompleteMigration = z.infer<typeof CompleteMigration>;

export const playerMigrationToPartner_Body = z.object({
	partnerMigrationId: z.number().int(),
	sourcePlayerId: z.number().int(),
	teamId: z.number().int().nullish(),
});
export type playerMigrationToPartner_Body = z.infer<
	typeof playerMigrationToPartner_Body
>;

export const playerMigrationConflict_Body = z
	.object({
		migrationId: z.number().int(),
		partnerMigrationId: z.number().int(),
		targetPlayerId: z.number().int(),
		teamId: z.string(),
		status: z.string(),
	})
	.partial();
export type playerMigrationConflict_Body = z.infer<
	typeof playerMigrationConflict_Body
>;

export const AllCountryTooltipData = z.object({
	id: z.number().int().nullable(),
	countryCode: z.string(),
	countryName: z.string(),
	enabled: z.boolean(),
	tooltip: z.string(),
});
export type AllCountryTooltipData = z.infer<typeof AllCountryTooltipData>;

export const CountryTooltipData = z.object({
	id: z.number().int().nullish(),
	enabled: z.boolean(),
	tooltip: z.string(),
	countries: z.array(
		z
			.object({
				value: z.string().describe("country code"),
				label: z.string().describe("country name"),
				continent: z.string().describe("continent name"),
			})
			.partial(),
	),
});
export type CountryTooltipData = z.infer<typeof CountryTooltipData>;

export const addUpdateCountry_Body = z
	.object({ countryTooltip: CountryTooltipData })
	.partial();
export type addUpdateCountry_Body = z.infer<typeof addUpdateCountry_Body>;

export const UpdateCountryTooltipData = z.object({
	id: z.number().int().nullable(),
	continent: z.string(),
	country: z.string(),
	enabled: z.boolean(),
	tooltip: z.string(),
});
export type UpdateCountryTooltipData = z.infer<typeof UpdateCountryTooltipData>;

export const SubscriptionRequest = z.object({
	start: z.number().int(),
	length: z.number().int(),
	search: z.string().optional(),
	subscriptionId: z.number().int().nullish(),
	clubId: z.number().int().nullish(),
	club: z.array(z.number().int()).nullish(),
	fromStartDate: z.string().nullish(),
	toStartDate: z.string().nullish(),
	fromExpirationDate: z.string().nullish(),
	toExpirationDate: z.string().nullish(),
	active: z.number().int().nullish(),
	product: z.array(z.string()).nullish(),
});
export type SubscriptionRequest = z.infer<typeof SubscriptionRequest>;

export const SubscriptionData = z
	.object({
		id: z.number().int(),
		comment: z.string().nullable(),
		product: z.string(),
		proClubName: z.string().nullable(),
		proClubId: z.number().int().nullable(),
		countries: z.array(
			z
				.object({
					label: z.string().describe("label"),
					value: z.string().describe("value"),
					continent: z.string().describe("continent"),
				})
				.partial(),
		),
		countriesCount: z.number().int(),
		gameOptions: z
			.object({
				ai: z.boolean().describe("ai"),
				statistics_rating: z.boolean().describe("statistics_rating"),
				statistics_players: z.boolean().describe("statistics_players"),
				statistics_teams: z.boolean().describe("statistics_teams"),
				events: z.boolean().describe("events"),
				clips: z.boolean().describe("clips"),
				downloadable: z.boolean().describe("downloadable"),
				downloadable_gametags: z.boolean().describe("downloadable_gametags"),
				player_stats_search: z.boolean().describe("player_stats_search"),
			})
			.partial(),
		maxAccounts: z.number().int(),
		startDate: z.string(),
		expirationDate: z.string(),
		active: z.boolean(),
		expiresIn: z.string().nullable(),
		ownerships: z.array(z.unknown()),
	})
	.partial();
export type SubscriptionData = z.infer<typeof SubscriptionData>;

export const GameOptions = z.object({
	ai: z.boolean(),
	statistics_rating: z.boolean(),
	statistics_players: z.boolean(),
	statistics_teams: z.boolean(),
	events: z.boolean(),
	downloadable: z.boolean(),
	clips: z.boolean(),
	downloadable_gametags: z.boolean().optional(),
	player_stats_search: z.boolean().optional(),
});
export type GameOptions = z.infer<typeof GameOptions>;

export const Ownership = z.array(FilterData);
export type Ownership = z.infer<typeof Ownership>;

export const AddUpdateSubscriptionRequest = z
	.object({
		id: z.number().int().nullable(),
		comment: z.string().nullable(),
		product: z.string().nullable(),
		maxAccounts: z.number().int().nullable(),
		startDate: z.string().nullable(),
		expirationDate: z.string().nullable(),
		countries: CountriesData,
		gameOptions: GameOptions,
		ownership: Ownership.nullable(),
		proClub: z.number().int().nullable(),
	})
	.partial();
export type AddUpdateSubscriptionRequest = z.infer<
	typeof AddUpdateSubscriptionRequest
>;

export const addUpdateSubscription_Body = z
	.object({ subscription: AddUpdateSubscriptionRequest })
	.partial();
export type addUpdateSubscription_Body = z.infer<
	typeof addUpdateSubscription_Body
>;

export const NotificationMessageData = z.object({
	id: z.number().int().nullable(),
	language: z.string(),
	fullLanguage: z.string(),
	title: z.string(),
	body: z.string(),
});
export type NotificationMessageData = z.infer<typeof NotificationMessageData>;

export const AllSpecificGenericNotificationData = z.object({
	id: z.number().int().nullable(),
	title: z.string(),
	methods: z.array(z.string()),
	userCount: z.number().int(),
	successful: z.number().int(),
	percentage: z.number().int(),
	failed: z.number().int(),
	authorName: z.string(),
	date: z.string(),
	messages: z.array(NotificationMessageData),
	receivers: z
		.object({
			users: z.array(
				z.object({ label: z.string(), value: z.number().int() }).partial(),
			),
			proClubs: z.array(
				z.object({ label: z.string(), value: z.number().int() }).partial(),
			),
			amateurClubs: z.array(
				z.object({ label: z.string(), value: z.number().int() }).partial(),
			),
		})
		.partial(),
});
export type AllSpecificGenericNotificationData = z.infer<
	typeof AllSpecificGenericNotificationData
>;

export const NotificationInstantData = z.object({
	id: z.number().int().nullable(),
	delivered: z.boolean(),
	method: z.string(),
	email: z.string(),
	phoneNumber: z.string(),
	firstName: z.string(),
	lastName: z.string(),
	sentDateTime: z.string(),
	locale: z.string(),
});
export type NotificationInstantData = z.infer<typeof NotificationInstantData>;

export const AddSpecificGenericNotificationData = z.object({
	authorId: z.number().int(),
	messages: z.array(
		z
			.object({
				body: z.string().describe("body of the notification"),
				language: z.string().describe("language"),
				title: z.string().describe("title of the notification"),
			})
			.partial(),
	),
	methods: z.array(z.string()),
	title: z.string(),
	receivers: z
		.object({
			users: z.array(
				z.object({ label: z.string(), value: z.number().int() }).partial(),
			),
			proClubs: z.array(
				z.object({ label: z.string(), value: z.number().int() }).partial(),
			),
			amateurClubs: z.array(
				z.object({ label: z.string(), value: z.number().int() }).partial(),
			),
		})
		.partial(),
});
export type AddSpecificGenericNotificationData = z.infer<
	typeof AddSpecificGenericNotificationData
>;

export const addSpecificGenericNotification_Body = z
	.object({ notifications: AddSpecificGenericNotificationData })
	.partial();
export type addSpecificGenericNotification_Body = z.infer<
	typeof addSpecificGenericNotification_Body
>;

export const NotificationJobData = z.object({
	id: z.number().int().nullable(),
	title: z.string(),
	methods: z.array(z.string()),
	userCount: z.number().int(),
	successful: z.number().int(),
	percentage: z.number().int(),
	failed: z.number().int(),
	authorName: z.string(),
	date: z.string(),
	messages: z.array(NotificationMessageData),
	receivers: z
		.object({
			users: z.array(
				z.object({ label: z.string(), value: z.number().int() }).partial(),
			),
			proClubs: z.array(
				z.object({ label: z.string(), value: z.number().int() }).partial(),
			),
			amateurClubs: z.array(
				z.object({ label: z.string(), value: z.number().int() }).partial(),
			),
		})
		.partial(),
});
export type NotificationJobData = z.infer<typeof NotificationJobData>;

export const UserInfo = z.object({
	id: z.number().int(),
	firstName: z.string(),
	lastName: z.string(),
	email: z.string(),
	phoneNumber: z.string().nullable(),
	timezone: z.string().nullable(),
	locale: z.string().nullish(),
	photo: z.string().nullish(),
	hasNewsletterSubscription: z.boolean(),
});
export type UserInfo = z.infer<typeof UserInfo>;

export const UserSessionLog = z
	.object({
		userId: z.number().int(),
		sessionId: z.string(),
		createdAt: z.string().datetime({ offset: true }).nullable(),
		updatedAt: z.string().datetime({ offset: true }).nullable(),
	})
	.partial();
export type UserSessionLog = z.infer<typeof UserSessionLog>;

export const UserActivityLogRequest = z.record(
	z.union([z.string(), z.number()]),
);
export type UserActivityLogRequest = z.infer<typeof UserActivityLogRequest>;

export const ErrorResponse = z.object({ code: z.string() });
export type ErrorResponse = z.infer<typeof ErrorResponse>;

export const CoachInfo = z.object({
	id: z.number().int(),
	firstName: z.string(),
	lastName: z.string(),
	email: z.string(),
});
export type CoachInfo = z.infer<typeof CoachInfo>;

export const ClubTeamInfo = z.object({
	coaches: z.array(CoachInfo),
	id: z.number().int(),
	name: z.string(),
	level: z.number().int(),
	playerCount: z.number().int(),
	clubId: z.number().int().nullable(),
	clubName: z.string().nullable(),
	category: z.string().nullable(),
});
export type ClubTeamInfo = z.infer<typeof ClubTeamInfo>;

export const TeamResponse = z.object({
	data: z.array(ClubTeamInfo),
	page: z.number().int(),
	limit: z.number().int(),
	pages: z.number().int(),
	total: z.number().int(),
});
export type TeamResponse = z.infer<typeof TeamResponse>;

export const addUpdateTeam_Body = z.object({
	id: z.number().int().nullish(),
	category: z.string(),
	name: z.string(),
});
export type addUpdateTeam_Body = z.infer<typeof addUpdateTeam_Body>;

export const DefaultSelectResponse = z.object({
	label: z.string(),
	value: z.union([z.string(), z.number(), z.string(), z.number()]),
});
export type DefaultSelectResponse = z.infer<typeof DefaultSelectResponse>;

export const order = z
	.object({
		name: z.string().describe("Sorting column name"),
		sort: z.string().describe("Sorting column type"),
	})
	.partial()

	.optional();
export type order = z.infer<typeof order>;

export const ClubPlayersInfo = z.object({
	id: z.number().int(),
	firstName: z.string(),
	lastName: z.string().nullable(),
	birthday: z.string().nullable(),
	birthdayPure: z.string().nullish(),
	strongestFoot: z.string().nullable(),
	height: z.union([z.string(), z.number(), z.string(), z.number()]).nullable(),
	weight: z.union([z.string(), z.number(), z.string(), z.number()]).nullable(),
	position: z.string().nullable(),
	position2: z.string().nullable(),
	photo: z.string().nullable(),
	nationality: z.string().nullable(),
	nationality2: z.string().nullable(),
	isRegistered: z.boolean().optional(),
	editable: z.boolean().optional(),
	notes: z.string().nullable(),
	vip: z.boolean().optional(),
	playedInNationalTeam: z.boolean().optional(),
	teamId: z.number().int().nullable(),
	teamName: z.string().nullable(),
	tranLink: z.string().nullish(),
	federationLink: z.string().nullish(),
});
export type ClubPlayersInfo = z.infer<typeof ClubPlayersInfo>;

export const ClubPlayersResponse = z.object({
	data: z.array(ClubPlayersInfo),
	countWithoutFilter: z.number().int(),
	page: z.number().int(),
	limit: z.number().int(),
	pages: z.number().int(),
	total: z.number().int(),
});
export type ClubPlayersResponse = z.infer<typeof ClubPlayersResponse>;

export const PlayerRequest = z.object({
	birthday: z.string(),
	checked: z.boolean(),
	firstName: z.string(),
	lastName: z.string(),
	height: z.number().int().nullable(),
	weight: z.number().int().nullable(),
	photo: z.string().nullable(),
	strongestFoot: z.string(),
	id: z.number().int().nullable(),
	teamId: z.number().int().nullable(),
	nationality: z.string(),
	nationality2: z.string().nullable(),
	position: z.string(),
	position2: z.string().nullable(),
	notes: z.string().nullable(),
	playedInNationalTeam: z.boolean().optional(),
	tranLink: z.string().nullable(),
	federationLink: z.string().nullable(),
});
export type PlayerRequest = z.infer<typeof PlayerRequest>;

export const addUpdatePlayer_Body = z
	.object({ player: PlayerRequest })
	.partial();
export type addUpdatePlayer_Body = z.infer<typeof addUpdatePlayer_Body>;

export const uploadPlayerPhoto_Body = z
	.object({ file: z.string(), id: z.number().int().nullable() })
	.partial();
export type uploadPlayerPhoto_Body = z.infer<typeof uploadPlayerPhoto_Body>;

export const assignCoachesToTeam_Body = z
	.object({ coachIds: z.array(z.number().int()) })
	.partial();
export type assignCoachesToTeam_Body = z.infer<typeof assignCoachesToTeam_Body>;

export const batchUpdate_Body = z.object({
	playerIds: z.array(z.number().int()),
	teamId: z.number().int().nullish(),
	dob: z.string().nullish(),
	strongestFoot: z.string().nullish(),
	playedInNationalTeam: z.boolean().nullish(),
	position: z.string().nullish(),
	position2: z.string().nullish(),
	nationality: z.string().nullish(),
	nationality2: z.string().nullish(),
});
export type batchUpdate_Body = z.infer<typeof batchUpdate_Body>;

export const order__2 = z
	.object({
		name: z.enum(["name", "duration", "size"]),
		sort: z.enum(["asc", "desc"]),
	})
	.partial()
	.optional();
export type order__2 = z.infer<typeof order__2>;

export const filters = z
	.array(
		z
			.object({
				field: z.string().describe("Field to filter on"),
				type: z.string().describe("Type of filter"),
				value: z.union([
					z.string(),
					z.number(),
					z
						.object({
							start: z.string().datetime({ offset: true }),
							end: z.string().datetime({ offset: true }),
						})
						.partial(),
				]),
			})
			.partial(),
	)
	.optional();
export type filters = z.infer<typeof filters>;

export const VideoStatusType = z.enum([
	"UPLOADED",
	"INGEST_STARTED",
	"INGEST_OK",
	"INGEST_ERROR",
	"PROCESSING_STARTED",
	"PROCESSING_ERROR",
	"PROCESSING_FINISHED",
]);
export type VideoStatusType = z.infer<typeof VideoStatusType>;

export const VideoEventLogStatusType = z.enum([
	"UPLOADED",
	"BACKUP_STARTED",
	"BACKUP_OK",
	"BACKUP_ERROR",
	"INGEST_STARTED",
	"INGEST_OK",
	"INGEST_ERROR",
	"PROCESSING_STARTED",
	"PROCESSING_ERROR",
	"PROCESSING_FINISHED",
	"INTERMEDIATE_CREATED",
	"INTERMEDIATE_ERROR",
	"VIDEO_CONCAT_STARTED",
	"VIDEO_CONCAT_OK",
	"VIDEO_CONCAT_ERROR",
	"HLS_CREATION_STARTED",
	"HLS_CREATION_OK",
	"HLS_CREATION_ERROR",
	"THUMBNAILS_CREATION_STARTED",
	"THUMBNAILS_CREATION_OK",
	"THUMBNAILS_CREATION_ERROR",
	"POSTER_CREATION_STARTED",
	"POSTER_CREATION_OK",
	"POSTER_CREATION_ERROR",
	"PARTIAL_OUTPUT_UPLOAD_STARTED",
	"PARTIAL_OUTPUT_UPLOAD_OK",
	"PARTIAL_OUTPUT_UPLOAD_ERROR",
	"OUTPUT_UPLOAD_STARTED",
	"OUTPUT_UPLOAD_OK",
	"OUTPUT_UPLOAD_ERROR",
	"RESET_REQUESTED",
	"RETRY_REQUESTED",
	"UPLOAD_DELETED",
]);
export type VideoEventLogStatusType = z.infer<typeof VideoEventLogStatusType>;

export const VideoProviderName = z.enum([
	"veo",
	"spiideo",
	"hudl",
	"pixellot",
	"panoris",
	"die-ligen",
	"manual",
]);
export type VideoProviderName = z.infer<typeof VideoProviderName>;

export const VideoUpload = z
	.object({
		id: z.number().int(),
		name: z.string(),
		file: z.string(),
		size: z.string().nullable(),
		duration: z.string().nullable(),
		bitRate: z.string().nullable(),
		width: z.number().int().nullable(),
		height: z.number().int().nullable(),
		status: VideoStatusType,
		lastEvent: VideoEventLogStatusType.nullable(),
		message: z.string().nullable(),
		context: z.string().nullable(),
		provider: VideoProviderName.nullable(),
		providerMetadata: z.string().nullable(),
		metadata: z.string().nullable(),
		createdAt: z.string(),
		updatedAt: z.string().nullable(),
		backupStartedAt: z.string().nullable(),
		backupFinishedAt: z.string().nullable(),
		partialUploadStartedAt: z.string().nullable(),
		partialUploadFinishedAt: z.string().nullable(),
		uploadStartedAt: z.string().nullable(),
		uploadFinishedAt: z.string().nullable(),
		processingStartedAt: z.string().nullable(),
		processingFinishedAt: z.string().nullable(),
		metadataApprovedAt: z.string().nullable(),
		lastUpdatedBy: z.string().nullable(),
		metadataAddedBy: z.string().nullable(),
		uploadedBy: z.string().nullable(),
		approvedBy: z.string().nullable(),
		node: z.string().nullable(),
		gpuId: z.number().int().nullable(),
	})
	.partial();
export type VideoUpload = z.infer<typeof VideoUpload>;

export const VideoUploadsPaginatedResponse = z
	.object({
		videos: z.array(VideoUpload),
		limit: z.number().int(),
		page: z.number().int(),
		pages: z.number().int(),
		total: z.number().int(),
	})
	.partial();
export type VideoUploadsPaginatedResponse = z.infer<
	typeof VideoUploadsPaginatedResponse
>;

export const VideoUploadLog = z.object({
	id: z.number().int(),
	name: z.string(),
	status: VideoEventLogStatusType,
	message: z.string().nullish(),
	context: z.string().nullish(),
	timestamp: z.string(),
});
export type VideoUploadLog = z.infer<typeof VideoUploadLog>;

export const VideoUploadDetail = z
	.object({
		logs: z.array(VideoUploadLog),
		link: z.string().nullable(),
		id: z.number().int(),
		name: z.string(),
		file: z.string(),
		size: z.string().nullable(),
		duration: z.string().nullable(),
		bitRate: z.string().nullable(),
		width: z.number().int().nullable(),
		height: z.number().int().nullable(),
		status: VideoStatusType,
		lastEvent: VideoEventLogStatusType.nullable(),
		message: z.string().nullable(),
		context: z.string().nullable(),
		provider: VideoProviderName.nullable(),
		providerMetadata: z.string().nullable(),
		metadata: z.string().nullable(),
		createdAt: z.string(),
		updatedAt: z.string().nullable(),
		backupStartedAt: z.string().nullable(),
		backupFinishedAt: z.string().nullable(),
		partialUploadStartedAt: z.string().nullable(),
		partialUploadFinishedAt: z.string().nullable(),
		uploadStartedAt: z.string().nullable(),
		uploadFinishedAt: z.string().nullable(),
		processingStartedAt: z.string().nullable(),
		processingFinishedAt: z.string().nullable(),
		metadataApprovedAt: z.string().nullable(),
		lastUpdatedBy: z.string().nullable(),
		metadataAddedBy: z.string().nullable(),
		uploadedBy: z.string().nullable(),
		approvedBy: z.string().nullable(),
		node: z.string().nullable(),
		gpuId: z.number().int().nullable(),
	})
	.partial();
export type VideoUploadDetail = z.infer<typeof VideoUploadDetail>;

export const PeriodRequest = z.object({
	period: z.string(),
	start: z.string().regex(/(?:(\d+):)?([0-5]?\d):([0-5]?\d)/).nullish(),
	end: z.string().regex(/(?:(\d+):)?([0-5]?\d):([0-5]?\d)/).nullish(),
	startTime: z.string().nullish(),
	endTime: z.string().nullish(),
	startTimeInSeconds: z.number().int().nullish(),
	endTimeInSeconds: z.number().int().nullish(),
});
export type PeriodRequest = z.infer<typeof PeriodRequest>;

export const VideoMetadataRequest = z.object({
	gameId: z.number().int().describe("ID of the game"),
	periods: z.array(PeriodRequest).nullish(),
	provider: z.string().nullish(),
});
export type VideoMetadataRequest = z.infer<typeof VideoMetadataRequest>;

export const GameData = z.object({
	id: z.number().int(),
	isAnalysisFinished: z.boolean().nullable(),
	type: z.string().nullable(),
	date: z.string().nullable(),
	description: z.string().nullable(),
	homeTeamName: z.string().nullable(),
	awayTeamName: z.string().nullable(),
	homeClubName: z.string().nullable(),
	awayClubName: z.string().nullable(),
	homeTeamsScore: z.number().int().nullable(),
	awayTeamsScore: z.number().int().nullable(),
	homeClubLogo: z.string().nullable(),
	awayClubLogo: z.string().nullable(),
	isFavorite: z.boolean().optional(),
	category: z.string().nullable(),
	playerRating: z.number().nullish(),
	minutesPlayed: z.number().int().nullish(),
	goalsScored: z.number().int().nullish(),
	videoUploadId: z.number().int().nullish(),
});
export type GameData = z.infer<typeof GameData>;

export const GameDetail = z
	.object({
		id: z.number().int(),
		homeTeamName: z.string().nullable(),
		homeTeamLogo: z.string().nullable(),
		homeTeamsScore: z.number().int().nullable(),
		awayTeamName: z.string().nullable(),
		awayTeamLogo: z.string().nullable(),
		awayTeamsScore: z.number().int().nullable(),
		category: z.string(),
		date: z.string(),
		type: z.string(),
	})
	.partial();
export type GameDetail = z.infer<typeof GameDetail>;

export const migratePlayers_Body = z
	.object({ playerIds: z.array(z.number().int()), teamId: z.number().int() })
	.partial();
export type migratePlayers_Body = z.infer<typeof migratePlayers_Body>;

export const ClubDetailResponseDTO = z.object({
	id: z.number().int(),
	name: z.string(),
	country: z.string(),
	countryCode: z.string(),
	logo: z.string().nullable(),
	region: z.string().nullable(),
});
export type ClubDetailResponseDTO = z.infer<typeof ClubDetailResponseDTO>;

const forgotPasswordRequestBody = z.object({ email: z.string() }).partial();
export type forgotPasswordRequestBody = z.infer<
	typeof forgotPasswordRequestBody
>;

const resetPasswordRequestBody = z.object({ newPassword: z.string() });
export type resetPasswordRequestBody = z.infer<typeof resetPasswordRequestBody>;

const enableDisableCountryRequestBody = z
	.object({ enabled: z.boolean() })
	.partial();
export type enableDisableCountryRequestBody = z.infer<
	typeof enableDisableCountryRequestBody
>;

const generateSignUpLinkRequestBody = z
	.object({ playerId: z.number().int() })
	.partial();
export type generateSignUpLinkRequestBody = z.infer<
	typeof generateSignUpLinkRequestBody
>;

const getCurrentUserResponse401 = z
	.object({ message: z.string().describe("Error message") })
	.partial();
export type getCurrentUserResponse401 = z.infer<
	typeof getCurrentUserResponse401
>;

const getCurrentUserResponse403 = z.array(
	z
		.object({
			status: z.string().describe("EventStatus"),
			comment: z.string().describe("Change Status Comment"),
		})
		.partial(),
);
export type getCurrentUserResponse403 = z.infer<
	typeof getCurrentUserResponse403
>;

const adminJsonLogoutResponse200 = z
	.object({ message: z.string().describe("message") })
	.partial();
export type adminJsonLogoutResponse200 = z.infer<
	typeof adminJsonLogoutResponse200
>;

const adminJsonLogoutResponse401 = z
	.object({ message: z.string().describe("Error message") })
	.partial();
export type adminJsonLogoutResponse401 = z.infer<
	typeof adminJsonLogoutResponse401
>;

const setUserLocaleResponse200 = z.object({ locale: z.string() }).partial();
export type setUserLocaleResponse200 = z.infer<typeof setUserLocaleResponse200>;

const forgotPasswordResponse200 = z.unknown();
export type forgotPasswordResponse200 = z.infer<
	typeof forgotPasswordResponse200
>;

const forgotPasswordResponse404 = z.unknown();
export type forgotPasswordResponse404 = z.infer<
	typeof forgotPasswordResponse404
>;

const resetPasswordResponse200 = z.unknown();
export type resetPasswordResponse200 = z.infer<typeof resetPasswordResponse200>;

const resetPasswordResponse404 = z.unknown();
export type resetPasswordResponse404 = z.infer<typeof resetPasswordResponse404>;

const validateUserPasswordResetResponse200 = z.unknown();
export type validateUserPasswordResetResponse200 = z.infer<
	typeof validateUserPasswordResetResponse200
>;

const validateUserPasswordResetResponse403 = z.unknown();
export type validateUserPasswordResetResponse403 = z.infer<
	typeof validateUserPasswordResetResponse403
>;

const updateSelectedCountriesResponse200 = z
	.object({
		status: z.union([z.string(), z.boolean()]).describe("status"),
		message: z.string().describe("message"),
	})
	.partial();
export type updateSelectedCountriesResponse200 = z.infer<
	typeof updateSelectedCountriesResponse200
>;

const updateSelectedCountriesResponse400 = z.unknown();
export type updateSelectedCountriesResponse400 = z.infer<
	typeof updateSelectedCountriesResponse400
>;

const updateSelectedCountriesResponse403 = z.unknown();
export type updateSelectedCountriesResponse403 = z.infer<
	typeof updateSelectedCountriesResponse403
>;

const getSubscriptionCountryMessageResponse200 = z
	.object({
		countries: CountriesData,
		hideMsgModal: z.boolean(),
		message: z.string(),
	})
	.partial();
export type getSubscriptionCountryMessageResponse200 = z.infer<
	typeof getSubscriptionCountryMessageResponse200
>;

const getSubscriptionCountryMessageResponse403 = z.unknown();
export type getSubscriptionCountryMessageResponse403 = z.infer<
	typeof getSubscriptionCountryMessageResponse403
>;

const removeCountriesSessionDetailsResponse200 = z
	.object({
		status: z.string().describe("EventStatus"),
		message: z.string().describe("Change Status Message"),
	})
	.partial();
export type removeCountriesSessionDetailsResponse200 = z.infer<
	typeof removeCountriesSessionDetailsResponse200
>;

const removeCountriesSessionDetailsResponse403 = z.unknown();
export type removeCountriesSessionDetailsResponse403 = z.infer<
	typeof removeCountriesSessionDetailsResponse403
>;

const searchNationalLeaguesResponse200 = z.array(SearchLeagueResponse);
export type searchNationalLeaguesResponse200 = z.infer<
	typeof searchNationalLeaguesResponse200
>;

const getPermissionTreeResponse200 = z.array(PermissionTreeResponse);
export type getPermissionTreeResponse200 = z.infer<
	typeof getPermissionTreeResponse200
>;

const getPermissionTreeResponse403 = z.unknown();
export type getPermissionTreeResponse403 = z.infer<
	typeof getPermissionTreeResponse403
>;

const getPermissionByRoleResponse200 = z
	.object({ data: z.array(z.number().int().describe("ID of the object")) })
	.partial();
export type getPermissionByRoleResponse200 = z.infer<
	typeof getPermissionByRoleResponse200
>;

const getPermissionByRoleResponse403 = z.unknown();
export type getPermissionByRoleResponse403 = z.infer<
	typeof getPermissionByRoleResponse403
>;

const assignPermissionToRoleResponse200 = z
	.object({
		status: z.string().describe("status"),
		message: z.string().describe("message"),
	})
	.partial();
export type assignPermissionToRoleResponse200 = z.infer<
	typeof assignPermissionToRoleResponse200
>;

const assignPermissionToRoleResponse403 = z.unknown();
export type assignPermissionToRoleResponse403 = z.infer<
	typeof assignPermissionToRoleResponse403
>;

const deleteRoleResponse200 = z
	.object({
		status: z.string().describe("status"),
		message: z.string().describe("message"),
		data: z.array(RolesData),
	})
	.partial();
export type deleteRoleResponse200 = z.infer<typeof deleteRoleResponse200>;

const deleteRoleResponse403 = z.unknown();
export type deleteRoleResponse403 = z.infer<typeof deleteRoleResponse403>;

const getRolesResponse200 = z.array(RolesData);
export type getRolesResponse200 = z.infer<typeof getRolesResponse200>;

const getRolesResponse403 = z.unknown();
export type getRolesResponse403 = z.infer<typeof getRolesResponse403>;

const getUserRolesResponse200 = z.object({ data: RolesData }).partial();
export type getUserRolesResponse200 = z.infer<typeof getUserRolesResponse200>;

const getUserRolesResponse403 = z.unknown();
export type getUserRolesResponse403 = z.infer<typeof getUserRolesResponse403>;

const addUpdateRoleResponse200 = z
	.object({
		status: z.string().describe("status"),
		message: z.string().describe("message"),
		data: z.array(RolesData),
	})
	.partial();
export type addUpdateRoleResponse200 = z.infer<typeof addUpdateRoleResponse200>;

const addUpdateRoleResponse403 = z.unknown();
export type addUpdateRoleResponse403 = z.infer<typeof addUpdateRoleResponse403>;

const getAllAdminUsersResponse200 = z
	.object({
		status: z.string(),
		data: z.array(AdminUserData),
		recordsFiltered: z.number().int(),
		recordsTotal: z.number().int(),
	})
	.partial();
export type getAllAdminUsersResponse200 = z.infer<
	typeof getAllAdminUsersResponse200
>;

const getAllAdminUsersResponse403 = z.unknown();
export type getAllAdminUsersResponse403 = z.infer<
	typeof getAllAdminUsersResponse403
>;

const addUpdateAdminUsersResponse200 = z
	.object({
		status: z.string().describe("status"),
		message: z.string().describe("message"),
	})
	.partial();
export type addUpdateAdminUsersResponse200 = z.infer<
	typeof addUpdateAdminUsersResponse200
>;

const addUpdateAdminUsersResponse403 = z.unknown();
export type addUpdateAdminUsersResponse403 = z.infer<
	typeof addUpdateAdminUsersResponse403
>;

const deleteAdminUserResponse200 = z
	.object({
		status: z.string().describe("DeleteStatus"),
		message: z.string().describe("Change Status Message"),
	})
	.partial();
export type deleteAdminUserResponse200 = z.infer<
	typeof deleteAdminUserResponse200
>;

const deleteAdminUserResponse400 = z.unknown();
export type deleteAdminUserResponse400 = z.infer<
	typeof deleteAdminUserResponse400
>;

const deleteAdminUserResponse403 = z.unknown();
export type deleteAdminUserResponse403 = z.infer<
	typeof deleteAdminUserResponse403
>;

const uploadProfilePhotoResponse200 = z
	.object({
		status: z.string().describe("status"),
		message: z.string().describe("message"),
		url: z.string().describe("message"),
	})
	.partial();
export type uploadProfilePhotoResponse200 = z.infer<
	typeof uploadProfilePhotoResponse200
>;

const uploadProfilePhotoResponse403 = z.unknown();
export type uploadProfilePhotoResponse403 = z.infer<
	typeof uploadProfilePhotoResponse403
>;

const getAllPolesResponse200 = z
	.object({
		status: z.string(),
		data: z.array(AllPoleData),
		recordsFiltered: z.number().int(),
		recordsTotal: z.number().int(),
	})
	.partial();
export type getAllPolesResponse200 = z.infer<typeof getAllPolesResponse200>;

const getAllPolesResponse403 = z.unknown();
export type getAllPolesResponse403 = z.infer<typeof getAllPolesResponse403>;

const addUpdatePoleResponse200 = z
	.object({
		data: PoleData,
		status: z.string().describe("status"),
		message: z.string().describe("message"),
	})
	.partial();
export type addUpdatePoleResponse200 = z.infer<typeof addUpdatePoleResponse200>;

const addUpdatePoleResponse403 = z.unknown();
export type addUpdatePoleResponse403 = z.infer<typeof addUpdatePoleResponse403>;

const getTimezonesResponse200 = z.array(z.string());
export type getTimezonesResponse200 = z.infer<typeof getTimezonesResponse200>;

const deletePoleResponse200 = z
	.object({
		status: z.string().describe("status"),
		message: z.string().describe("message"),
		sync: z.boolean().describe("sync"),
	})
	.partial();
export type deletePoleResponse200 = z.infer<typeof deletePoleResponse200>;

const deletePoleResponse400 = z.unknown();
export type deletePoleResponse400 = z.infer<typeof deletePoleResponse400>;

const deletePoleResponse403 = z.unknown();
export type deletePoleResponse403 = z.infer<typeof deletePoleResponse403>;

const getSelectedPoleByClubResponse200 = z.array(DefaultResponseOptions);
export type getSelectedPoleByClubResponse200 = z.infer<
	typeof getSelectedPoleByClubResponse200
>;

const getSelectedPoleByClubResponse400 = z.unknown();
export type getSelectedPoleByClubResponse400 = z.infer<
	typeof getSelectedPoleByClubResponse400
>;

const assignPoleResponse200 = z
	.object({
		data: PoleData.describe("pole data").nullable(),
		message: z.string().describe("Pole Assignment Message"),
		status: z.string().describe("success"),
		sync: z.boolean().describe("sync status").nullable(),
	})
	.partial();
export type assignPoleResponse200 = z.infer<typeof assignPoleResponse200>;

const assignPoleResponse403 = z.unknown();
export type assignPoleResponse403 = z.infer<typeof assignPoleResponse403>;

const unAssignPoleResponse200 = z
	.object({
		data: PoleData,
		message: z.string().describe("Pole Un Assignment Message"),
		status: z.string().describe("success"),
		sync: z.boolean().describe("sync status").nullable(),
	})
	.partial();
export type unAssignPoleResponse200 = z.infer<typeof unAssignPoleResponse200>;

const unAssignPoleResponse403 = z.unknown();
export type unAssignPoleResponse403 = z.infer<typeof unAssignPoleResponse403>;

const syncPoleResponse200 = z
	.object({
		message: z.string().describe("pole synced Message"),
		status: z.string().describe("success"),
		sync: z.boolean().describe("sync status").nullable(),
	})
	.partial();
export type syncPoleResponse200 = z.infer<typeof syncPoleResponse200>;

const syncPoleResponse400 = z.unknown();
export type syncPoleResponse400 = z.infer<typeof syncPoleResponse400>;

const getClubListResponse200 = z.array(FilterData);
export type getClubListResponse200 = z.infer<typeof getClubListResponse200>;

const getClubListResponse403 = z.unknown();
export type getClubListResponse403 = z.infer<typeof getClubListResponse403>;

const getCountriesResponse200 = z.unknown();
export type getCountriesResponse200 = z.infer<typeof getCountriesResponse200>;

const getCountriesResponse403 = z.unknown();
export type getCountriesResponse403 = z.infer<typeof getCountriesResponse403>;

const getContinentCountryResponse200 = z.array(ContinentCountryResponse);
export type getContinentCountryResponse200 = z.infer<
	typeof getContinentCountryResponse200
>;

const getContinentCountryResponse403 = z.unknown();
export type getContinentCountryResponse403 = z.infer<
	typeof getContinentCountryResponse403
>;

const getNPClubsResponse200 = z
	.object({
		status: z.string(),
		data: z.array(ClubData),
		recordsFiltered: z.number().int(),
		recordsTotal: z.number().int(),
	})
	.partial();
export type getNPClubsResponse200 = z.infer<typeof getNPClubsResponse200>;

const getNPClubsResponse403 = z.unknown();
export type getNPClubsResponse403 = z.infer<typeof getNPClubsResponse403>;

const addUpdateNPClubResponse200 = z.object({
	data: ClubData.optional(),
	status: z.string().describe("status"),
	message: z.string().describe("message"),
});
export type addUpdateNPClubResponse200 = z.infer<
	typeof addUpdateNPClubResponse200
>;

const addUpdateNPClubResponse403 = z.unknown();
export type addUpdateNPClubResponse403 = z.infer<
	typeof addUpdateNPClubResponse403
>;

const deleteNPClubResponse200 = z
	.object({
		status: z.string().describe("status"),
		message: z.string().describe("message"),
	})
	.partial();
export type deleteNPClubResponse200 = z.infer<typeof deleteNPClubResponse200>;

const deleteNPClubResponse403 = z.unknown();
export type deleteNPClubResponse403 = z.infer<typeof deleteNPClubResponse403>;

const uploadNPClubPhotoResponse200 = z
	.object({
		status: z.string().describe("status"),
		message: z.string().describe("message"),
		url: z.string().describe("message"),
	})
	.partial();
export type uploadNPClubPhotoResponse200 = z.infer<
	typeof uploadNPClubPhotoResponse200
>;

const uploadNPClubPhotoResponse403 = z.unknown();
export type uploadNPClubPhotoResponse403 = z.infer<
	typeof uploadNPClubPhotoResponse403
>;

const uploadNPPlayerPictureResponse200 = z
	.object({
		status: z.string().describe("status"),
		message: z.string().describe("message"),
		url: z.string().describe("message"),
	})
	.partial();
export type uploadNPPlayerPictureResponse200 = z.infer<
	typeof uploadNPPlayerPictureResponse200
>;

const uploadNPPlayerPictureResponse403 = z.unknown();
export type uploadNPPlayerPictureResponse403 = z.infer<
	typeof uploadNPPlayerPictureResponse403
>;

const getNPPlayersResponse200 = z
	.object({
		status: z.string(),
		data: z.array(PlayerData),
		recordsFiltered: z.number().int(),
		recordsTotal: z.number().int(),
	})
	.partial();
export type getNPPlayersResponse200 = z.infer<typeof getNPPlayersResponse200>;

const getNPPlayersResponse403 = z.unknown();
export type getNPPlayersResponse403 = z.infer<typeof getNPPlayersResponse403>;

const getNationalityListResponse200 = z.array(DefaultResponseOptions);
export type getNationalityListResponse200 = z.infer<
	typeof getNationalityListResponse200
>;

const getNationalityListResponse403 = z.unknown();
export type getNationalityListResponse403 = z.infer<
	typeof getNationalityListResponse403
>;

const getPositionListResponse200 = z.array(DefaultResponseOptions);
export type getPositionListResponse200 = z.infer<
	typeof getPositionListResponse200
>;

const getPositionListResponse403 = z.unknown();
export type getPositionListResponse403 = z.infer<
	typeof getPositionListResponse403
>;

const getGeographicalAreaListResponse200 = z.array(DefaultResponseOptions);
export type getGeographicalAreaListResponse200 = z.infer<
	typeof getGeographicalAreaListResponse200
>;

const getGeographicalAreaListResponse403 = z.unknown();
export type getGeographicalAreaListResponse403 = z.infer<
	typeof getGeographicalAreaListResponse403
>;

const addUpdateNPPlayerResponse200 = z
	.object({
		data: ClubData,
		status: z.string().describe("status"),
		message: z.string().describe("message"),
	})
	.partial();
export type addUpdateNPPlayerResponse200 = z.infer<
	typeof addUpdateNPPlayerResponse200
>;

const addUpdateNPPlayerResponse403 = z.unknown();
export type addUpdateNPPlayerResponse403 = z.infer<
	typeof addUpdateNPPlayerResponse403
>;

const deleteNPPlayerResponse200 = z
	.object({
		status: z.string().describe("status"),
		message: z.string().describe("message"),
	})
	.partial();
export type deleteNPPlayerResponse200 = z.infer<
	typeof deleteNPPlayerResponse200
>;

const deleteNPPlayerResponse403 = z.unknown();
export type deleteNPPlayerResponse403 = z.infer<
	typeof deleteNPPlayerResponse403
>;

const getAllNPClubsResponse200 = z.array(ClubOptionData);
export type getAllNPClubsResponse200 = z.infer<typeof getAllNPClubsResponse200>;

const getAllNPClubsResponse403 = z.unknown();
export type getAllNPClubsResponse403 = z.infer<typeof getAllNPClubsResponse403>;

const getClubPlayersResponse200 = z.array(ClubPlayers);
export type getClubPlayersResponse200 = z.infer<
	typeof getClubPlayersResponse200
>;

const executeNPClubMigrationResponse200 = z.unknown();
export type executeNPClubMigrationResponse200 = z.infer<
	typeof executeNPClubMigrationResponse200
>;

const executeNPClubMigrationResponse403 = z.unknown();
export type executeNPClubMigrationResponse403 = z.infer<
	typeof executeNPClubMigrationResponse403
>;

const getTeamsByTargetClubResponse200 = z.array(FilterData);
export type getTeamsByTargetClubResponse200 = z.infer<
	typeof getTeamsByTargetClubResponse200
>;

const getTeamsByTargetClubResponse403 = z.unknown();
export type getTeamsByTargetClubResponse403 = z.infer<
	typeof getTeamsByTargetClubResponse403
>;

const playerExistsTargetClubResponse200 = z
	.object({
		status: z.string(),
		percentage: z.number().int(),
		id: z.number().int(),
		targetPlayer: z.number().int().nullable(),
	})
	.partial();
export type playerExistsTargetClubResponse200 = z.infer<
	typeof playerExistsTargetClubResponse200
>;

const playerExistsTargetClubResponse403 = z.unknown();
export type playerExistsTargetClubResponse403 = z.infer<
	typeof playerExistsTargetClubResponse403
>;

const getMigrationStatusResponse200 = z
	.object({
		failed: z
			.object({
				skippedPlayersCount: z.number().int(),
				skippedPlayers: z.array(z.unknown()),
			})
			.partial(),
		success: z
			.object({
				migratedPlayersCount: z.number().int(),
				migratedPlayers: z.array(z.unknown()),
				migratedGamesCount: z.number().int(),
				migratedGames: z.array(z.unknown()),
			})
			.partial(),
	})
	.partial();
export type getMigrationStatusResponse200 = z.infer<
	typeof getMigrationStatusResponse200
>;

const getMigrationStatusResponse403 = z.unknown();
export type getMigrationStatusResponse403 = z.infer<
	typeof getMigrationStatusResponse403
>;

const executePartnerClubMigrationResponse200 = z.unknown();
export type executePartnerClubMigrationResponse200 = z.infer<
	typeof executePartnerClubMigrationResponse200
>;

const executePartnerClubMigrationResponse403 = z.unknown();
export type executePartnerClubMigrationResponse403 = z.infer<
	typeof executePartnerClubMigrationResponse403
>;

const createPartnerPlayerFromNonpartnerResponse200 = z.object({
	id: z.number().int(),
	percentage: z.number().int(),
	status: z.string(),
	targetPlayer: z
		.array(
			z.object({
				team: z.string().describe("Team name"),
				status: z.string().describe("Status"),
				firstName: z.string().describe("First Name of Target Player"),
				lastName: z.string().describe("Last Name of Target Player"),
			}),
		)
		.nullable(),
});
export type createPartnerPlayerFromNonpartnerResponse200 = z.infer<
	typeof createPartnerPlayerFromNonpartnerResponse200
>;

const partnerPlayerExistsTargetClubResponse200 = z
	.object({
		status: z.string(),
		percentage: z.number().int(),
		id: z.number().int(),
		targetPlayer: z.number().int().nullable(),
	})
	.partial();
export type partnerPlayerExistsTargetClubResponse200 = z.infer<
	typeof partnerPlayerExistsTargetClubResponse200
>;

const partnerPlayerExistsTargetClubResponse403 = z.unknown();
export type partnerPlayerExistsTargetClubResponse403 = z.infer<
	typeof partnerPlayerExistsTargetClubResponse403
>;

const getPartnerMigrationStatusResponse200 = z
	.object({
		failed: z
			.object({
				skippedPlayersCount: z.number().int(),
				skippedPlayers: z.array(z.unknown()),
			})
			.partial(),
		success: z
			.object({
				migratedPlayersCount: z.number().int(),
				migratedPlayers: z.array(z.unknown()),
				migratedGamesCount: z.number().int(),
				migratedGames: z.array(z.unknown()),
			})
			.partial(),
	})
	.partial();
export type getPartnerMigrationStatusResponse200 = z.infer<
	typeof getPartnerMigrationStatusResponse200
>;

const getPartnerMigrationStatusResponse403 = z.unknown();
export type getPartnerMigrationStatusResponse403 = z.infer<
	typeof getPartnerMigrationStatusResponse403
>;

const updateGameTeamResponse200 = z
	.object({
		status: z.string(),
		message: z.string(),
		data: z.object({}).partial(),
	})
	.partial();
export type updateGameTeamResponse200 = z.infer<
	typeof updateGameTeamResponse200
>;

const updateGameTeamResponse403 = z.unknown();
export type updateGameTeamResponse403 = z.infer<
	typeof updateGameTeamResponse403
>;

const migrateSourceClubNoPlayersResponse200 = z
	.object({
		status: z.string().describe("status"),
		message: z.string().describe("message"),
		data: z.array(z.object({}).partial()).describe("data"),
	})
	.partial();
export type migrateSourceClubNoPlayersResponse200 = z.infer<
	typeof migrateSourceClubNoPlayersResponse200
>;

const migrateSourceClubNoPlayersResponse403 = z.unknown();
export type migrateSourceClubNoPlayersResponse403 = z.infer<
	typeof migrateSourceClubNoPlayersResponse403
>;

const clubMigrationWithZeroPlayersResponse200 = z.object({
	data: z.array(MigrateZeroPlayerClub),
	status: z.string().optional(),
	message: z.string().optional(),
});
export type clubMigrationWithZeroPlayersResponse200 = z.infer<
	typeof clubMigrationWithZeroPlayersResponse200
>;

const playerMigrationToPartnerResponse200 = z.object({
	id: z.number().int(),
	percentage: z.number().int(),
	status: z.string(),
	targetPlayer: z
		.array(
			z.object({
				team: z.string().describe("Team name"),
				status: z.string().describe("Status"),
				firstName: z.string().describe("First Name of Target Player"),
				lastName: z.string().describe("Last Name of Target Player"),
			}),
		)
		.nullable(),
});
export type playerMigrationToPartnerResponse200 = z.infer<
	typeof playerMigrationToPartnerResponse200
>;

const playerMigrationConflictResponse200 = z
	.object({
		id: z.number().int().nullable(),
		percentage: z.number().int().nullable(),
		status: z.union([z.string(), z.number()]),
		message: z.string().nullable(),
		targetPlayer: z
			.array(
				z.object({
					team: z.string().describe("Team name"),
					status: z.string().describe("Status"),
					firstName: z.string().describe("First Name of Target Player"),
					lastName: z.string().describe("Last Name of Target Player"),
				}),
			)
			.nullable(),
	})
	.partial();
export type playerMigrationConflictResponse200 = z.infer<
	typeof playerMigrationConflictResponse200
>;

const getAllCountriesTooltipResponse200 = z
	.object({
		status: z.string(),
		data: z.array(AllCountryTooltipData),
		recordsFiltered: z.number().int(),
		recordsTotal: z.number().int(),
	})
	.partial();
export type getAllCountriesTooltipResponse200 = z.infer<
	typeof getAllCountriesTooltipResponse200
>;

const getAllCountriesTooltipResponse403 = z.unknown();
export type getAllCountriesTooltipResponse403 = z.infer<
	typeof getAllCountriesTooltipResponse403
>;

const addUpdateCountryResponse200 = z
	.object({
		message: z.string().describe("message"),
		status: z.string().describe("status"),
	})
	.partial();
export type addUpdateCountryResponse200 = z.infer<
	typeof addUpdateCountryResponse200
>;

const addUpdateCountryResponse403 = z.unknown();
export type addUpdateCountryResponse403 = z.infer<
	typeof addUpdateCountryResponse403
>;

const deleteCountryResponse200 = z
	.object({
		message: z.string().describe("message"),
		status: z.string().describe("status"),
	})
	.partial();
export type deleteCountryResponse200 = z.infer<typeof deleteCountryResponse200>;

const deleteCountryResponse400 = z.unknown();
export type deleteCountryResponse400 = z.infer<typeof deleteCountryResponse400>;

const deleteCountryResponse403 = z.unknown();
export type deleteCountryResponse403 = z.infer<typeof deleteCountryResponse403>;

const allCountriesResponse200 = z.unknown();
export type allCountriesResponse200 = z.infer<typeof allCountriesResponse200>;

const allCountriesResponse403 = z.unknown();
export type allCountriesResponse403 = z.infer<typeof allCountriesResponse403>;

const enableDisableCountryResponse200 = z
	.object({
		message: z.string().describe("message"),
		status: z.string().describe("status"),
		data: UpdateCountryTooltipData,
	})
	.partial();
export type enableDisableCountryResponse200 = z.infer<
	typeof enableDisableCountryResponse200
>;

const enableDisableCountryResponse403 = z.unknown();
export type enableDisableCountryResponse403 = z.infer<
	typeof enableDisableCountryResponse403
>;

const loadSubscriptionDataResponse200 = z
	.object({
		status: z.string(),
		data: z.array(SubscriptionData),
		recordsFiltered: z.number().int(),
		recordsTotal: z.number().int(),
	})
	.partial();
export type loadSubscriptionDataResponse200 = z.infer<
	typeof loadSubscriptionDataResponse200
>;

const loadSubscriptionDataResponse403 = z.unknown();
export type loadSubscriptionDataResponse403 = z.infer<
	typeof loadSubscriptionDataResponse403
>;

const loadOwnershipsResponse200 = z
	.object({
		data: z.array(
			z.object({ label: z.string(), value: z.number().int() }).partial(),
		),
	})
	.partial();
export type loadOwnershipsResponse200 = z.infer<
	typeof loadOwnershipsResponse200
>;

const loadOwnershipsResponse403 = z.unknown();
export type loadOwnershipsResponse403 = z.infer<
	typeof loadOwnershipsResponse403
>;

const getSubscriptionFilterDataResponse200 = z
	.object({ clubs: z.array(FilterData), products: z.array(FilterData) })
	.partial();
export type getSubscriptionFilterDataResponse200 = z.infer<
	typeof getSubscriptionFilterDataResponse200
>;

const getSubscriptionFilterDataResponse403 = z.unknown();
export type getSubscriptionFilterDataResponse403 = z.infer<
	typeof getSubscriptionFilterDataResponse403
>;

const addUpdateSubscriptionResponse200 = z
	.object({ message: z.string(), status: z.string() })
	.partial();
export type addUpdateSubscriptionResponse200 = z.infer<
	typeof addUpdateSubscriptionResponse200
>;

const addUpdateSubscriptionResponse403 = z.unknown();
export type addUpdateSubscriptionResponse403 = z.infer<
	typeof addUpdateSubscriptionResponse403
>;

const assignSubscriptionToClubResponse200 = z
	.object({ message: z.string(), status: z.string() })
	.partial();
export type assignSubscriptionToClubResponse200 = z.infer<
	typeof assignSubscriptionToClubResponse200
>;

const assignSubscriptionToClubResponse403 = z.unknown();
export type assignSubscriptionToClubResponse403 = z.infer<
	typeof assignSubscriptionToClubResponse403
>;

const deleteSubscriptionResponse200 = z
	.object({ message: z.string(), status: z.string() })
	.partial();
export type deleteSubscriptionResponse200 = z.infer<
	typeof deleteSubscriptionResponse200
>;

const deleteSubscriptionResponse403 = z.unknown();
export type deleteSubscriptionResponse403 = z.infer<
	typeof deleteSubscriptionResponse403
>;

const getAllSpecificGenericNotificationsResponse200 = z
	.object({
		status: z.string(),
		data: z.array(AllSpecificGenericNotificationData),
		recordsFiltered: z.number().int(),
		recordsTotal: z.number().int(),
	})
	.partial();
export type getAllSpecificGenericNotificationsResponse200 = z.infer<
	typeof getAllSpecificGenericNotificationsResponse200
>;

const getAllSpecificGenericNotificationsResponse403 = z.unknown();
export type getAllSpecificGenericNotificationsResponse403 = z.infer<
	typeof getAllSpecificGenericNotificationsResponse403
>;

const getNotificationInstantsResponse200 = z
	.object({
		status: z.string(),
		data: z.array(NotificationInstantData),
		recordsFiltered: z.number().int(),
		recordsTotal: z.number().int(),
	})
	.partial();
export type getNotificationInstantsResponse200 = z.infer<
	typeof getNotificationInstantsResponse200
>;

const getNotificationInstantsResponse403 = z.unknown();
export type getNotificationInstantsResponse403 = z.infer<
	typeof getNotificationInstantsResponse403
>;

const retryFailedNotificationResponse200 = z
	.object({ status: z.string(), message: z.string() })
	.partial();
export type retryFailedNotificationResponse200 = z.infer<
	typeof retryFailedNotificationResponse200
>;

const retryFailedNotificationResponse400 = z.unknown();
export type retryFailedNotificationResponse400 = z.infer<
	typeof retryFailedNotificationResponse400
>;

const retryFailedNotificationResponse403 = z.unknown();
export type retryFailedNotificationResponse403 = z.infer<
	typeof retryFailedNotificationResponse403
>;

const addSpecificGenericNotificationResponse200 = z
	.object({
		status: z.string(),
		message: z.string(),
		data: AllSpecificGenericNotificationData,
	})
	.partial();
export type addSpecificGenericNotificationResponse200 = z.infer<
	typeof addSpecificGenericNotificationResponse200
>;

const addSpecificGenericNotificationResponse403 = z.unknown();
export type addSpecificGenericNotificationResponse403 = z.infer<
	typeof addSpecificGenericNotificationResponse403
>;

const addSpecificGenericNotificationResponse500 = z.unknown();
export type addSpecificGenericNotificationResponse500 = z.infer<
	typeof addSpecificGenericNotificationResponse500
>;

const testSpecificGenericNotificationResponse200 = z
	.object({ status: z.string(), message: z.string() })
	.partial();
export type testSpecificGenericNotificationResponse200 = z.infer<
	typeof testSpecificGenericNotificationResponse200
>;

const testSpecificGenericNotificationResponse403 = z.unknown();
export type testSpecificGenericNotificationResponse403 = z.infer<
	typeof testSpecificGenericNotificationResponse403
>;

const testSpecificGenericNotificationResponse500 = z.unknown();
export type testSpecificGenericNotificationResponse500 = z.infer<
	typeof testSpecificGenericNotificationResponse500
>;

const refreshJobNotificationResponse200 = z
	.object({
		status: z.string(),
		message: z.string(),
		data: NotificationJobData,
	})
	.partial();
export type refreshJobNotificationResponse200 = z.infer<
	typeof refreshJobNotificationResponse200
>;

const refreshJobNotificationResponse400 = z.unknown();
export type refreshJobNotificationResponse400 = z.infer<
	typeof refreshJobNotificationResponse400
>;

const refreshJobNotificationResponse403 = z.unknown();
export type refreshJobNotificationResponse403 = z.infer<
	typeof refreshJobNotificationResponse403
>;

const getAllLanguagesResponse200 = z.array(DefaultResponseOptions);
export type getAllLanguagesResponse200 = z.infer<
	typeof getAllLanguagesResponse200
>;

const userSearchResponse200 = z.array(FilterData);
export type userSearchResponse200 = z.infer<typeof userSearchResponse200>;

const userSearchResponse403 = z.unknown();
export type userSearchResponse403 = z.infer<typeof userSearchResponse403>;

const clubAmateurSearchResponse200 = z.array(FilterData);
export type clubAmateurSearchResponse200 = z.infer<
	typeof clubAmateurSearchResponse200
>;

const clubAmateurSearchResponse403 = z.unknown();
export type clubAmateurSearchResponse403 = z.infer<
	typeof clubAmateurSearchResponse403
>;

const clubProSearchResponse200 = z.array(FilterData);
export type clubProSearchResponse200 = z.infer<typeof clubProSearchResponse200>;

const clubProSearchResponse403 = z.unknown();
export type clubProSearchResponse403 = z.infer<typeof clubProSearchResponse403>;

const getAllSubscriptionProductsResponse200 = z.array(DefaultResponseOptions);
export type getAllSubscriptionProductsResponse200 = z.infer<
	typeof getAllSubscriptionProductsResponse200
>;

const getUserSessionsResponse200 = z.array(UserSessionLog);
export type getUserSessionsResponse200 = z.infer<
	typeof getUserSessionsResponse200
>;

const revokeSessionsResponse200 = z
	.object({ sessionsDeleted: z.array(z.string()) })
	.partial();
export type revokeSessionsResponse200 = z.infer<
	typeof revokeSessionsResponse200
>;

const getUserSignInsResponse200 = z.array(
	z
		.object({
			id: z.string(),
			location: z
				.object({
					city: z.string(),
					state: z.string(),
					countryOrRegion: z.string(),
				})
				.partial(),
			device: z
				.object({
					deviceId: z.string(),
					displayName: z.string(),
					operatingSystem: z.string(),
					browser: z.string(),
					isCompliant: z.boolean(),
					isManaged: z.boolean(),
					trustType: z.string(),
				})
				.partial(),
			appDisplayName: z.string(),
			createdDateTime: z.string(),
		})
		.partial(),
);
export type getUserSignInsResponse200 = z.infer<
	typeof getUserSignInsResponse200
>;

const logUserActivityResponse400 = z.object({ code: z.string() });
export type logUserActivityResponse400 = z.infer<
	typeof logUserActivityResponse400
>;

const getClubAllTeamsResponse400 = z
	.object({ property_path: z.string(), message: z.string() })
	.partial();
export type getClubAllTeamsResponse400 = z.infer<
	typeof getClubAllTeamsResponse400
>;

const getClubAllTeamsResponse403 = z
	.object({ status: z.number().int(), message: z.string() })
	.partial();
export type getClubAllTeamsResponse403 = z.infer<
	typeof getClubAllTeamsResponse403
>;

const getTeamByIdAndCoachIdResponse200 = z
	.object({
		status: z.number().int().describe("status"),
		data: z.array(ClubTeamInfo).describe("team details"),
	})
	.partial();
export type getTeamByIdAndCoachIdResponse200 = z.infer<
	typeof getTeamByIdAndCoachIdResponse200
>;

const getTeamByIdAndCoachIdResponse403 = z
	.object({ status: z.number().int(), message: z.string() })
	.partial();
export type getTeamByIdAndCoachIdResponse403 = z.infer<
	typeof getTeamByIdAndCoachIdResponse403
>;

const addUpdateTeamResponse200 = z
	.object({
		status: z.number().int().describe("status"),
		message: z.string().describe("message"),
	})
	.partial();
export type addUpdateTeamResponse200 = z.infer<typeof addUpdateTeamResponse200>;

const addUpdateTeamResponse400 = z
	.object({ property_path: z.string(), message: z.string() })
	.partial();
export type addUpdateTeamResponse400 = z.infer<typeof addUpdateTeamResponse400>;

const addUpdateTeamResponse403 = z
	.object({ status: z.number().int(), message: z.string() })
	.partial();
export type addUpdateTeamResponse403 = z.infer<typeof addUpdateTeamResponse403>;

const addUpdateTeamResponse409 = z.unknown();
export type addUpdateTeamResponse409 = z.infer<typeof addUpdateTeamResponse409>;

const deleteTeamResponse200 = z
	.object({
		status: z.number().int().describe("status"),
		message: z.string().describe("message"),
	})
	.partial();
export type deleteTeamResponse200 = z.infer<typeof deleteTeamResponse200>;

const deleteTeamResponse403 = z
	.object({ status: z.number().int(), message: z.string() })
	.partial();
export type deleteTeamResponse403 = z.infer<typeof deleteTeamResponse403>;

const deleteTeamResponse404 = z.unknown();
export type deleteTeamResponse404 = z.infer<typeof deleteTeamResponse404>;

const clubCoachesResponse200 = z.array(DefaultSelectResponse);
export type clubCoachesResponse200 = z.infer<typeof clubCoachesResponse200>;

const clubCoachesResponse403 = z
	.object({ status: z.number().int(), message: z.string() })
	.partial();
export type clubCoachesResponse403 = z.infer<typeof clubCoachesResponse403>;

const getClubTeamsListResponse200 = z.array(DefaultSelectResponse);
export type getClubTeamsListResponse200 = z.infer<
	typeof getClubTeamsListResponse200
>;

const getClubTeamsListResponse403 = z
	.object({ status: z.number().int(), message: z.string() })
	.partial();
export type getClubTeamsListResponse403 = z.infer<
	typeof getClubTeamsListResponse403
>;

const teamPlayersResponse400 = z
	.object({ property_path: z.string(), message: z.string() })
	.partial();
export type teamPlayersResponse400 = z.infer<typeof teamPlayersResponse400>;

const teamPlayersResponse403 = z
	.object({ status: z.number().int(), message: z.string() })
	.partial();
export type teamPlayersResponse403 = z.infer<typeof teamPlayersResponse403>;

const addUpdatePlayerResponse200 = z
	.object({
		status: z.number().int().describe("status"),
		message: z.string().describe("message"),
		data: ClubPlayersInfo,
	})
	.partial();
export type addUpdatePlayerResponse200 = z.infer<
	typeof addUpdatePlayerResponse200
>;

const addUpdatePlayerResponse400 = z
	.object({ property_path: z.string(), message: z.string() })
	.partial();
export type addUpdatePlayerResponse400 = z.infer<
	typeof addUpdatePlayerResponse400
>;

const addUpdatePlayerResponse403 = z
	.object({ status: z.number().int(), message: z.string() })
	.partial();
export type addUpdatePlayerResponse403 = z.infer<
	typeof addUpdatePlayerResponse403
>;

const addUpdatePlayerResponse409 = z
	.object({
		status: z.number().int().describe("status"),
		message: z.string().describe("message"),
		data: z.array(ClubPlayersInfo).describe("Player Data"),
	})
	.partial();
export type addUpdatePlayerResponse409 = z.infer<
	typeof addUpdatePlayerResponse409
>;

const updateVipResponse200 = z
	.object({
		status: z.number().int().describe("status"),
		message: z.string().describe("message"),
	})
	.partial();
export type updateVipResponse200 = z.infer<typeof updateVipResponse200>;

const updateVipResponse403 = z
	.object({ status: z.number().int(), message: z.string() })
	.partial();
export type updateVipResponse403 = z.infer<typeof updateVipResponse403>;

const updateVipResponse404 = z.unknown();
export type updateVipResponse404 = z.infer<typeof updateVipResponse404>;

const updateLevelResponse200 = z
	.object({
		status: z.number().int().describe("status"),
		message: z.string().describe("message"),
	})
	.partial();
export type updateLevelResponse200 = z.infer<typeof updateLevelResponse200>;

const updateLevelResponse403 = z
	.object({ status: z.number().int(), message: z.string() })
	.partial();
export type updateLevelResponse403 = z.infer<typeof updateLevelResponse403>;

const updateLevelResponse404 = z.unknown();
export type updateLevelResponse404 = z.infer<typeof updateLevelResponse404>;

const deletePlayerResponse200 = z
	.object({
		status: z.number().int().describe("status"),
		message: z.string().describe("message"),
	})
	.partial();
export type deletePlayerResponse200 = z.infer<typeof deletePlayerResponse200>;

const deletePlayerResponse403 = z
	.object({
		status: z.number().int().describe("status"),
		message: z.string().describe("message"),
	})
	.partial();
export type deletePlayerResponse403 = z.infer<typeof deletePlayerResponse403>;

const deletePlayerResponse404 = z.unknown();
export type deletePlayerResponse404 = z.infer<typeof deletePlayerResponse404>;

const formerPlayersResponse400 = z
	.object({ property_path: z.string(), message: z.string() })
	.partial();
export type formerPlayersResponse400 = z.infer<typeof formerPlayersResponse400>;

const formerPlayersResponse403 = z
	.object({ status: z.number().int(), message: z.string() })
	.partial();
export type formerPlayersResponse403 = z.infer<typeof formerPlayersResponse403>;

const uploadPlayerPhotoResponse200 = z
	.object({
		status: z.number().int().describe("status"),
		message: z.string().describe("message"),
		url: z.string().describe("message"),
	})
	.partial();
export type uploadPlayerPhotoResponse200 = z.infer<
	typeof uploadPlayerPhotoResponse200
>;

const uploadPlayerPhotoResponse400 = z.unknown();
export type uploadPlayerPhotoResponse400 = z.infer<
	typeof uploadPlayerPhotoResponse400
>;

const uploadPlayerPhotoResponse403 = z
	.object({ status: z.number().int(), message: z.string() })
	.partial();
export type uploadPlayerPhotoResponse403 = z.infer<
	typeof uploadPlayerPhotoResponse403
>;

const assignCoachesToTeamResponse200 = z
	.object({
		status: z.number().int().describe("status"),
		message: z.string().describe("message"),
	})
	.partial();
export type assignCoachesToTeamResponse200 = z.infer<
	typeof assignCoachesToTeamResponse200
>;

const assignCoachesToTeamResponse403 = z
	.object({ status: z.number().int(), message: z.string() })
	.partial();
export type assignCoachesToTeamResponse403 = z.infer<
	typeof assignCoachesToTeamResponse403
>;

const getNationalitiesResponse200 = z.array(DefaultSelectResponse);
export type getNationalitiesResponse200 = z.infer<
	typeof getNationalitiesResponse200
>;

const getCategoriesResponse200 = z.array(DefaultSelectResponse);
export type getCategoriesResponse200 = z.infer<typeof getCategoriesResponse200>;

const batchUpdateResponse200 = z.unknown();
export type batchUpdateResponse200 = z.infer<typeof batchUpdateResponse200>;

const batchUpdateResponse400 = z.unknown();
export type batchUpdateResponse400 = z.infer<typeof batchUpdateResponse400>;

const batchUpdateResponse403 = z.unknown();
export type batchUpdateResponse403 = z.infer<typeof batchUpdateResponse403>;

const generateSignUpLinkResponse200 = z.object({
	shortLink: z.string().describe("Mobile app sign-up short URL"),
	previewLink: z.string().describe("Mobile app sign-up preview URL"),
});
export type generateSignUpLinkResponse200 = z.infer<
	typeof generateSignUpLinkResponse200
>;

const generateSignUpLinkResponse400 = z.unknown();
export type generateSignUpLinkResponse400 = z.infer<
	typeof generateSignUpLinkResponse400
>;

const generateSignUpLinkResponse403 = z.unknown();
export type generateSignUpLinkResponse403 = z.infer<
	typeof generateSignUpLinkResponse403
>;

const generateSignUpLinkResponse404 = z.unknown();
export type generateSignUpLinkResponse404 = z.infer<
	typeof generateSignUpLinkResponse404
>;

const generateSignUpLinkResponse409 = z.unknown();
export type generateSignUpLinkResponse409 = z.infer<
	typeof generateSignUpLinkResponse409
>;

const getPlayerLatestLogEntriesResponse200 = z.object({
	height: z.string().nullable(),
	weight: z.string().nullable(),
	photo: z.string().nullable(),
});
export type getPlayerLatestLogEntriesResponse200 = z.infer<
	typeof getPlayerLatestLogEntriesResponse200
>;

const getGamesWithoutVideoResponse200 = z.array(GameData);
export type getGamesWithoutVideoResponse200 = z.infer<
	typeof getGamesWithoutVideoResponse200
>;

const getVideoUploadsNotificationsResponse200 = z.array(VideoUploadLog);
export type getVideoUploadsNotificationsResponse200 = z.infer<
	typeof getVideoUploadsNotificationsResponse200
>;

const getTestVideosResponse200 = z.array(
	z.object({ host: z.string(), path: z.string() }),
);
export type getTestVideosResponse200 = z.infer<typeof getTestVideosResponse200>;

const migratePlayersResponse200 = z
	.object({
		status: z.number().int().describe("status"),
		message: z.string().describe("message"),
	})
	.partial();
export type migratePlayersResponse200 = z.infer<
	typeof migratePlayersResponse200
>;

const migratePlayersResponse400 = z.array(
	z.object({ property_path: z.string(), message: z.string() }).partial(),
);
export type migratePlayersResponse400 = z.infer<
	typeof migratePlayersResponse400
>;

const migratePlayersResponse403 = z
	.object({ status: z.number().int(), message: z.string() })
	.partial();
export type migratePlayersResponse403 = z.infer<
	typeof migratePlayersResponse403
>;

const getClubDetailResponse400 = z
	.object({ property_path: z.string(), message: z.string() })
	.partial();
export type getClubDetailResponse400 = z.infer<typeof getClubDetailResponse400>;

const getClubDetailResponse403 = z
	.object({ status: z.number().int(), message: z.string() })
	.partial();
export type getClubDetailResponse403 = z.infer<typeof getClubDetailResponse403>;

const jsonLogin = (wretch: Http) => {
	const mutation = async (params: { body: JsonLoginRequest }) => {
		const args = z.object({ body: z.any() }).parse(params);
		const { body } = args;

		try {
			const res = await wretch.url("/admin/json_login").json(body).post().res();
			const json = await res.json();
			switch (res.status) {
				case 200: {
					const data = UserResponse.parse(json);
					return {
						status: res.status,
						data,
						headers: res.headers,
						url: res.url,
					};
				}
			}
		} catch (e) {
			const err = error(e);
			throw err;
		}

		invariant(false);
	};

	return {
		mutation,
		useMutation: (
			options?: Omit<
				UseMutationOptions<
					ApiResponse<UserResponse> | undefined,
					GenericWretchError<null>,
					{ body: JsonLoginRequest }
				>,
				"mutationFn"
			>,
		) => {
			return useMutation<
				ApiResponse<UserResponse> | undefined,
				GenericWretchError<null>,
				{ body: JsonLoginRequest }
			>(mutation, options || {});
		},
	};
};

const getCurrentUser = (wretch: Http, queryClient: QueryClient) => {
	const queryFn = async (
		signal?: AbortSignal | undefined,
	): Promise<ApiResponse<UserResponse>> => {
		try {
			const res = await wretch.options({ signal }).url("/admin/me").get().res();
			const json = await res.json();
			switch (res.status) {
				case 200: {
					const data = UserResponse.parse(json);
					return {
						status: res.status,
						data,
						headers: res.headers,
						url: res.url,
					};
				}
			}
		} catch (e) {
			const err = error(e);
			const json = err.json;
			switch (err.status) {
				case 401: {
					err.json = getCurrentUserResponse401.parse(json);
					break;
				}
				case 403: {
					err.json = getCurrentUserResponse403.parse(json);
					break;
				}
			}
			throw err;
		}

		invariant(false);
	};

	return {
		query: queryFn,
		useQuery: (
			options?: Omit<
				UseQueryOptions<
					ApiResponse<UserResponse>,
					GenericWretchError<
						getCurrentUserResponse401 | getCurrentUserResponse403
					>
				>,
				"queryFn"
			>,
		): UseQueryResult<
			ApiResponse<UserResponse>,
			GenericWretchError<getCurrentUserResponse401 | getCurrentUserResponse403>
		> => {
			const key = ["getCurrentUser", ...(options?.queryKey || [])];
			const query: QueryFunction<ApiResponse<UserResponse>> = async ({
				meta,
				queryKey,
				pageParam,
				signal,
			}) => {
				return queryFn(signal);
			};
			const invalidate = () => queryClient.invalidateQueries(key);
			const queryResult = useQuery<
				ApiResponse<UserResponse>,
				GenericWretchError<
					getCurrentUserResponse401 | getCurrentUserResponse403
				>
			>(key, query, options || {}) as UseQueryResult<
				ApiResponse<UserResponse>,
				GenericWretchError<
					getCurrentUserResponse401 | getCurrentUserResponse403
				>
			>;
			queryResult.invalidate = invalidate;
			queryResult.key = key;
			return queryResult;
		},
		useInfiniteQuery: (
			options?: Omit<
				UseInfiniteQueryOptions<
					ApiResponse<UserResponse>,
					GenericWretchError<
						getCurrentUserResponse401 | getCurrentUserResponse403
					>
				>,
				"queryFn"
			>,
		): UseInfiniteQueryResult<
			ApiResponse<UserResponse>,
			GenericWretchError<getCurrentUserResponse401 | getCurrentUserResponse403>
		> => {
			const key = ["getCurrentUser", ...(options?.queryKey || [])];
			const query: QueryFunction<ApiResponse<UserResponse>> = async ({
				meta,
				queryKey,
				pageParam,
				signal,
			}) => {
				return queryFn(signal);
			};
			const invalidate = () => queryClient.invalidateQueries(key);
			const queryResult = useInfiniteQuery<
				ApiResponse<UserResponse>,
				GenericWretchError<
					getCurrentUserResponse401 | getCurrentUserResponse403
				>
			>(key, query, options || {}) as UseInfiniteQueryResult<
				ApiResponse<UserResponse>,
				GenericWretchError<
					getCurrentUserResponse401 | getCurrentUserResponse403
				>
			>;
			queryResult.invalidate = invalidate;
			queryResult.key = key;
			return queryResult;
		},
	};
};

const adminJsonLogout = (wretch: Http) => {
	const mutation = async () => {
		try {
			const res = await wretch.url("/admin/json_logout").post().res();
			const json = await res.json();
			switch (res.status) {
				case 200: {
					const data = adminJsonLogoutResponse200.parse(json);
					return {
						status: res.status,
						data,
						headers: res.headers,
						url: res.url,
					};
				}
			}
		} catch (e) {
			const err = error(e);
			const json = err.json;
			switch (err.status) {
				case 401: {
					err.json = adminJsonLogoutResponse401.parse(json);
					break;
				}
			}
			throw err;
		}

		invariant(false);
	};

	return {
		mutation,
		useMutation: (
			options?: Omit<
				UseMutationOptions<
					ApiResponse<adminJsonLogoutResponse200> | undefined,
					GenericWretchError<adminJsonLogoutResponse401>,
					void
				>,
				"mutationFn"
			>,
		) => {
			return useMutation<
				ApiResponse<adminJsonLogoutResponse200> | undefined,
				GenericWretchError<adminJsonLogoutResponse401>,
				void
			>(mutation, options || {});
		},
	};
};

const setUserLocale = (wretch: Http, queryClient: QueryClient) => {
	const queryFn = async (
		params: { locale: string },
		signal?: AbortSignal | undefined,
	): Promise<ApiResponse<setUserLocaleResponse200>> => {
		const args = z.object({ locale: z.string() }).parse(params);
		const { locale } = args;
		try {
			const res = await wretch
				.options({ signal })
				.url("/admin/update_locale")
				.query(qs.stringify({ locale }))
				.get()
				.res();
			const json = await res.json();
			switch (res.status) {
				case 200: {
					const data = setUserLocaleResponse200.parse(json);
					return {
						status: res.status,
						data,
						headers: res.headers,
						url: res.url,
					};
				}
			}
		} catch (e) {
			const err = error(e);
			throw err;
		}

		invariant(false);
	};

	return {
		query: queryFn,
		useQuery: (
			params: { locale: string },
			options?: Omit<
				UseQueryOptions<
					ApiResponse<setUserLocaleResponse200>,
					GenericWretchError<null>
				>,
				"queryFn"
			>,
		): UseQueryResult<
			ApiResponse<setUserLocaleResponse200>,
			GenericWretchError<null>
		> => {
			const key = ["setUserLocale", params, ...(options?.queryKey || [])];
			const query: QueryFunction<ApiResponse<setUserLocaleResponse200>> =
				async ({ meta, queryKey, pageParam, signal }) => {
					return queryFn(params, signal);
				};
			const invalidate = () => queryClient.invalidateQueries(key);
			const queryResult = useQuery<
				ApiResponse<setUserLocaleResponse200>,
				GenericWretchError<null>
			>(key, query, options || {}) as UseQueryResult<
				ApiResponse<setUserLocaleResponse200>,
				GenericWretchError<null>
			>;
			queryResult.invalidate = invalidate;
			queryResult.key = key;
			return queryResult;
		},
		useInfiniteQuery: (
			params: { locale: string },
			options?: Omit<
				UseInfiniteQueryOptions<
					ApiResponse<setUserLocaleResponse200>,
					GenericWretchError<null>
				>,
				"queryFn"
			>,
		): UseInfiniteQueryResult<
			ApiResponse<setUserLocaleResponse200>,
			GenericWretchError<null>
		> => {
			const key = ["setUserLocale", params, ...(options?.queryKey || [])];
			const query: QueryFunction<ApiResponse<setUserLocaleResponse200>> =
				async ({ meta, queryKey, pageParam, signal }) => {
					return queryFn(params, signal);
				};
			const invalidate = () => queryClient.invalidateQueries(key);
			const queryResult = useInfiniteQuery<
				ApiResponse<setUserLocaleResponse200>,
				GenericWretchError<null>
			>(key, query, options || {}) as UseInfiniteQueryResult<
				ApiResponse<setUserLocaleResponse200>,
				GenericWretchError<null>
			>;
			queryResult.invalidate = invalidate;
			queryResult.key = key;
			return queryResult;
		},
	};
};

const forgotPassword = (wretch: Http) => {
	const mutation = async (params: { body: forgotPasswordRequestBody }) => {
		const args = z.object({ body: z.any() }).parse(params);
		const { body } = args;

		try {
			const res = await wretch
				.url("/admin/api/requestPassword")
				.json(body)
				.post()
				.res();
			const json = await res.json();
			switch (res.status) {
				case 200: {
					const data = forgotPasswordResponse200.parse(json);
					return {
						status: res.status,
						data,
						headers: res.headers,
						url: res.url,
					};
				}
			}
		} catch (e) {
			const err = error(e);
			const json = err.json;
			switch (err.status) {
				case 404: {
					err.json = forgotPasswordResponse404.parse(json);
					break;
				}
			}
			throw err;
		}

		invariant(false);
	};

	return {
		mutation,
		useMutation: (
			options?: Omit<
				UseMutationOptions<
					ApiResponse<forgotPasswordResponse200> | undefined,
					GenericWretchError<forgotPasswordResponse404>,
					{ body: forgotPasswordRequestBody }
				>,
				"mutationFn"
			>,
		) => {
			return useMutation<
				ApiResponse<forgotPasswordResponse200> | undefined,
				GenericWretchError<forgotPasswordResponse404>,
				{ body: forgotPasswordRequestBody }
			>(mutation, options || {});
		},
	};
};

const resetPassword = (wretch: Http) => {
	const mutation = async (params: {
		token: string;
		body: resetPasswordRequestBody;
	}) => {
		const args = z.object({ token: z.string(), body: z.any() }).parse(params);
		const { token, body } = args;

		try {
			const res = await wretch
				.url(`/admin/api/resetPassword/${token}`)
				.json(body)
				.post()
				.res();
			const json = await res.json();
			switch (res.status) {
				case 200: {
					const data = resetPasswordResponse200.parse(json);
					return {
						status: res.status,
						data,
						headers: res.headers,
						url: res.url,
					};
				}
			}
		} catch (e) {
			const err = error(e);
			const json = err.json;
			switch (err.status) {
				case 404: {
					err.json = resetPasswordResponse404.parse(json);
					break;
				}
			}
			throw err;
		}

		invariant(false);
	};

	return {
		mutation,
		useMutation: (
			options?: Omit<
				UseMutationOptions<
					ApiResponse<resetPasswordResponse200> | undefined,
					GenericWretchError<resetPasswordResponse404>,
					{ token: string; body: resetPasswordRequestBody }
				>,
				"mutationFn"
			>,
		) => {
			return useMutation<
				ApiResponse<resetPasswordResponse200> | undefined,
				GenericWretchError<resetPasswordResponse404>,
				{ token: string; body: resetPasswordRequestBody }
			>(mutation, options || {});
		},
	};
};

const validateUserPasswordReset = (wretch: Http, queryClient: QueryClient) => {
	const queryFn = async (
		params: { token: string },
		signal?: AbortSignal | undefined,
	): Promise<ApiResponse<validateUserPasswordResetResponse200>> => {
		const args = z.object({ token: z.string() }).parse(params);
		const { token } = args;
		try {
			const res = await wretch
				.options({ signal })
				.url(`/admin/api/validateUserPasswordReset/${token}`)
				.get()
				.res();
			const json = await res.json();
			switch (res.status) {
				case 200: {
					const data = validateUserPasswordResetResponse200.parse(json);
					return {
						status: res.status,
						data,
						headers: res.headers,
						url: res.url,
					};
				}
			}
		} catch (e) {
			const err = error(e);
			const json = err.json;
			switch (err.status) {
				case 403: {
					err.json = validateUserPasswordResetResponse403.parse(json);
					break;
				}
			}
			throw err;
		}

		invariant(false);
	};

	return {
		query: queryFn,
		useQuery: (
			params: { token: string },
			options?: Omit<
				UseQueryOptions<
					ApiResponse<validateUserPasswordResetResponse200>,
					GenericWretchError<validateUserPasswordResetResponse403>
				>,
				"queryFn"
			>,
		): UseQueryResult<
			ApiResponse<validateUserPasswordResetResponse200>,
			GenericWretchError<validateUserPasswordResetResponse403>
		> => {
			const key = [
				"validateUserPasswordReset",
				params,
				...(options?.queryKey || []),
			];
			const query: QueryFunction<
				ApiResponse<validateUserPasswordResetResponse200>
			> = async ({ meta, queryKey, pageParam, signal }) => {
				return queryFn(params, signal);
			};
			const invalidate = () => queryClient.invalidateQueries(key);
			const queryResult = useQuery<
				ApiResponse<validateUserPasswordResetResponse200>,
				GenericWretchError<validateUserPasswordResetResponse403>
			>(key, query, options || {}) as UseQueryResult<
				ApiResponse<validateUserPasswordResetResponse200>,
				GenericWretchError<validateUserPasswordResetResponse403>
			>;
			queryResult.invalidate = invalidate;
			queryResult.key = key;
			return queryResult;
		},
		useInfiniteQuery: (
			params: { token: string },
			options?: Omit<
				UseInfiniteQueryOptions<
					ApiResponse<validateUserPasswordResetResponse200>,
					GenericWretchError<validateUserPasswordResetResponse403>
				>,
				"queryFn"
			>,
		): UseInfiniteQueryResult<
			ApiResponse<validateUserPasswordResetResponse200>,
			GenericWretchError<validateUserPasswordResetResponse403>
		> => {
			const key = [
				"validateUserPasswordReset",
				params,
				...(options?.queryKey || []),
			];
			const query: QueryFunction<
				ApiResponse<validateUserPasswordResetResponse200>
			> = async ({ meta, queryKey, pageParam, signal }) => {
				return queryFn(params, signal);
			};
			const invalidate = () => queryClient.invalidateQueries(key);
			const queryResult = useInfiniteQuery<
				ApiResponse<validateUserPasswordResetResponse200>,
				GenericWretchError<validateUserPasswordResetResponse403>
			>(key, query, options || {}) as UseInfiniteQueryResult<
				ApiResponse<validateUserPasswordResetResponse200>,
				GenericWretchError<validateUserPasswordResetResponse403>
			>;
			queryResult.invalidate = invalidate;
			queryResult.key = key;
			return queryResult;
		},
	};
};

const getSelectedCountries = (wretch: Http, queryClient: QueryClient) => {
	const queryFn = async (
		signal?: AbortSignal | undefined,
	): Promise<ApiResponse<SelectedCountriesData>> => {
		try {
			const res = await wretch
				.options({ signal })
				.url("/admin/api/user/selectedCountries")
				.get()
				.res();
			const json = await res.json();
			switch (res.status) {
				case 200: {
					const data = SelectedCountriesData.parse(json);
					return {
						status: res.status,
						data,
						headers: res.headers,
						url: res.url,
					};
				}
			}
		} catch (e) {
			const err = error(e);
			throw err;
		}

		invariant(false);
	};

	return {
		query: queryFn,
		useQuery: (
			options?: Omit<
				UseQueryOptions<
					ApiResponse<SelectedCountriesData>,
					GenericWretchError<null>
				>,
				"queryFn"
			>,
		): UseQueryResult<
			ApiResponse<SelectedCountriesData>,
			GenericWretchError<null>
		> => {
			const key = ["getSelectedCountries", ...(options?.queryKey || [])];
			const query: QueryFunction<ApiResponse<SelectedCountriesData>> = async ({
				meta,
				queryKey,
				pageParam,
				signal,
			}) => {
				return queryFn(signal);
			};
			const invalidate = () => queryClient.invalidateQueries(key);
			const queryResult = useQuery<
				ApiResponse<SelectedCountriesData>,
				GenericWretchError<null>
			>(key, query, options || {}) as UseQueryResult<
				ApiResponse<SelectedCountriesData>,
				GenericWretchError<null>
			>;
			queryResult.invalidate = invalidate;
			queryResult.key = key;
			return queryResult;
		},
		useInfiniteQuery: (
			options?: Omit<
				UseInfiniteQueryOptions<
					ApiResponse<SelectedCountriesData>,
					GenericWretchError<null>
				>,
				"queryFn"
			>,
		): UseInfiniteQueryResult<
			ApiResponse<SelectedCountriesData>,
			GenericWretchError<null>
		> => {
			const key = ["getSelectedCountries", ...(options?.queryKey || [])];
			const query: QueryFunction<ApiResponse<SelectedCountriesData>> = async ({
				meta,
				queryKey,
				pageParam,
				signal,
			}) => {
				return queryFn(signal);
			};
			const invalidate = () => queryClient.invalidateQueries(key);
			const queryResult = useInfiniteQuery<
				ApiResponse<SelectedCountriesData>,
				GenericWretchError<null>
			>(key, query, options || {}) as UseInfiniteQueryResult<
				ApiResponse<SelectedCountriesData>,
				GenericWretchError<null>
			>;
			queryResult.invalidate = invalidate;
			queryResult.key = key;
			return queryResult;
		},
	};
};

const getAllCountries = (wretch: Http, queryClient: QueryClient) => {
	const queryFn = async (
		params: { locale?: string },
		signal?: AbortSignal | undefined,
	): Promise<ApiResponse<CountryData>> => {
		const args = z.object({ locale: z.string().optional() }).parse(params);
		const { locale } = args;
		try {
			const res = await wretch
				.options({ signal })
				.url("/admin/api/user/countries")
				.query(qs.stringify({ locale }))
				.get()
				.res();
			const json = await res.json();
			switch (res.status) {
				case 200: {
					const data = CountryData.parse(json);
					return {
						status: res.status,
						data,
						headers: res.headers,
						url: res.url,
					};
				}
			}
		} catch (e) {
			const err = error(e);
			throw err;
		}

		invariant(false);
	};

	return {
		query: queryFn,
		useQuery: (
			params: { locale?: string },
			options?: Omit<
				UseQueryOptions<ApiResponse<CountryData>, GenericWretchError<null>>,
				"queryFn"
			>,
		): UseQueryResult<ApiResponse<CountryData>, GenericWretchError<null>> => {
			const key = ["getAllCountries", params, ...(options?.queryKey || [])];
			const query: QueryFunction<ApiResponse<CountryData>> = async ({
				meta,
				queryKey,
				pageParam,
				signal,
			}) => {
				return queryFn(params, signal);
			};
			const invalidate = () => queryClient.invalidateQueries(key);
			const queryResult = useQuery<
				ApiResponse<CountryData>,
				GenericWretchError<null>
			>(key, query, options || {}) as UseQueryResult<
				ApiResponse<CountryData>,
				GenericWretchError<null>
			>;
			queryResult.invalidate = invalidate;
			queryResult.key = key;
			return queryResult;
		},
		useInfiniteQuery: (
			params: { locale?: string },
			options?: Omit<
				UseInfiniteQueryOptions<
					ApiResponse<CountryData>,
					GenericWretchError<null>
				>,
				"queryFn"
			>,
		): UseInfiniteQueryResult<
			ApiResponse<CountryData>,
			GenericWretchError<null>
		> => {
			const key = ["getAllCountries", params, ...(options?.queryKey || [])];
			const query: QueryFunction<ApiResponse<CountryData>> = async ({
				meta,
				queryKey,
				pageParam,
				signal,
			}) => {
				return queryFn(params, signal);
			};
			const invalidate = () => queryClient.invalidateQueries(key);
			const queryResult = useInfiniteQuery<
				ApiResponse<CountryData>,
				GenericWretchError<null>
			>(key, query, options || {}) as UseInfiniteQueryResult<
				ApiResponse<CountryData>,
				GenericWretchError<null>
			>;
			queryResult.invalidate = invalidate;
			queryResult.key = key;
			return queryResult;
		},
	};
};

const updateSelectedCountries = (wretch: Http) => {
	const mutation = async (params: { body: updateSelectedCountries_Body }) => {
		const args = z.object({ body: z.any() }).parse(params);
		const { body } = args;

		try {
			const res = await wretch
				.url("/admin/api/user/countries")
				.json(body)
				.patch()
				.res();
			const json = await res.json();
			switch (res.status) {
				case 200: {
					const data = updateSelectedCountriesResponse200.parse(json);
					return {
						status: res.status,
						data,
						headers: res.headers,
						url: res.url,
					};
				}
			}
		} catch (e) {
			const err = error(e);
			const json = err.json;
			switch (err.status) {
				case 400: {
					err.json = updateSelectedCountriesResponse400.parse(json);
					break;
				}
				case 403: {
					err.json = updateSelectedCountriesResponse403.parse(json);
					break;
				}
			}
			throw err;
		}

		invariant(false);
	};

	return {
		mutation,
		useMutation: (
			options?: Omit<
				UseMutationOptions<
					ApiResponse<updateSelectedCountriesResponse200> | undefined,
					GenericWretchError<
						| updateSelectedCountriesResponse400
						| updateSelectedCountriesResponse403
					>,
					{ body: updateSelectedCountries_Body }
				>,
				"mutationFn"
			>,
		) => {
			return useMutation<
				ApiResponse<updateSelectedCountriesResponse200> | undefined,
				GenericWretchError<
					| updateSelectedCountriesResponse400
					| updateSelectedCountriesResponse403
				>,
				{ body: updateSelectedCountries_Body }
			>(mutation, options || {});
		},
	};
};

const getSubscriptionCountryMessage = (
	wretch: Http,
	queryClient: QueryClient,
) => {
	const queryFn = async (
		signal?: AbortSignal | undefined,
	): Promise<ApiResponse<getSubscriptionCountryMessageResponse200>> => {
		try {
			const res = await wretch
				.options({ signal })
				.url("/admin/api/user/subscriptionCountryMessage")
				.get()
				.res();
			const json = await res.json();
			switch (res.status) {
				case 200: {
					const data = getSubscriptionCountryMessageResponse200.parse(json);
					return {
						status: res.status,
						data,
						headers: res.headers,
						url: res.url,
					};
				}
			}
		} catch (e) {
			const err = error(e);
			const json = err.json;
			switch (err.status) {
				case 403: {
					err.json = getSubscriptionCountryMessageResponse403.parse(json);
					break;
				}
			}
			throw err;
		}

		invariant(false);
	};

	return {
		query: queryFn,
		useQuery: (
			options?: Omit<
				UseQueryOptions<
					ApiResponse<getSubscriptionCountryMessageResponse200>,
					GenericWretchError<getSubscriptionCountryMessageResponse403>
				>,
				"queryFn"
			>,
		): UseQueryResult<
			ApiResponse<getSubscriptionCountryMessageResponse200>,
			GenericWretchError<getSubscriptionCountryMessageResponse403>
		> => {
			const key = [
				"getSubscriptionCountryMessage",
				...(options?.queryKey || []),
			];
			const query: QueryFunction<
				ApiResponse<getSubscriptionCountryMessageResponse200>
			> = async ({ meta, queryKey, pageParam, signal }) => {
				return queryFn(signal);
			};
			const invalidate = () => queryClient.invalidateQueries(key);
			const queryResult = useQuery<
				ApiResponse<getSubscriptionCountryMessageResponse200>,
				GenericWretchError<getSubscriptionCountryMessageResponse403>
			>(key, query, options || {}) as UseQueryResult<
				ApiResponse<getSubscriptionCountryMessageResponse200>,
				GenericWretchError<getSubscriptionCountryMessageResponse403>
			>;
			queryResult.invalidate = invalidate;
			queryResult.key = key;
			return queryResult;
		},
		useInfiniteQuery: (
			options?: Omit<
				UseInfiniteQueryOptions<
					ApiResponse<getSubscriptionCountryMessageResponse200>,
					GenericWretchError<getSubscriptionCountryMessageResponse403>
				>,
				"queryFn"
			>,
		): UseInfiniteQueryResult<
			ApiResponse<getSubscriptionCountryMessageResponse200>,
			GenericWretchError<getSubscriptionCountryMessageResponse403>
		> => {
			const key = [
				"getSubscriptionCountryMessage",
				...(options?.queryKey || []),
			];
			const query: QueryFunction<
				ApiResponse<getSubscriptionCountryMessageResponse200>
			> = async ({ meta, queryKey, pageParam, signal }) => {
				return queryFn(signal);
			};
			const invalidate = () => queryClient.invalidateQueries(key);
			const queryResult = useInfiniteQuery<
				ApiResponse<getSubscriptionCountryMessageResponse200>,
				GenericWretchError<getSubscriptionCountryMessageResponse403>
			>(key, query, options || {}) as UseInfiniteQueryResult<
				ApiResponse<getSubscriptionCountryMessageResponse200>,
				GenericWretchError<getSubscriptionCountryMessageResponse403>
			>;
			queryResult.invalidate = invalidate;
			queryResult.key = key;
			return queryResult;
		},
	};
};

const removeCountriesSessionDetails = (wretch: Http) => {
	const mutation = async () => {
		try {
			const res = await wretch
				.url("/admin/api/user/removeCountrySession")
				.delete()
				.res();
			const json = await res.json();
			switch (res.status) {
				case 200: {
					const data = removeCountriesSessionDetailsResponse200.parse(json);
					return {
						status: res.status,
						data,
						headers: res.headers,
						url: res.url,
					};
				}
			}
		} catch (e) {
			const err = error(e);
			const json = err.json;
			switch (err.status) {
				case 403: {
					err.json = removeCountriesSessionDetailsResponse403.parse(json);
					break;
				}
			}
			throw err;
		}

		invariant(false);
	};

	return {
		mutation,
		useMutation: (
			options?: Omit<
				UseMutationOptions<
					ApiResponse<removeCountriesSessionDetailsResponse200> | undefined,
					GenericWretchError<removeCountriesSessionDetailsResponse403>,
					void
				>,
				"mutationFn"
			>,
		) => {
			return useMutation<
				ApiResponse<removeCountriesSessionDetailsResponse200> | undefined,
				GenericWretchError<removeCountriesSessionDetailsResponse403>,
				void
			>(mutation, options || {});
		},
	};
};

const searchNationalLeagues = (wretch: Http, queryClient: QueryClient) => {
	const queryFn = async (
		params: { category?: string; club_id?: number; type?: string },
		signal?: AbortSignal | undefined,
	): Promise<ApiResponse<searchNationalLeaguesResponse200>> => {
		const args = z
			.object({
				category: z.string().optional(),
				club_id: z.number().optional(),
				type: z.string().optional(),
			})
			.parse(params);
		const { category, club_id, type } = args;
		try {
			const res = await wretch
				.options({ signal })
				.url("/admin/api/searchNationalLeagues")
				.query(qs.stringify({ category, club_id, type }))
				.get()
				.res();
			const json = await res.json();
			switch (res.status) {
				case 200: {
					const data = searchNationalLeaguesResponse200.parse(json);
					return {
						status: res.status,
						data,
						headers: res.headers,
						url: res.url,
					};
				}
			}
		} catch (e) {
			const err = error(e);
			throw err;
		}

		invariant(false);
	};

	return {
		query: queryFn,
		useQuery: (
			params: { category?: string; club_id?: number; type?: string },
			options?: Omit<
				UseQueryOptions<
					ApiResponse<searchNationalLeaguesResponse200>,
					GenericWretchError<null>
				>,
				"queryFn"
			>,
		): UseQueryResult<
			ApiResponse<searchNationalLeaguesResponse200>,
			GenericWretchError<null>
		> => {
			const key = [
				"searchNationalLeagues",
				params,
				...(options?.queryKey || []),
			];
			const query: QueryFunction<
				ApiResponse<searchNationalLeaguesResponse200>
			> = async ({ meta, queryKey, pageParam, signal }) => {
				return queryFn(params, signal);
			};
			const invalidate = () => queryClient.invalidateQueries(key);
			const queryResult = useQuery<
				ApiResponse<searchNationalLeaguesResponse200>,
				GenericWretchError<null>
			>(key, query, options || {}) as UseQueryResult<
				ApiResponse<searchNationalLeaguesResponse200>,
				GenericWretchError<null>
			>;
			queryResult.invalidate = invalidate;
			queryResult.key = key;
			return queryResult;
		},
		useInfiniteQuery: (
			params: { category?: string; club_id?: number; type?: string },
			options?: Omit<
				UseInfiniteQueryOptions<
					ApiResponse<searchNationalLeaguesResponse200>,
					GenericWretchError<null>
				>,
				"queryFn"
			>,
		): UseInfiniteQueryResult<
			ApiResponse<searchNationalLeaguesResponse200>,
			GenericWretchError<null>
		> => {
			const key = [
				"searchNationalLeagues",
				params,
				...(options?.queryKey || []),
			];
			const query: QueryFunction<
				ApiResponse<searchNationalLeaguesResponse200>
			> = async ({ meta, queryKey, pageParam, signal }) => {
				return queryFn(params, signal);
			};
			const invalidate = () => queryClient.invalidateQueries(key);
			const queryResult = useInfiniteQuery<
				ApiResponse<searchNationalLeaguesResponse200>,
				GenericWretchError<null>
			>(key, query, options || {}) as UseInfiniteQueryResult<
				ApiResponse<searchNationalLeaguesResponse200>,
				GenericWretchError<null>
			>;
			queryResult.invalidate = invalidate;
			queryResult.key = key;
			return queryResult;
		},
	};
};

const getPermissionTree = (wretch: Http, queryClient: QueryClient) => {
	const queryFn = async (
		signal?: AbortSignal | undefined,
	): Promise<ApiResponse<getPermissionTreeResponse200>> => {
		try {
			const res = await wretch
				.options({ signal })
				.url("/admin/api/permissionTree")
				.get()
				.res();
			const json = await res.json();
			switch (res.status) {
				case 200: {
					const data = getPermissionTreeResponse200.parse(json);
					return {
						status: res.status,
						data,
						headers: res.headers,
						url: res.url,
					};
				}
			}
		} catch (e) {
			const err = error(e);
			const json = err.json;
			switch (err.status) {
				case 403: {
					err.json = getPermissionTreeResponse403.parse(json);
					break;
				}
			}
			throw err;
		}

		invariant(false);
	};

	return {
		query: queryFn,
		useQuery: (
			options?: Omit<
				UseQueryOptions<
					ApiResponse<getPermissionTreeResponse200>,
					GenericWretchError<getPermissionTreeResponse403>
				>,
				"queryFn"
			>,
		): UseQueryResult<
			ApiResponse<getPermissionTreeResponse200>,
			GenericWretchError<getPermissionTreeResponse403>
		> => {
			const key = ["getPermissionTree", ...(options?.queryKey || [])];
			const query: QueryFunction<ApiResponse<getPermissionTreeResponse200>> =
				async ({ meta, queryKey, pageParam, signal }) => {
					return queryFn(signal);
				};
			const invalidate = () => queryClient.invalidateQueries(key);
			const queryResult = useQuery<
				ApiResponse<getPermissionTreeResponse200>,
				GenericWretchError<getPermissionTreeResponse403>
			>(key, query, options || {}) as UseQueryResult<
				ApiResponse<getPermissionTreeResponse200>,
				GenericWretchError<getPermissionTreeResponse403>
			>;
			queryResult.invalidate = invalidate;
			queryResult.key = key;
			return queryResult;
		},
		useInfiniteQuery: (
			options?: Omit<
				UseInfiniteQueryOptions<
					ApiResponse<getPermissionTreeResponse200>,
					GenericWretchError<getPermissionTreeResponse403>
				>,
				"queryFn"
			>,
		): UseInfiniteQueryResult<
			ApiResponse<getPermissionTreeResponse200>,
			GenericWretchError<getPermissionTreeResponse403>
		> => {
			const key = ["getPermissionTree", ...(options?.queryKey || [])];
			const query: QueryFunction<ApiResponse<getPermissionTreeResponse200>> =
				async ({ meta, queryKey, pageParam, signal }) => {
					return queryFn(signal);
				};
			const invalidate = () => queryClient.invalidateQueries(key);
			const queryResult = useInfiniteQuery<
				ApiResponse<getPermissionTreeResponse200>,
				GenericWretchError<getPermissionTreeResponse403>
			>(key, query, options || {}) as UseInfiniteQueryResult<
				ApiResponse<getPermissionTreeResponse200>,
				GenericWretchError<getPermissionTreeResponse403>
			>;
			queryResult.invalidate = invalidate;
			queryResult.key = key;
			return queryResult;
		},
	};
};

const getPermissionByRole = (wretch: Http, queryClient: QueryClient) => {
	const queryFn = async (
		params: { roleId: number },
		signal?: AbortSignal | undefined,
	): Promise<ApiResponse<getPermissionByRoleResponse200>> => {
		const args = z.object({ roleId: z.number().int() }).parse(params);
		const { roleId } = args;
		try {
			const res = await wretch
				.options({ signal })
				.url(`/admin/api/permissionByRole/${roleId}`)
				.get()
				.res();
			const json = await res.json();
			switch (res.status) {
				case 200: {
					const data = getPermissionByRoleResponse200.parse(json);
					return {
						status: res.status,
						data,
						headers: res.headers,
						url: res.url,
					};
				}
			}
		} catch (e) {
			const err = error(e);
			const json = err.json;
			switch (err.status) {
				case 403: {
					err.json = getPermissionByRoleResponse403.parse(json);
					break;
				}
			}
			throw err;
		}

		invariant(false);
	};

	return {
		query: queryFn,
		useQuery: (
			params: { roleId: number },
			options?: Omit<
				UseQueryOptions<
					ApiResponse<getPermissionByRoleResponse200>,
					GenericWretchError<getPermissionByRoleResponse403>
				>,
				"queryFn"
			>,
		): UseQueryResult<
			ApiResponse<getPermissionByRoleResponse200>,
			GenericWretchError<getPermissionByRoleResponse403>
		> => {
			const key = ["getPermissionByRole", params, ...(options?.queryKey || [])];
			const query: QueryFunction<ApiResponse<getPermissionByRoleResponse200>> =
				async ({ meta, queryKey, pageParam, signal }) => {
					return queryFn(params, signal);
				};
			const invalidate = () => queryClient.invalidateQueries(key);
			const queryResult = useQuery<
				ApiResponse<getPermissionByRoleResponse200>,
				GenericWretchError<getPermissionByRoleResponse403>
			>(key, query, options || {}) as UseQueryResult<
				ApiResponse<getPermissionByRoleResponse200>,
				GenericWretchError<getPermissionByRoleResponse403>
			>;
			queryResult.invalidate = invalidate;
			queryResult.key = key;
			return queryResult;
		},
		useInfiniteQuery: (
			params: { roleId: number },
			options?: Omit<
				UseInfiniteQueryOptions<
					ApiResponse<getPermissionByRoleResponse200>,
					GenericWretchError<getPermissionByRoleResponse403>
				>,
				"queryFn"
			>,
		): UseInfiniteQueryResult<
			ApiResponse<getPermissionByRoleResponse200>,
			GenericWretchError<getPermissionByRoleResponse403>
		> => {
			const key = ["getPermissionByRole", params, ...(options?.queryKey || [])];
			const query: QueryFunction<ApiResponse<getPermissionByRoleResponse200>> =
				async ({ meta, queryKey, pageParam, signal }) => {
					return queryFn(params, signal);
				};
			const invalidate = () => queryClient.invalidateQueries(key);
			const queryResult = useInfiniteQuery<
				ApiResponse<getPermissionByRoleResponse200>,
				GenericWretchError<getPermissionByRoleResponse403>
			>(key, query, options || {}) as UseInfiniteQueryResult<
				ApiResponse<getPermissionByRoleResponse200>,
				GenericWretchError<getPermissionByRoleResponse403>
			>;
			queryResult.invalidate = invalidate;
			queryResult.key = key;
			return queryResult;
		},
	};
};

const assignPermissionToRole = (wretch: Http) => {
	const mutation = async (params: {
		roleId: number;
		body: assignPermissionToRole_Body;
	}) => {
		const args = z
			.object({ roleId: z.number().int(), body: z.any() })
			.parse(params);
		const { roleId, body } = args;

		try {
			const res = await wretch
				.url(`/admin/api/assignPermissionToRole/${roleId}`)
				.json(body)
				.post()
				.res();
			const json = await res.json();
			switch (res.status) {
				case 200: {
					const data = assignPermissionToRoleResponse200.parse(json);
					return {
						status: res.status,
						data,
						headers: res.headers,
						url: res.url,
					};
				}
			}
		} catch (e) {
			const err = error(e);
			const json = err.json;
			switch (err.status) {
				case 403: {
					err.json = assignPermissionToRoleResponse403.parse(json);
					break;
				}
			}
			throw err;
		}

		invariant(false);
	};

	return {
		mutation,
		useMutation: (
			options?: Omit<
				UseMutationOptions<
					ApiResponse<assignPermissionToRoleResponse200> | undefined,
					GenericWretchError<assignPermissionToRoleResponse403>,
					{ roleId: number; body: assignPermissionToRole_Body }
				>,
				"mutationFn"
			>,
		) => {
			return useMutation<
				ApiResponse<assignPermissionToRoleResponse200> | undefined,
				GenericWretchError<assignPermissionToRoleResponse403>,
				{ roleId: number; body: assignPermissionToRole_Body }
			>(mutation, options || {});
		},
	};
};

const deleteRole = (wretch: Http) => {
	const mutation = async (params: { roleId: number }) => {
		const args = z.object({ roleId: z.number().int() }).parse(params);
		const { roleId } = args;

		try {
			const res = await wretch.url(`/admin/api/role/${roleId}`).delete().res();
			const json = await res.json();
			switch (res.status) {
				case 200: {
					const data = deleteRoleResponse200.parse(json);
					return {
						status: res.status,
						data,
						headers: res.headers,
						url: res.url,
					};
				}
			}
		} catch (e) {
			const err = error(e);
			const json = err.json;
			switch (err.status) {
				case 403: {
					err.json = deleteRoleResponse403.parse(json);
					break;
				}
			}
			throw err;
		}

		invariant(false);
	};

	return {
		mutation,
		useMutation: (
			options?: Omit<
				UseMutationOptions<
					ApiResponse<deleteRoleResponse200> | undefined,
					GenericWretchError<deleteRoleResponse403>,
					{ roleId: number }
				>,
				"mutationFn"
			>,
		) => {
			return useMutation<
				ApiResponse<deleteRoleResponse200> | undefined,
				GenericWretchError<deleteRoleResponse403>,
				{ roleId: number }
			>(mutation, options || {});
		},
	};
};

const getRoles = (wretch: Http, queryClient: QueryClient) => {
	const queryFn = async (
		signal?: AbortSignal | undefined,
	): Promise<ApiResponse<getRolesResponse200>> => {
		try {
			const res = await wretch
				.options({ signal })
				.url("/admin/api/roles")
				.get()
				.res();
			const json = await res.json();
			switch (res.status) {
				case 200: {
					const data = getRolesResponse200.parse(json);
					return {
						status: res.status,
						data,
						headers: res.headers,
						url: res.url,
					};
				}
			}
		} catch (e) {
			const err = error(e);
			const json = err.json;
			switch (err.status) {
				case 403: {
					err.json = getRolesResponse403.parse(json);
					break;
				}
			}
			throw err;
		}

		invariant(false);
	};

	return {
		query: queryFn,
		useQuery: (
			options?: Omit<
				UseQueryOptions<
					ApiResponse<getRolesResponse200>,
					GenericWretchError<getRolesResponse403>
				>,
				"queryFn"
			>,
		): UseQueryResult<
			ApiResponse<getRolesResponse200>,
			GenericWretchError<getRolesResponse403>
		> => {
			const key = ["getRoles", ...(options?.queryKey || [])];
			const query: QueryFunction<ApiResponse<getRolesResponse200>> = async ({
				meta,
				queryKey,
				pageParam,
				signal,
			}) => {
				return queryFn(signal);
			};
			const invalidate = () => queryClient.invalidateQueries(key);
			const queryResult = useQuery<
				ApiResponse<getRolesResponse200>,
				GenericWretchError<getRolesResponse403>
			>(key, query, options || {}) as UseQueryResult<
				ApiResponse<getRolesResponse200>,
				GenericWretchError<getRolesResponse403>
			>;
			queryResult.invalidate = invalidate;
			queryResult.key = key;
			return queryResult;
		},
		useInfiniteQuery: (
			options?: Omit<
				UseInfiniteQueryOptions<
					ApiResponse<getRolesResponse200>,
					GenericWretchError<getRolesResponse403>
				>,
				"queryFn"
			>,
		): UseInfiniteQueryResult<
			ApiResponse<getRolesResponse200>,
			GenericWretchError<getRolesResponse403>
		> => {
			const key = ["getRoles", ...(options?.queryKey || [])];
			const query: QueryFunction<ApiResponse<getRolesResponse200>> = async ({
				meta,
				queryKey,
				pageParam,
				signal,
			}) => {
				return queryFn(signal);
			};
			const invalidate = () => queryClient.invalidateQueries(key);
			const queryResult = useInfiniteQuery<
				ApiResponse<getRolesResponse200>,
				GenericWretchError<getRolesResponse403>
			>(key, query, options || {}) as UseInfiniteQueryResult<
				ApiResponse<getRolesResponse200>,
				GenericWretchError<getRolesResponse403>
			>;
			queryResult.invalidate = invalidate;
			queryResult.key = key;
			return queryResult;
		},
	};
};

const getUserRoles = (wretch: Http, queryClient: QueryClient) => {
	const queryFn = async (
		signal?: AbortSignal | undefined,
	): Promise<ApiResponse<getUserRolesResponse200>> => {
		try {
			const res = await wretch
				.options({ signal })
				.url("/admin/api/user/roles")
				.get()
				.res();
			const json = await res.json();
			switch (res.status) {
				case 200: {
					const data = getUserRolesResponse200.parse(json);
					return {
						status: res.status,
						data,
						headers: res.headers,
						url: res.url,
					};
				}
			}
		} catch (e) {
			const err = error(e);
			const json = err.json;
			switch (err.status) {
				case 403: {
					err.json = getUserRolesResponse403.parse(json);
					break;
				}
			}
			throw err;
		}

		invariant(false);
	};

	return {
		query: queryFn,
		useQuery: (
			options?: Omit<
				UseQueryOptions<
					ApiResponse<getUserRolesResponse200>,
					GenericWretchError<getUserRolesResponse403>
				>,
				"queryFn"
			>,
		): UseQueryResult<
			ApiResponse<getUserRolesResponse200>,
			GenericWretchError<getUserRolesResponse403>
		> => {
			const key = ["getUserRoles", ...(options?.queryKey || [])];
			const query: QueryFunction<ApiResponse<getUserRolesResponse200>> =
				async ({ meta, queryKey, pageParam, signal }) => {
					return queryFn(signal);
				};
			const invalidate = () => queryClient.invalidateQueries(key);
			const queryResult = useQuery<
				ApiResponse<getUserRolesResponse200>,
				GenericWretchError<getUserRolesResponse403>
			>(key, query, options || {}) as UseQueryResult<
				ApiResponse<getUserRolesResponse200>,
				GenericWretchError<getUserRolesResponse403>
			>;
			queryResult.invalidate = invalidate;
			queryResult.key = key;
			return queryResult;
		},
		useInfiniteQuery: (
			options?: Omit<
				UseInfiniteQueryOptions<
					ApiResponse<getUserRolesResponse200>,
					GenericWretchError<getUserRolesResponse403>
				>,
				"queryFn"
			>,
		): UseInfiniteQueryResult<
			ApiResponse<getUserRolesResponse200>,
			GenericWretchError<getUserRolesResponse403>
		> => {
			const key = ["getUserRoles", ...(options?.queryKey || [])];
			const query: QueryFunction<ApiResponse<getUserRolesResponse200>> =
				async ({ meta, queryKey, pageParam, signal }) => {
					return queryFn(signal);
				};
			const invalidate = () => queryClient.invalidateQueries(key);
			const queryResult = useInfiniteQuery<
				ApiResponse<getUserRolesResponse200>,
				GenericWretchError<getUserRolesResponse403>
			>(key, query, options || {}) as UseInfiniteQueryResult<
				ApiResponse<getUserRolesResponse200>,
				GenericWretchError<getUserRolesResponse403>
			>;
			queryResult.invalidate = invalidate;
			queryResult.key = key;
			return queryResult;
		},
	};
};

const addUpdateRole = (wretch: Http) => {
	const mutation = async (params: { body: addUpdateRole_Body }) => {
		const args = z.object({ body: z.any() }).parse(params);
		const { body } = args;

		try {
			const res = await wretch
				.url("/admin/api/addUpdateRole")
				.json(body)
				.post()
				.res();
			const json = await res.json();
			switch (res.status) {
				case 200: {
					const data = addUpdateRoleResponse200.parse(json);
					return {
						status: res.status,
						data,
						headers: res.headers,
						url: res.url,
					};
				}
			}
		} catch (e) {
			const err = error(e);
			const json = err.json;
			switch (err.status) {
				case 403: {
					err.json = addUpdateRoleResponse403.parse(json);
					break;
				}
			}
			throw err;
		}

		invariant(false);
	};

	return {
		mutation,
		useMutation: (
			options?: Omit<
				UseMutationOptions<
					ApiResponse<addUpdateRoleResponse200> | undefined,
					GenericWretchError<addUpdateRoleResponse403>,
					{ body: addUpdateRole_Body }
				>,
				"mutationFn"
			>,
		) => {
			return useMutation<
				ApiResponse<addUpdateRoleResponse200> | undefined,
				GenericWretchError<addUpdateRoleResponse403>,
				{ body: addUpdateRole_Body }
			>(mutation, options || {});
		},
	};
};

const getAllAdminUsers = (wretch: Http, queryClient: QueryClient) => {
	const queryFn = async (
		params: { start: number; length: number; search?: string },
		signal?: AbortSignal | undefined,
	): Promise<ApiResponse<getAllAdminUsersResponse200>> => {
		const args = z
			.object({
				start: z.number().int(),
				length: z.number().int(),
				search: z.string().optional(),
			})
			.parse(params);
		const { start, length, search } = args;
		try {
			const res = await wretch
				.options({ signal })
				.url("/admin/api/users")
				.query(qs.stringify({ start, length, search }))
				.get()
				.res();
			const json = await res.json();
			switch (res.status) {
				case 200: {
					const data = getAllAdminUsersResponse200.parse(json);
					return {
						status: res.status,
						data,
						headers: res.headers,
						url: res.url,
					};
				}
			}
		} catch (e) {
			const err = error(e);
			const json = err.json;
			switch (err.status) {
				case 403: {
					err.json = getAllAdminUsersResponse403.parse(json);
					break;
				}
			}
			throw err;
		}

		invariant(false);
	};

	return {
		query: queryFn,
		useQuery: (
			params: { start: number; length: number; search?: string },
			options?: Omit<
				UseQueryOptions<
					ApiResponse<getAllAdminUsersResponse200>,
					GenericWretchError<getAllAdminUsersResponse403>
				>,
				"queryFn"
			>,
		): UseQueryResult<
			ApiResponse<getAllAdminUsersResponse200>,
			GenericWretchError<getAllAdminUsersResponse403>
		> => {
			const key = ["getAllAdminUsers", params, ...(options?.queryKey || [])];
			const query: QueryFunction<ApiResponse<getAllAdminUsersResponse200>> =
				async ({ meta, queryKey, pageParam, signal }) => {
					return queryFn(params, signal);
				};
			const invalidate = () => queryClient.invalidateQueries(key);
			const queryResult = useQuery<
				ApiResponse<getAllAdminUsersResponse200>,
				GenericWretchError<getAllAdminUsersResponse403>
			>(key, query, options || {}) as UseQueryResult<
				ApiResponse<getAllAdminUsersResponse200>,
				GenericWretchError<getAllAdminUsersResponse403>
			>;
			queryResult.invalidate = invalidate;
			queryResult.key = key;
			return queryResult;
		},
		useInfiniteQuery: (
			params: { start: number; length: number; search?: string },
			options?: Omit<
				UseInfiniteQueryOptions<
					ApiResponse<getAllAdminUsersResponse200>,
					GenericWretchError<getAllAdminUsersResponse403>
				>,
				"queryFn"
			>,
		): UseInfiniteQueryResult<
			ApiResponse<getAllAdminUsersResponse200>,
			GenericWretchError<getAllAdminUsersResponse403>
		> => {
			const key = ["getAllAdminUsers", params, ...(options?.queryKey || [])];
			const query: QueryFunction<ApiResponse<getAllAdminUsersResponse200>> =
				async ({ meta, queryKey, pageParam, signal }) => {
					return queryFn(params, signal);
				};
			const invalidate = () => queryClient.invalidateQueries(key);
			const queryResult = useInfiniteQuery<
				ApiResponse<getAllAdminUsersResponse200>,
				GenericWretchError<getAllAdminUsersResponse403>
			>(key, query, options || {}) as UseInfiniteQueryResult<
				ApiResponse<getAllAdminUsersResponse200>,
				GenericWretchError<getAllAdminUsersResponse403>
			>;
			queryResult.invalidate = invalidate;
			queryResult.key = key;
			return queryResult;
		},
	};
};

const addUpdateAdminUsers = (wretch: Http) => {
	const mutation = async (params: { body: addUpdateAdminUsers_Body }) => {
		const args = z.object({ body: z.any() }).parse(params);
		const { body } = args;

		try {
			const res = await wretch
				.url("/admin/api/addUpdateAdminUser")
				.json(body)
				.post()
				.res();
			const json = await res.json();
			switch (res.status) {
				case 200: {
					const data = addUpdateAdminUsersResponse200.parse(json);
					return {
						status: res.status,
						data,
						headers: res.headers,
						url: res.url,
					};
				}
			}
		} catch (e) {
			const err = error(e);
			const json = err.json;
			switch (err.status) {
				case 403: {
					err.json = addUpdateAdminUsersResponse403.parse(json);
					break;
				}
			}
			throw err;
		}

		invariant(false);
	};

	return {
		mutation,
		useMutation: (
			options?: Omit<
				UseMutationOptions<
					ApiResponse<addUpdateAdminUsersResponse200> | undefined,
					GenericWretchError<addUpdateAdminUsersResponse403>,
					{ body: addUpdateAdminUsers_Body }
				>,
				"mutationFn"
			>,
		) => {
			return useMutation<
				ApiResponse<addUpdateAdminUsersResponse200> | undefined,
				GenericWretchError<addUpdateAdminUsersResponse403>,
				{ body: addUpdateAdminUsers_Body }
			>(mutation, options || {});
		},
	};
};

const deleteAdminUser = (wretch: Http) => {
	const mutation = async (params: { id: number }) => {
		const args = z.object({ id: z.number().int() }).parse(params);
		const { id } = args;

		try {
			const res = await wretch.url(`/admin/api/user/${id}`).delete().res();
			const json = await res.json();
			switch (res.status) {
				case 200: {
					const data = deleteAdminUserResponse200.parse(json);
					return {
						status: res.status,
						data,
						headers: res.headers,
						url: res.url,
					};
				}
			}
		} catch (e) {
			const err = error(e);
			const json = err.json;
			switch (err.status) {
				case 400: {
					err.json = deleteAdminUserResponse400.parse(json);
					break;
				}
				case 403: {
					err.json = deleteAdminUserResponse403.parse(json);
					break;
				}
			}
			throw err;
		}

		invariant(false);
	};

	return {
		mutation,
		useMutation: (
			options?: Omit<
				UseMutationOptions<
					ApiResponse<deleteAdminUserResponse200> | undefined,
					GenericWretchError<
						deleteAdminUserResponse400 | deleteAdminUserResponse403
					>,
					{ id: number }
				>,
				"mutationFn"
			>,
		) => {
			return useMutation<
				ApiResponse<deleteAdminUserResponse200> | undefined,
				GenericWretchError<
					deleteAdminUserResponse400 | deleteAdminUserResponse403
				>,
				{ id: number }
			>(mutation, options || {});
		},
	};
};

const uploadProfilePhoto = (wretch: Http) => {
	const mutation = async (params: { body: uploadProfilePhoto_Body }) => {
		const args = z.object({ body: z.any() }).parse(params);
		const { body } = args;

		try {
			const res = await wretch
				.url("/admin/api/user/upload-photo")
				.json(body)
				.post()
				.res();
			const json = await res.json();
			switch (res.status) {
				case 200: {
					const data = uploadProfilePhotoResponse200.parse(json);
					return {
						status: res.status,
						data,
						headers: res.headers,
						url: res.url,
					};
				}
			}
		} catch (e) {
			const err = error(e);
			const json = err.json;
			switch (err.status) {
				case 403: {
					err.json = uploadProfilePhotoResponse403.parse(json);
					break;
				}
			}
			throw err;
		}

		invariant(false);
	};

	return {
		mutation,
		useMutation: (
			options?: Omit<
				UseMutationOptions<
					ApiResponse<uploadProfilePhotoResponse200> | undefined,
					GenericWretchError<uploadProfilePhotoResponse403>,
					{ body: uploadProfilePhoto_Body }
				>,
				"mutationFn"
			>,
		) => {
			return useMutation<
				ApiResponse<uploadProfilePhotoResponse200> | undefined,
				GenericWretchError<uploadProfilePhotoResponse403>,
				{ body: uploadProfilePhoto_Body }
			>(mutation, options || {});
		},
	};
};

const getAllPoles = (wretch: Http, queryClient: QueryClient) => {
	const queryFn = async (
		params: { start: number; length: number; search?: string },
		signal?: AbortSignal | undefined,
	): Promise<ApiResponse<getAllPolesResponse200>> => {
		const args = z
			.object({
				start: z.number().int(),
				length: z.number().int(),
				search: z.string().optional(),
			})
			.parse(params);
		const { start, length, search } = args;
		try {
			const res = await wretch
				.options({ signal })
				.url("/admin/api/poles")
				.query(qs.stringify({ start, length, search }))
				.get()
				.res();
			const json = await res.json();
			switch (res.status) {
				case 200: {
					const data = getAllPolesResponse200.parse(json);
					return {
						status: res.status,
						data,
						headers: res.headers,
						url: res.url,
					};
				}
			}
		} catch (e) {
			const err = error(e);
			const json = err.json;
			switch (err.status) {
				case 403: {
					err.json = getAllPolesResponse403.parse(json);
					break;
				}
			}
			throw err;
		}

		invariant(false);
	};

	return {
		query: queryFn,
		useQuery: (
			params: { start: number; length: number; search?: string },
			options?: Omit<
				UseQueryOptions<
					ApiResponse<getAllPolesResponse200>,
					GenericWretchError<getAllPolesResponse403>
				>,
				"queryFn"
			>,
		): UseQueryResult<
			ApiResponse<getAllPolesResponse200>,
			GenericWretchError<getAllPolesResponse403>
		> => {
			const key = ["getAllPoles", params, ...(options?.queryKey || [])];
			const query: QueryFunction<ApiResponse<getAllPolesResponse200>> = async ({
				meta,
				queryKey,
				pageParam,
				signal,
			}) => {
				return queryFn(params, signal);
			};
			const invalidate = () => queryClient.invalidateQueries(key);
			const queryResult = useQuery<
				ApiResponse<getAllPolesResponse200>,
				GenericWretchError<getAllPolesResponse403>
			>(key, query, options || {}) as UseQueryResult<
				ApiResponse<getAllPolesResponse200>,
				GenericWretchError<getAllPolesResponse403>
			>;
			queryResult.invalidate = invalidate;
			queryResult.key = key;
			return queryResult;
		},
		useInfiniteQuery: (
			params: { start: number; length: number; search?: string },
			options?: Omit<
				UseInfiniteQueryOptions<
					ApiResponse<getAllPolesResponse200>,
					GenericWretchError<getAllPolesResponse403>
				>,
				"queryFn"
			>,
		): UseInfiniteQueryResult<
			ApiResponse<getAllPolesResponse200>,
			GenericWretchError<getAllPolesResponse403>
		> => {
			const key = ["getAllPoles", params, ...(options?.queryKey || [])];
			const query: QueryFunction<ApiResponse<getAllPolesResponse200>> = async ({
				meta,
				queryKey,
				pageParam,
				signal,
			}) => {
				return queryFn(params, signal);
			};
			const invalidate = () => queryClient.invalidateQueries(key);
			const queryResult = useInfiniteQuery<
				ApiResponse<getAllPolesResponse200>,
				GenericWretchError<getAllPolesResponse403>
			>(key, query, options || {}) as UseInfiniteQueryResult<
				ApiResponse<getAllPolesResponse200>,
				GenericWretchError<getAllPolesResponse403>
			>;
			queryResult.invalidate = invalidate;
			queryResult.key = key;
			return queryResult;
		},
	};
};

const addUpdatePole = (wretch: Http) => {
	const mutation = async (params: { body: addUpdatePole_Body }) => {
		const args = z.object({ body: z.any() }).parse(params);
		const { body } = args;

		try {
			const res = await wretch
				.url("/admin/api/addUpdatePole")
				.json(body)
				.post()
				.res();
			const json = await res.json();
			switch (res.status) {
				case 200: {
					const data = addUpdatePoleResponse200.parse(json);
					return {
						status: res.status,
						data,
						headers: res.headers,
						url: res.url,
					};
				}
			}
		} catch (e) {
			const err = error(e);
			const json = err.json;
			switch (err.status) {
				case 403: {
					err.json = addUpdatePoleResponse403.parse(json);
					break;
				}
			}
			throw err;
		}

		invariant(false);
	};

	return {
		mutation,
		useMutation: (
			options?: Omit<
				UseMutationOptions<
					ApiResponse<addUpdatePoleResponse200> | undefined,
					GenericWretchError<addUpdatePoleResponse403>,
					{ body: addUpdatePole_Body }
				>,
				"mutationFn"
			>,
		) => {
			return useMutation<
				ApiResponse<addUpdatePoleResponse200> | undefined,
				GenericWretchError<addUpdatePoleResponse403>,
				{ body: addUpdatePole_Body }
			>(mutation, options || {});
		},
	};
};

const getTimezones = (wretch: Http, queryClient: QueryClient) => {
	const queryFn = async (
		signal?: AbortSignal | undefined,
	): Promise<ApiResponse<getTimezonesResponse200>> => {
		try {
			const res = await wretch
				.options({ signal })
				.url("/admin/api/getTimezones")
				.get()
				.res();
			const json = await res.json();
			switch (res.status) {
				case 200: {
					const data = getTimezonesResponse200.parse(json);
					return {
						status: res.status,
						data,
						headers: res.headers,
						url: res.url,
					};
				}
			}
		} catch (e) {
			const err = error(e);
			throw err;
		}

		invariant(false);
	};

	return {
		query: queryFn,
		useQuery: (
			options?: Omit<
				UseQueryOptions<
					ApiResponse<getTimezonesResponse200>,
					GenericWretchError<null>
				>,
				"queryFn"
			>,
		): UseQueryResult<
			ApiResponse<getTimezonesResponse200>,
			GenericWretchError<null>
		> => {
			const key = ["getTimezones", ...(options?.queryKey || [])];
			const query: QueryFunction<ApiResponse<getTimezonesResponse200>> =
				async ({ meta, queryKey, pageParam, signal }) => {
					return queryFn(signal);
				};
			const invalidate = () => queryClient.invalidateQueries(key);
			const queryResult = useQuery<
				ApiResponse<getTimezonesResponse200>,
				GenericWretchError<null>
			>(key, query, options || {}) as UseQueryResult<
				ApiResponse<getTimezonesResponse200>,
				GenericWretchError<null>
			>;
			queryResult.invalidate = invalidate;
			queryResult.key = key;
			return queryResult;
		},
		useInfiniteQuery: (
			options?: Omit<
				UseInfiniteQueryOptions<
					ApiResponse<getTimezonesResponse200>,
					GenericWretchError<null>
				>,
				"queryFn"
			>,
		): UseInfiniteQueryResult<
			ApiResponse<getTimezonesResponse200>,
			GenericWretchError<null>
		> => {
			const key = ["getTimezones", ...(options?.queryKey || [])];
			const query: QueryFunction<ApiResponse<getTimezonesResponse200>> =
				async ({ meta, queryKey, pageParam, signal }) => {
					return queryFn(signal);
				};
			const invalidate = () => queryClient.invalidateQueries(key);
			const queryResult = useInfiniteQuery<
				ApiResponse<getTimezonesResponse200>,
				GenericWretchError<null>
			>(key, query, options || {}) as UseInfiniteQueryResult<
				ApiResponse<getTimezonesResponse200>,
				GenericWretchError<null>
			>;
			queryResult.invalidate = invalidate;
			queryResult.key = key;
			return queryResult;
		},
	};
};

const deletePole = (wretch: Http) => {
	const mutation = async (params: { id: number }) => {
		const args = z.object({ id: z.number().int() }).parse(params);
		const { id } = args;

		try {
			const res = await wretch.url(`/admin/api/pole/${id}`).delete().res();
			const json = await res.json();
			switch (res.status) {
				case 200: {
					const data = deletePoleResponse200.parse(json);
					return {
						status: res.status,
						data,
						headers: res.headers,
						url: res.url,
					};
				}
			}
		} catch (e) {
			const err = error(e);
			const json = err.json;
			switch (err.status) {
				case 400: {
					err.json = deletePoleResponse400.parse(json);
					break;
				}
				case 403: {
					err.json = deletePoleResponse403.parse(json);
					break;
				}
			}
			throw err;
		}

		invariant(false);
	};

	return {
		mutation,
		useMutation: (
			options?: Omit<
				UseMutationOptions<
					ApiResponse<deletePoleResponse200> | undefined,
					GenericWretchError<deletePoleResponse400 | deletePoleResponse403>,
					{ id: number }
				>,
				"mutationFn"
			>,
		) => {
			return useMutation<
				ApiResponse<deletePoleResponse200> | undefined,
				GenericWretchError<deletePoleResponse400 | deletePoleResponse403>,
				{ id: number }
			>(mutation, options || {});
		},
	};
};

const getSelectedPoleByClub = (wretch: Http, queryClient: QueryClient) => {
	const queryFn = async (
		params: { clubId: number },
		signal?: AbortSignal | undefined,
	): Promise<ApiResponse<getSelectedPoleByClubResponse200>> => {
		const args = z.object({ clubId: z.number().int() }).parse(params);
		const { clubId } = args;
		try {
			const res = await wretch
				.options({ signal })
				.url(`/admin/api/pole/club/${clubId}`)
				.get()
				.res();
			const json = await res.json();
			switch (res.status) {
				case 200: {
					const data = getSelectedPoleByClubResponse200.parse(json);
					return {
						status: res.status,
						data,
						headers: res.headers,
						url: res.url,
					};
				}
			}
		} catch (e) {
			const err = error(e);
			const json = err.json;
			switch (err.status) {
				case 400: {
					err.json = getSelectedPoleByClubResponse400.parse(json);
					break;
				}
			}
			throw err;
		}

		invariant(false);
	};

	return {
		query: queryFn,
		useQuery: (
			params: { clubId: number },
			options?: Omit<
				UseQueryOptions<
					ApiResponse<getSelectedPoleByClubResponse200>,
					GenericWretchError<getSelectedPoleByClubResponse400>
				>,
				"queryFn"
			>,
		): UseQueryResult<
			ApiResponse<getSelectedPoleByClubResponse200>,
			GenericWretchError<getSelectedPoleByClubResponse400>
		> => {
			const key = [
				"getSelectedPoleByClub",
				params,
				...(options?.queryKey || []),
			];
			const query: QueryFunction<
				ApiResponse<getSelectedPoleByClubResponse200>
			> = async ({ meta, queryKey, pageParam, signal }) => {
				return queryFn(params, signal);
			};
			const invalidate = () => queryClient.invalidateQueries(key);
			const queryResult = useQuery<
				ApiResponse<getSelectedPoleByClubResponse200>,
				GenericWretchError<getSelectedPoleByClubResponse400>
			>(key, query, options || {}) as UseQueryResult<
				ApiResponse<getSelectedPoleByClubResponse200>,
				GenericWretchError<getSelectedPoleByClubResponse400>
			>;
			queryResult.invalidate = invalidate;
			queryResult.key = key;
			return queryResult;
		},
		useInfiniteQuery: (
			params: { clubId: number },
			options?: Omit<
				UseInfiniteQueryOptions<
					ApiResponse<getSelectedPoleByClubResponse200>,
					GenericWretchError<getSelectedPoleByClubResponse400>
				>,
				"queryFn"
			>,
		): UseInfiniteQueryResult<
			ApiResponse<getSelectedPoleByClubResponse200>,
			GenericWretchError<getSelectedPoleByClubResponse400>
		> => {
			const key = [
				"getSelectedPoleByClub",
				params,
				...(options?.queryKey || []),
			];
			const query: QueryFunction<
				ApiResponse<getSelectedPoleByClubResponse200>
			> = async ({ meta, queryKey, pageParam, signal }) => {
				return queryFn(params, signal);
			};
			const invalidate = () => queryClient.invalidateQueries(key);
			const queryResult = useInfiniteQuery<
				ApiResponse<getSelectedPoleByClubResponse200>,
				GenericWretchError<getSelectedPoleByClubResponse400>
			>(key, query, options || {}) as UseInfiniteQueryResult<
				ApiResponse<getSelectedPoleByClubResponse200>,
				GenericWretchError<getSelectedPoleByClubResponse400>
			>;
			queryResult.invalidate = invalidate;
			queryResult.key = key;
			return queryResult;
		},
	};
};

const assignPole = (wretch: Http) => {
	const mutation = async (params: { id: number; body: assignPole_Body }) => {
		const args = z
			.object({ id: z.number().int(), body: z.any() })
			.parse(params);
		const { id, body } = args;

		try {
			const res = await wretch
				.url(`/admin/api/pole/${id}/assign`)
				.json(body)
				.put()
				.res();
			const json = await res.json();
			switch (res.status) {
				case 200: {
					const data = assignPoleResponse200.parse(json);
					return {
						status: res.status,
						data,
						headers: res.headers,
						url: res.url,
					};
				}
			}
		} catch (e) {
			const err = error(e);
			const json = err.json;
			switch (err.status) {
				case 403: {
					err.json = assignPoleResponse403.parse(json);
					break;
				}
			}
			throw err;
		}

		invariant(false);
	};

	return {
		mutation,
		useMutation: (
			options?: Omit<
				UseMutationOptions<
					ApiResponse<assignPoleResponse200> | undefined,
					GenericWretchError<assignPoleResponse403>,
					{ id: number; body: assignPole_Body }
				>,
				"mutationFn"
			>,
		) => {
			return useMutation<
				ApiResponse<assignPoleResponse200> | undefined,
				GenericWretchError<assignPoleResponse403>,
				{ id: number; body: assignPole_Body }
			>(mutation, options || {});
		},
	};
};

const unAssignPole = (wretch: Http) => {
	const mutation = async (params: { id: number; clubId: number }) => {
		const args = z
			.object({ id: z.number().int(), clubId: z.number().int() })
			.parse(params);
		const { id, clubId } = args;

		try {
			const res = await wretch
				.url(`/admin/api/pole/${id}/un-assign/${clubId}`)
				.put()
				.res();
			const json = await res.json();
			switch (res.status) {
				case 200: {
					const data = unAssignPoleResponse200.parse(json);
					return {
						status: res.status,
						data,
						headers: res.headers,
						url: res.url,
					};
				}
			}
		} catch (e) {
			const err = error(e);
			const json = err.json;
			switch (err.status) {
				case 403: {
					err.json = unAssignPoleResponse403.parse(json);
					break;
				}
			}
			throw err;
		}

		invariant(false);
	};

	return {
		mutation,
		useMutation: (
			options?: Omit<
				UseMutationOptions<
					ApiResponse<unAssignPoleResponse200> | undefined,
					GenericWretchError<unAssignPoleResponse403>,
					{ id: number; clubId: number }
				>,
				"mutationFn"
			>,
		) => {
			return useMutation<
				ApiResponse<unAssignPoleResponse200> | undefined,
				GenericWretchError<unAssignPoleResponse403>,
				{ id: number; clubId: number }
			>(mutation, options || {});
		},
	};
};

const syncPole = (wretch: Http) => {
	const mutation = async (params: { id: number }) => {
		const args = z.object({ id: z.number().int() }).parse(params);
		const { id } = args;

		try {
			const res = await wretch.url(`/admin/api/pole/${id}/sync`).put().res();
			const json = await res.json();
			switch (res.status) {
				case 200: {
					const data = syncPoleResponse200.parse(json);
					return {
						status: res.status,
						data,
						headers: res.headers,
						url: res.url,
					};
				}
			}
		} catch (e) {
			const err = error(e);
			const json = err.json;
			switch (err.status) {
				case 400: {
					err.json = syncPoleResponse400.parse(json);
					break;
				}
			}
			throw err;
		}

		invariant(false);
	};

	return {
		mutation,
		useMutation: (
			options?: Omit<
				UseMutationOptions<
					ApiResponse<syncPoleResponse200> | undefined,
					GenericWretchError<syncPoleResponse400>,
					{ id: number }
				>,
				"mutationFn"
			>,
		) => {
			return useMutation<
				ApiResponse<syncPoleResponse200> | undefined,
				GenericWretchError<syncPoleResponse400>,
				{ id: number }
			>(mutation, options || {});
		},
	};
};

const getClubList = (wretch: Http, queryClient: QueryClient) => {
	const queryFn = async (
		signal?: AbortSignal | undefined,
	): Promise<ApiResponse<getClubListResponse200>> => {
		try {
			const res = await wretch
				.options({ signal })
				.url("/admin/api/club")
				.get()
				.res();
			const json = await res.json();
			switch (res.status) {
				case 200: {
					const data = getClubListResponse200.parse(json);
					return {
						status: res.status,
						data,
						headers: res.headers,
						url: res.url,
					};
				}
			}
		} catch (e) {
			const err = error(e);
			const json = err.json;
			switch (err.status) {
				case 403: {
					err.json = getClubListResponse403.parse(json);
					break;
				}
			}
			throw err;
		}

		invariant(false);
	};

	return {
		query: queryFn,
		useQuery: (
			options?: Omit<
				UseQueryOptions<
					ApiResponse<getClubListResponse200>,
					GenericWretchError<getClubListResponse403>
				>,
				"queryFn"
			>,
		): UseQueryResult<
			ApiResponse<getClubListResponse200>,
			GenericWretchError<getClubListResponse403>
		> => {
			const key = ["getClubList", ...(options?.queryKey || [])];
			const query: QueryFunction<ApiResponse<getClubListResponse200>> = async ({
				meta,
				queryKey,
				pageParam,
				signal,
			}) => {
				return queryFn(signal);
			};
			const invalidate = () => queryClient.invalidateQueries(key);
			const queryResult = useQuery<
				ApiResponse<getClubListResponse200>,
				GenericWretchError<getClubListResponse403>
			>(key, query, options || {}) as UseQueryResult<
				ApiResponse<getClubListResponse200>,
				GenericWretchError<getClubListResponse403>
			>;
			queryResult.invalidate = invalidate;
			queryResult.key = key;
			return queryResult;
		},
		useInfiniteQuery: (
			options?: Omit<
				UseInfiniteQueryOptions<
					ApiResponse<getClubListResponse200>,
					GenericWretchError<getClubListResponse403>
				>,
				"queryFn"
			>,
		): UseInfiniteQueryResult<
			ApiResponse<getClubListResponse200>,
			GenericWretchError<getClubListResponse403>
		> => {
			const key = ["getClubList", ...(options?.queryKey || [])];
			const query: QueryFunction<ApiResponse<getClubListResponse200>> = async ({
				meta,
				queryKey,
				pageParam,
				signal,
			}) => {
				return queryFn(signal);
			};
			const invalidate = () => queryClient.invalidateQueries(key);
			const queryResult = useInfiniteQuery<
				ApiResponse<getClubListResponse200>,
				GenericWretchError<getClubListResponse403>
			>(key, query, options || {}) as UseInfiniteQueryResult<
				ApiResponse<getClubListResponse200>,
				GenericWretchError<getClubListResponse403>
			>;
			queryResult.invalidate = invalidate;
			queryResult.key = key;
			return queryResult;
		},
	};
};

const getCountries = (wretch: Http, queryClient: QueryClient) => {
	const queryFn = async (
		signal?: AbortSignal | undefined,
	): Promise<ApiResponse<getCountriesResponse200>> => {
		try {
			const res = await wretch
				.options({ signal })
				.url("/admin/api/nonPartnerClub/countries")
				.get()
				.res();
			const json = await res.json();
			switch (res.status) {
				case 200: {
					const data = getCountriesResponse200.parse(json);
					return {
						status: res.status,
						data,
						headers: res.headers,
						url: res.url,
					};
				}
			}
		} catch (e) {
			const err = error(e);
			const json = err.json;
			switch (err.status) {
				case 403: {
					err.json = getCountriesResponse403.parse(json);
					break;
				}
			}
			throw err;
		}

		invariant(false);
	};

	return {
		query: queryFn,
		useQuery: (
			options?: Omit<
				UseQueryOptions<
					ApiResponse<getCountriesResponse200>,
					GenericWretchError<getCountriesResponse403>
				>,
				"queryFn"
			>,
		): UseQueryResult<
			ApiResponse<getCountriesResponse200>,
			GenericWretchError<getCountriesResponse403>
		> => {
			const key = ["getCountries", ...(options?.queryKey || [])];
			const query: QueryFunction<ApiResponse<getCountriesResponse200>> =
				async ({ meta, queryKey, pageParam, signal }) => {
					return queryFn(signal);
				};
			const invalidate = () => queryClient.invalidateQueries(key);
			const queryResult = useQuery<
				ApiResponse<getCountriesResponse200>,
				GenericWretchError<getCountriesResponse403>
			>(key, query, options || {}) as UseQueryResult<
				ApiResponse<getCountriesResponse200>,
				GenericWretchError<getCountriesResponse403>
			>;
			queryResult.invalidate = invalidate;
			queryResult.key = key;
			return queryResult;
		},
		useInfiniteQuery: (
			options?: Omit<
				UseInfiniteQueryOptions<
					ApiResponse<getCountriesResponse200>,
					GenericWretchError<getCountriesResponse403>
				>,
				"queryFn"
			>,
		): UseInfiniteQueryResult<
			ApiResponse<getCountriesResponse200>,
			GenericWretchError<getCountriesResponse403>
		> => {
			const key = ["getCountries", ...(options?.queryKey || [])];
			const query: QueryFunction<ApiResponse<getCountriesResponse200>> =
				async ({ meta, queryKey, pageParam, signal }) => {
					return queryFn(signal);
				};
			const invalidate = () => queryClient.invalidateQueries(key);
			const queryResult = useInfiniteQuery<
				ApiResponse<getCountriesResponse200>,
				GenericWretchError<getCountriesResponse403>
			>(key, query, options || {}) as UseInfiniteQueryResult<
				ApiResponse<getCountriesResponse200>,
				GenericWretchError<getCountriesResponse403>
			>;
			queryResult.invalidate = invalidate;
			queryResult.key = key;
			return queryResult;
		},
	};
};

const getContinentCountry = (wretch: Http, queryClient: QueryClient) => {
	const queryFn = async (
		signal?: AbortSignal | undefined,
	): Promise<ApiResponse<getContinentCountryResponse200>> => {
		try {
			const res = await wretch
				.options({ signal })
				.url("/admin/api/getContinentCountry")
				.get()
				.res();
			const json = await res.json();
			switch (res.status) {
				case 200: {
					const data = getContinentCountryResponse200.parse(json);
					return {
						status: res.status,
						data,
						headers: res.headers,
						url: res.url,
					};
				}
			}
		} catch (e) {
			const err = error(e);
			const json = err.json;
			switch (err.status) {
				case 403: {
					err.json = getContinentCountryResponse403.parse(json);
					break;
				}
			}
			throw err;
		}

		invariant(false);
	};

	return {
		query: queryFn,
		useQuery: (
			options?: Omit<
				UseQueryOptions<
					ApiResponse<getContinentCountryResponse200>,
					GenericWretchError<getContinentCountryResponse403>
				>,
				"queryFn"
			>,
		): UseQueryResult<
			ApiResponse<getContinentCountryResponse200>,
			GenericWretchError<getContinentCountryResponse403>
		> => {
			const key = ["getContinentCountry", ...(options?.queryKey || [])];
			const query: QueryFunction<ApiResponse<getContinentCountryResponse200>> =
				async ({ meta, queryKey, pageParam, signal }) => {
					return queryFn(signal);
				};
			const invalidate = () => queryClient.invalidateQueries(key);
			const queryResult = useQuery<
				ApiResponse<getContinentCountryResponse200>,
				GenericWretchError<getContinentCountryResponse403>
			>(key, query, options || {}) as UseQueryResult<
				ApiResponse<getContinentCountryResponse200>,
				GenericWretchError<getContinentCountryResponse403>
			>;
			queryResult.invalidate = invalidate;
			queryResult.key = key;
			return queryResult;
		},
		useInfiniteQuery: (
			options?: Omit<
				UseInfiniteQueryOptions<
					ApiResponse<getContinentCountryResponse200>,
					GenericWretchError<getContinentCountryResponse403>
				>,
				"queryFn"
			>,
		): UseInfiniteQueryResult<
			ApiResponse<getContinentCountryResponse200>,
			GenericWretchError<getContinentCountryResponse403>
		> => {
			const key = ["getContinentCountry", ...(options?.queryKey || [])];
			const query: QueryFunction<ApiResponse<getContinentCountryResponse200>> =
				async ({ meta, queryKey, pageParam, signal }) => {
					return queryFn(signal);
				};
			const invalidate = () => queryClient.invalidateQueries(key);
			const queryResult = useInfiniteQuery<
				ApiResponse<getContinentCountryResponse200>,
				GenericWretchError<getContinentCountryResponse403>
			>(key, query, options || {}) as UseInfiniteQueryResult<
				ApiResponse<getContinentCountryResponse200>,
				GenericWretchError<getContinentCountryResponse403>
			>;
			queryResult.invalidate = invalidate;
			queryResult.key = key;
			return queryResult;
		},
	};
};

const getNPClubs = (wretch: Http, queryClient: QueryClient) => {
	const queryFn = async (
		params: {
			start: number;
			length: number;
			search?: string;
			country?: string;
			showcase?: number;
		},
		signal?: AbortSignal | undefined,
	): Promise<ApiResponse<getNPClubsResponse200>> => {
		const args = z
			.object({
				start: z.number().int(),
				length: z.number().int(),
				search: z.string().nullable().optional(),
				country: z.string().nullable().optional(),
				showcase: z.number().int().nullable().optional(),
			})
			.parse(params);
		const { start, length, search, country, showcase } = args;
		try {
			const res = await wretch
				.options({ signal })
				.url("/admin/api/nonPartnerClubs")
				.query(qs.stringify({ start, length, search, country, showcase }))
				.get()
				.res();
			const json = await res.json();
			switch (res.status) {
				case 200: {
					const data = getNPClubsResponse200.parse(json);
					return {
						status: res.status,
						data,
						headers: res.headers,
						url: res.url,
					};
				}
			}
		} catch (e) {
			const err = error(e);
			const json = err.json;
			switch (err.status) {
				case 403: {
					err.json = getNPClubsResponse403.parse(json);
					break;
				}
			}
			throw err;
		}

		invariant(false);
	};

	return {
		query: queryFn,
		useQuery: (
			params: {
				start: number;
				length: number;
				search?: string;
				country?: string;
				showcase?: number;
			},
			options?: Omit<
				UseQueryOptions<
					ApiResponse<getNPClubsResponse200>,
					GenericWretchError<getNPClubsResponse403>
				>,
				"queryFn"
			>,
		): UseQueryResult<
			ApiResponse<getNPClubsResponse200>,
			GenericWretchError<getNPClubsResponse403>
		> => {
			const key = ["getNPClubs", params, ...(options?.queryKey || [])];
			const query: QueryFunction<ApiResponse<getNPClubsResponse200>> = async ({
				meta,
				queryKey,
				pageParam,
				signal,
			}) => {
				return queryFn(params, signal);
			};
			const invalidate = () => queryClient.invalidateQueries(key);
			const queryResult = useQuery<
				ApiResponse<getNPClubsResponse200>,
				GenericWretchError<getNPClubsResponse403>
			>(key, query, options || {}) as UseQueryResult<
				ApiResponse<getNPClubsResponse200>,
				GenericWretchError<getNPClubsResponse403>
			>;
			queryResult.invalidate = invalidate;
			queryResult.key = key;
			return queryResult;
		},
		useInfiniteQuery: (
			params: {
				start: number;
				length: number;
				search?: string;
				country?: string;
				showcase?: number;
			},
			options?: Omit<
				UseInfiniteQueryOptions<
					ApiResponse<getNPClubsResponse200>,
					GenericWretchError<getNPClubsResponse403>
				>,
				"queryFn"
			>,
		): UseInfiniteQueryResult<
			ApiResponse<getNPClubsResponse200>,
			GenericWretchError<getNPClubsResponse403>
		> => {
			const key = ["getNPClubs", params, ...(options?.queryKey || [])];
			const query: QueryFunction<ApiResponse<getNPClubsResponse200>> = async ({
				meta,
				queryKey,
				pageParam,
				signal,
			}) => {
				return queryFn(params, signal);
			};
			const invalidate = () => queryClient.invalidateQueries(key);
			const queryResult = useInfiniteQuery<
				ApiResponse<getNPClubsResponse200>,
				GenericWretchError<getNPClubsResponse403>
			>(key, query, options || {}) as UseInfiniteQueryResult<
				ApiResponse<getNPClubsResponse200>,
				GenericWretchError<getNPClubsResponse403>
			>;
			queryResult.invalidate = invalidate;
			queryResult.key = key;
			return queryResult;
		},
	};
};

const addUpdateNPClub = (wretch: Http) => {
	const mutation = async (params: { body: addUpdateNPClub_Body }) => {
		const args = z.object({ body: z.any() }).parse(params);
		const { body } = args;

		try {
			const res = await wretch
				.url("/admin/api/addUpdateNPClub")
				.json(body)
				.post()
				.res();
			const json = await res.json();
			switch (res.status) {
				case 200: {
					const data = addUpdateNPClubResponse200.parse(json);
					return {
						status: res.status,
						data,
						headers: res.headers,
						url: res.url,
					};
				}
			}
		} catch (e) {
			const err = error(e);
			const json = err.json;
			switch (err.status) {
				case 403: {
					err.json = addUpdateNPClubResponse403.parse(json);
					break;
				}
			}
			throw err;
		}

		invariant(false);
	};

	return {
		mutation,
		useMutation: (
			options?: Omit<
				UseMutationOptions<
					ApiResponse<addUpdateNPClubResponse200> | undefined,
					GenericWretchError<addUpdateNPClubResponse403>,
					{ body: addUpdateNPClub_Body }
				>,
				"mutationFn"
			>,
		) => {
			return useMutation<
				ApiResponse<addUpdateNPClubResponse200> | undefined,
				GenericWretchError<addUpdateNPClubResponse403>,
				{ body: addUpdateNPClub_Body }
			>(mutation, options || {});
		},
	};
};

const deleteNPClub = (wretch: Http) => {
	const mutation = async (params: { clubId: number }) => {
		const args = z.object({ clubId: z.number().int() }).parse(params);
		const { clubId } = args;

		try {
			const res = await wretch
				.url(`/admin/api/nonPartnerClub/${clubId}`)
				.delete()
				.res();
			const json = await res.json();
			switch (res.status) {
				case 200: {
					const data = deleteNPClubResponse200.parse(json);
					return {
						status: res.status,
						data,
						headers: res.headers,
						url: res.url,
					};
				}
			}
		} catch (e) {
			const err = error(e);
			const json = err.json;
			switch (err.status) {
				case 403: {
					err.json = deleteNPClubResponse403.parse(json);
					break;
				}
			}
			throw err;
		}

		invariant(false);
	};

	return {
		mutation,
		useMutation: (
			options?: Omit<
				UseMutationOptions<
					ApiResponse<deleteNPClubResponse200> | undefined,
					GenericWretchError<deleteNPClubResponse403>,
					{ clubId: number }
				>,
				"mutationFn"
			>,
		) => {
			return useMutation<
				ApiResponse<deleteNPClubResponse200> | undefined,
				GenericWretchError<deleteNPClubResponse403>,
				{ clubId: number }
			>(mutation, options || {});
		},
	};
};

const uploadNPClubPhoto = (wretch: Http) => {
	const mutation = async (params: { body: uploadProfilePhoto_Body }) => {
		const args = z.object({ body: z.any() }).parse(params);
		const { body } = args;

		try {
			const res = await wretch
				.url("/admin/api/uploadNonPartnerClubLogo")
				.json(body)
				.post()
				.res();
			const json = await res.json();
			switch (res.status) {
				case 200: {
					const data = uploadNPClubPhotoResponse200.parse(json);
					return {
						status: res.status,
						data,
						headers: res.headers,
						url: res.url,
					};
				}
			}
		} catch (e) {
			const err = error(e);
			const json = err.json;
			switch (err.status) {
				case 403: {
					err.json = uploadNPClubPhotoResponse403.parse(json);
					break;
				}
			}
			throw err;
		}

		invariant(false);
	};

	return {
		mutation,
		useMutation: (
			options?: Omit<
				UseMutationOptions<
					ApiResponse<uploadNPClubPhotoResponse200> | undefined,
					GenericWretchError<uploadNPClubPhotoResponse403>,
					{ body: uploadProfilePhoto_Body }
				>,
				"mutationFn"
			>,
		) => {
			return useMutation<
				ApiResponse<uploadNPClubPhotoResponse200> | undefined,
				GenericWretchError<uploadNPClubPhotoResponse403>,
				{ body: uploadProfilePhoto_Body }
			>(mutation, options || {});
		},
	};
};

const uploadNPPlayerPicture = (wretch: Http) => {
	const mutation = async (params: { body: uploadProfilePhoto_Body }) => {
		const args = z.object({ body: z.any() }).parse(params);
		const { body } = args;

		try {
			const res = await wretch
				.url("/admin/api/uploadNonPartnerPlayerPicture")
				.json(body)
				.post()
				.res();
			const json = await res.json();
			switch (res.status) {
				case 200: {
					const data = uploadNPPlayerPictureResponse200.parse(json);
					return {
						status: res.status,
						data,
						headers: res.headers,
						url: res.url,
					};
				}
			}
		} catch (e) {
			const err = error(e);
			const json = err.json;
			switch (err.status) {
				case 403: {
					err.json = uploadNPPlayerPictureResponse403.parse(json);
					break;
				}
			}
			throw err;
		}

		invariant(false);
	};

	return {
		mutation,
		useMutation: (
			options?: Omit<
				UseMutationOptions<
					ApiResponse<uploadNPPlayerPictureResponse200> | undefined,
					GenericWretchError<uploadNPPlayerPictureResponse403>,
					{ body: uploadProfilePhoto_Body }
				>,
				"mutationFn"
			>,
		) => {
			return useMutation<
				ApiResponse<uploadNPPlayerPictureResponse200> | undefined,
				GenericWretchError<uploadNPPlayerPictureResponse403>,
				{ body: uploadProfilePhoto_Body }
			>(mutation, options || {});
		},
	};
};

const getNPPlayers = (wretch: Http, queryClient: QueryClient) => {
	const queryFn = async (
		params: {
			start: number;
			length: number;
			search?: string;
			country?: string;
			clubNonpartnerId?: string;
		},
		signal?: AbortSignal | undefined,
	): Promise<ApiResponse<getNPPlayersResponse200>> => {
		const args = z
			.object({
				start: z.number().int(),
				length: z.number().int(),
				search: z.string().optional(),
				country: z.string().optional(),
				clubNonpartnerId: z.string().optional(),
			})
			.parse(params);
		const { start, length, search, country, clubNonpartnerId } = args;
		try {
			const res = await wretch
				.options({ signal })
				.url("/admin/api/nonPartnerPlayers")
				.query(
					qs.stringify({ start, length, search, country, clubNonpartnerId }),
				)
				.get()
				.res();
			const json = await res.json();
			switch (res.status) {
				case 200: {
					const data = getNPPlayersResponse200.parse(json);
					return {
						status: res.status,
						data,
						headers: res.headers,
						url: res.url,
					};
				}
			}
		} catch (e) {
			const err = error(e);
			const json = err.json;
			switch (err.status) {
				case 403: {
					err.json = getNPPlayersResponse403.parse(json);
					break;
				}
			}
			throw err;
		}

		invariant(false);
	};

	return {
		query: queryFn,
		useQuery: (
			params: {
				start: number;
				length: number;
				search?: string;
				country?: string;
				clubNonpartnerId?: string;
			},
			options?: Omit<
				UseQueryOptions<
					ApiResponse<getNPPlayersResponse200>,
					GenericWretchError<getNPPlayersResponse403>
				>,
				"queryFn"
			>,
		): UseQueryResult<
			ApiResponse<getNPPlayersResponse200>,
			GenericWretchError<getNPPlayersResponse403>
		> => {
			const key = ["getNPPlayers", params, ...(options?.queryKey || [])];
			const query: QueryFunction<ApiResponse<getNPPlayersResponse200>> =
				async ({ meta, queryKey, pageParam, signal }) => {
					return queryFn(params, signal);
				};
			const invalidate = () => queryClient.invalidateQueries(key);
			const queryResult = useQuery<
				ApiResponse<getNPPlayersResponse200>,
				GenericWretchError<getNPPlayersResponse403>
			>(key, query, options || {}) as UseQueryResult<
				ApiResponse<getNPPlayersResponse200>,
				GenericWretchError<getNPPlayersResponse403>
			>;
			queryResult.invalidate = invalidate;
			queryResult.key = key;
			return queryResult;
		},
		useInfiniteQuery: (
			params: {
				start: number;
				length: number;
				search?: string;
				country?: string;
				clubNonpartnerId?: string;
			},
			options?: Omit<
				UseInfiniteQueryOptions<
					ApiResponse<getNPPlayersResponse200>,
					GenericWretchError<getNPPlayersResponse403>
				>,
				"queryFn"
			>,
		): UseInfiniteQueryResult<
			ApiResponse<getNPPlayersResponse200>,
			GenericWretchError<getNPPlayersResponse403>
		> => {
			const key = ["getNPPlayers", params, ...(options?.queryKey || [])];
			const query: QueryFunction<ApiResponse<getNPPlayersResponse200>> =
				async ({ meta, queryKey, pageParam, signal }) => {
					return queryFn(params, signal);
				};
			const invalidate = () => queryClient.invalidateQueries(key);
			const queryResult = useInfiniteQuery<
				ApiResponse<getNPPlayersResponse200>,
				GenericWretchError<getNPPlayersResponse403>
			>(key, query, options || {}) as UseInfiniteQueryResult<
				ApiResponse<getNPPlayersResponse200>,
				GenericWretchError<getNPPlayersResponse403>
			>;
			queryResult.invalidate = invalidate;
			queryResult.key = key;
			return queryResult;
		},
	};
};

const getNationalityList = (wretch: Http, queryClient: QueryClient) => {
	const queryFn = async (
		signal?: AbortSignal | undefined,
	): Promise<ApiResponse<getNationalityListResponse200>> => {
		try {
			const res = await wretch
				.options({ signal })
				.url("/admin/api/getNationalityList")
				.get()
				.res();
			const json = await res.json();
			switch (res.status) {
				case 200: {
					const data = getNationalityListResponse200.parse(json);
					return {
						status: res.status,
						data,
						headers: res.headers,
						url: res.url,
					};
				}
			}
		} catch (e) {
			const err = error(e);
			const json = err.json;
			switch (err.status) {
				case 403: {
					err.json = getNationalityListResponse403.parse(json);
					break;
				}
			}
			throw err;
		}

		invariant(false);
	};

	return {
		query: queryFn,
		useQuery: (
			options?: Omit<
				UseQueryOptions<
					ApiResponse<getNationalityListResponse200>,
					GenericWretchError<getNationalityListResponse403>
				>,
				"queryFn"
			>,
		): UseQueryResult<
			ApiResponse<getNationalityListResponse200>,
			GenericWretchError<getNationalityListResponse403>
		> => {
			const key = ["getNationalityList", ...(options?.queryKey || [])];
			const query: QueryFunction<ApiResponse<getNationalityListResponse200>> =
				async ({ meta, queryKey, pageParam, signal }) => {
					return queryFn(signal);
				};
			const invalidate = () => queryClient.invalidateQueries(key);
			const queryResult = useQuery<
				ApiResponse<getNationalityListResponse200>,
				GenericWretchError<getNationalityListResponse403>
			>(key, query, options || {}) as UseQueryResult<
				ApiResponse<getNationalityListResponse200>,
				GenericWretchError<getNationalityListResponse403>
			>;
			queryResult.invalidate = invalidate;
			queryResult.key = key;
			return queryResult;
		},
		useInfiniteQuery: (
			options?: Omit<
				UseInfiniteQueryOptions<
					ApiResponse<getNationalityListResponse200>,
					GenericWretchError<getNationalityListResponse403>
				>,
				"queryFn"
			>,
		): UseInfiniteQueryResult<
			ApiResponse<getNationalityListResponse200>,
			GenericWretchError<getNationalityListResponse403>
		> => {
			const key = ["getNationalityList", ...(options?.queryKey || [])];
			const query: QueryFunction<ApiResponse<getNationalityListResponse200>> =
				async ({ meta, queryKey, pageParam, signal }) => {
					return queryFn(signal);
				};
			const invalidate = () => queryClient.invalidateQueries(key);
			const queryResult = useInfiniteQuery<
				ApiResponse<getNationalityListResponse200>,
				GenericWretchError<getNationalityListResponse403>
			>(key, query, options || {}) as UseInfiniteQueryResult<
				ApiResponse<getNationalityListResponse200>,
				GenericWretchError<getNationalityListResponse403>
			>;
			queryResult.invalidate = invalidate;
			queryResult.key = key;
			return queryResult;
		},
	};
};

const getPositionList = (wretch: Http, queryClient: QueryClient) => {
	const queryFn = async (
		signal?: AbortSignal | undefined,
	): Promise<ApiResponse<getPositionListResponse200>> => {
		try {
			const res = await wretch
				.options({ signal })
				.url("/admin/api/getPositionList")
				.get()
				.res();
			const json = await res.json();
			switch (res.status) {
				case 200: {
					const data = getPositionListResponse200.parse(json);
					return {
						status: res.status,
						data,
						headers: res.headers,
						url: res.url,
					};
				}
			}
		} catch (e) {
			const err = error(e);
			const json = err.json;
			switch (err.status) {
				case 403: {
					err.json = getPositionListResponse403.parse(json);
					break;
				}
			}
			throw err;
		}

		invariant(false);
	};

	return {
		query: queryFn,
		useQuery: (
			options?: Omit<
				UseQueryOptions<
					ApiResponse<getPositionListResponse200>,
					GenericWretchError<getPositionListResponse403>
				>,
				"queryFn"
			>,
		): UseQueryResult<
			ApiResponse<getPositionListResponse200>,
			GenericWretchError<getPositionListResponse403>
		> => {
			const key = ["getPositionList", ...(options?.queryKey || [])];
			const query: QueryFunction<ApiResponse<getPositionListResponse200>> =
				async ({ meta, queryKey, pageParam, signal }) => {
					return queryFn(signal);
				};
			const invalidate = () => queryClient.invalidateQueries(key);
			const queryResult = useQuery<
				ApiResponse<getPositionListResponse200>,
				GenericWretchError<getPositionListResponse403>
			>(key, query, options || {}) as UseQueryResult<
				ApiResponse<getPositionListResponse200>,
				GenericWretchError<getPositionListResponse403>
			>;
			queryResult.invalidate = invalidate;
			queryResult.key = key;
			return queryResult;
		},
		useInfiniteQuery: (
			options?: Omit<
				UseInfiniteQueryOptions<
					ApiResponse<getPositionListResponse200>,
					GenericWretchError<getPositionListResponse403>
				>,
				"queryFn"
			>,
		): UseInfiniteQueryResult<
			ApiResponse<getPositionListResponse200>,
			GenericWretchError<getPositionListResponse403>
		> => {
			const key = ["getPositionList", ...(options?.queryKey || [])];
			const query: QueryFunction<ApiResponse<getPositionListResponse200>> =
				async ({ meta, queryKey, pageParam, signal }) => {
					return queryFn(signal);
				};
			const invalidate = () => queryClient.invalidateQueries(key);
			const queryResult = useInfiniteQuery<
				ApiResponse<getPositionListResponse200>,
				GenericWretchError<getPositionListResponse403>
			>(key, query, options || {}) as UseInfiniteQueryResult<
				ApiResponse<getPositionListResponse200>,
				GenericWretchError<getPositionListResponse403>
			>;
			queryResult.invalidate = invalidate;
			queryResult.key = key;
			return queryResult;
		},
	};
};

const getGeographicalAreaList = (wretch: Http, queryClient: QueryClient) => {
	const queryFn = async (
		signal?: AbortSignal | undefined,
	): Promise<ApiResponse<getGeographicalAreaListResponse200>> => {
		try {
			const res = await wretch
				.options({ signal })
				.url("/admin/api/getGeographicalAreaList")
				.get()
				.res();
			const json = await res.json();
			switch (res.status) {
				case 200: {
					const data = getGeographicalAreaListResponse200.parse(json);
					return {
						status: res.status,
						data,
						headers: res.headers,
						url: res.url,
					};
				}
			}
		} catch (e) {
			const err = error(e);
			const json = err.json;
			switch (err.status) {
				case 403: {
					err.json = getGeographicalAreaListResponse403.parse(json);
					break;
				}
			}
			throw err;
		}

		invariant(false);
	};

	return {
		query: queryFn,
		useQuery: (
			options?: Omit<
				UseQueryOptions<
					ApiResponse<getGeographicalAreaListResponse200>,
					GenericWretchError<getGeographicalAreaListResponse403>
				>,
				"queryFn"
			>,
		): UseQueryResult<
			ApiResponse<getGeographicalAreaListResponse200>,
			GenericWretchError<getGeographicalAreaListResponse403>
		> => {
			const key = ["getGeographicalAreaList", ...(options?.queryKey || [])];
			const query: QueryFunction<
				ApiResponse<getGeographicalAreaListResponse200>
			> = async ({ meta, queryKey, pageParam, signal }) => {
				return queryFn(signal);
			};
			const invalidate = () => queryClient.invalidateQueries(key);
			const queryResult = useQuery<
				ApiResponse<getGeographicalAreaListResponse200>,
				GenericWretchError<getGeographicalAreaListResponse403>
			>(key, query, options || {}) as UseQueryResult<
				ApiResponse<getGeographicalAreaListResponse200>,
				GenericWretchError<getGeographicalAreaListResponse403>
			>;
			queryResult.invalidate = invalidate;
			queryResult.key = key;
			return queryResult;
		},
		useInfiniteQuery: (
			options?: Omit<
				UseInfiniteQueryOptions<
					ApiResponse<getGeographicalAreaListResponse200>,
					GenericWretchError<getGeographicalAreaListResponse403>
				>,
				"queryFn"
			>,
		): UseInfiniteQueryResult<
			ApiResponse<getGeographicalAreaListResponse200>,
			GenericWretchError<getGeographicalAreaListResponse403>
		> => {
			const key = ["getGeographicalAreaList", ...(options?.queryKey || [])];
			const query: QueryFunction<
				ApiResponse<getGeographicalAreaListResponse200>
			> = async ({ meta, queryKey, pageParam, signal }) => {
				return queryFn(signal);
			};
			const invalidate = () => queryClient.invalidateQueries(key);
			const queryResult = useInfiniteQuery<
				ApiResponse<getGeographicalAreaListResponse200>,
				GenericWretchError<getGeographicalAreaListResponse403>
			>(key, query, options || {}) as UseInfiniteQueryResult<
				ApiResponse<getGeographicalAreaListResponse200>,
				GenericWretchError<getGeographicalAreaListResponse403>
			>;
			queryResult.invalidate = invalidate;
			queryResult.key = key;
			return queryResult;
		},
	};
};

const addUpdateNPPlayer = (wretch: Http) => {
	const mutation = async (params: { body: addUpdateNPPlayer_Body }) => {
		const args = z.object({ body: z.any() }).parse(params);
		const { body } = args;

		try {
			const res = await wretch
				.url("/admin/api/addUpdateNPPlayer")
				.json(body)
				.post()
				.res();
			const json = await res.json();
			switch (res.status) {
				case 200: {
					const data = addUpdateNPPlayerResponse200.parse(json);
					return {
						status: res.status,
						data,
						headers: res.headers,
						url: res.url,
					};
				}
			}
		} catch (e) {
			const err = error(e);
			const json = err.json;
			switch (err.status) {
				case 403: {
					err.json = addUpdateNPPlayerResponse403.parse(json);
					break;
				}
			}
			throw err;
		}

		invariant(false);
	};

	return {
		mutation,
		useMutation: (
			options?: Omit<
				UseMutationOptions<
					ApiResponse<addUpdateNPPlayerResponse200> | undefined,
					GenericWretchError<addUpdateNPPlayerResponse403>,
					{ body: addUpdateNPPlayer_Body }
				>,
				"mutationFn"
			>,
		) => {
			return useMutation<
				ApiResponse<addUpdateNPPlayerResponse200> | undefined,
				GenericWretchError<addUpdateNPPlayerResponse403>,
				{ body: addUpdateNPPlayer_Body }
			>(mutation, options || {});
		},
	};
};

const deleteNPPlayer = (wretch: Http) => {
	const mutation = async (params: { playerId: number }) => {
		const args = z.object({ playerId: z.number().int() }).parse(params);
		const { playerId } = args;

		try {
			const res = await wretch
				.url(`/admin/api/nonPartnerPlayer/${playerId}`)
				.delete()
				.res();
			const json = await res.json();
			switch (res.status) {
				case 200: {
					const data = deleteNPPlayerResponse200.parse(json);
					return {
						status: res.status,
						data,
						headers: res.headers,
						url: res.url,
					};
				}
			}
		} catch (e) {
			const err = error(e);
			const json = err.json;
			switch (err.status) {
				case 403: {
					err.json = deleteNPPlayerResponse403.parse(json);
					break;
				}
			}
			throw err;
		}

		invariant(false);
	};

	return {
		mutation,
		useMutation: (
			options?: Omit<
				UseMutationOptions<
					ApiResponse<deleteNPPlayerResponse200> | undefined,
					GenericWretchError<deleteNPPlayerResponse403>,
					{ playerId: number }
				>,
				"mutationFn"
			>,
		) => {
			return useMutation<
				ApiResponse<deleteNPPlayerResponse200> | undefined,
				GenericWretchError<deleteNPPlayerResponse403>,
				{ playerId: number }
			>(mutation, options || {});
		},
	};
};

const getAllNPClubs = (wretch: Http, queryClient: QueryClient) => {
	const queryFn = async (
		signal?: AbortSignal | undefined,
	): Promise<ApiResponse<getAllNPClubsResponse200>> => {
		try {
			const res = await wretch
				.options({ signal })
				.url("/admin/api/nonPartnerPlayer/clubOptions")
				.get()
				.res();
			const json = await res.json();
			switch (res.status) {
				case 200: {
					const data = getAllNPClubsResponse200.parse(json);
					return {
						status: res.status,
						data,
						headers: res.headers,
						url: res.url,
					};
				}
			}
		} catch (e) {
			const err = error(e);
			const json = err.json;
			switch (err.status) {
				case 403: {
					err.json = getAllNPClubsResponse403.parse(json);
					break;
				}
			}
			throw err;
		}

		invariant(false);
	};

	return {
		query: queryFn,
		useQuery: (
			options?: Omit<
				UseQueryOptions<
					ApiResponse<getAllNPClubsResponse200>,
					GenericWretchError<getAllNPClubsResponse403>
				>,
				"queryFn"
			>,
		): UseQueryResult<
			ApiResponse<getAllNPClubsResponse200>,
			GenericWretchError<getAllNPClubsResponse403>
		> => {
			const key = ["getAllNPClubs", ...(options?.queryKey || [])];
			const query: QueryFunction<ApiResponse<getAllNPClubsResponse200>> =
				async ({ meta, queryKey, pageParam, signal }) => {
					return queryFn(signal);
				};
			const invalidate = () => queryClient.invalidateQueries(key);
			const queryResult = useQuery<
				ApiResponse<getAllNPClubsResponse200>,
				GenericWretchError<getAllNPClubsResponse403>
			>(key, query, options || {}) as UseQueryResult<
				ApiResponse<getAllNPClubsResponse200>,
				GenericWretchError<getAllNPClubsResponse403>
			>;
			queryResult.invalidate = invalidate;
			queryResult.key = key;
			return queryResult;
		},
		useInfiniteQuery: (
			options?: Omit<
				UseInfiniteQueryOptions<
					ApiResponse<getAllNPClubsResponse200>,
					GenericWretchError<getAllNPClubsResponse403>
				>,
				"queryFn"
			>,
		): UseInfiniteQueryResult<
			ApiResponse<getAllNPClubsResponse200>,
			GenericWretchError<getAllNPClubsResponse403>
		> => {
			const key = ["getAllNPClubs", ...(options?.queryKey || [])];
			const query: QueryFunction<ApiResponse<getAllNPClubsResponse200>> =
				async ({ meta, queryKey, pageParam, signal }) => {
					return queryFn(signal);
				};
			const invalidate = () => queryClient.invalidateQueries(key);
			const queryResult = useInfiniteQuery<
				ApiResponse<getAllNPClubsResponse200>,
				GenericWretchError<getAllNPClubsResponse403>
			>(key, query, options || {}) as UseInfiniteQueryResult<
				ApiResponse<getAllNPClubsResponse200>,
				GenericWretchError<getAllNPClubsResponse403>
			>;
			queryResult.invalidate = invalidate;
			queryResult.key = key;
			return queryResult;
		},
	};
};

const getClubPlayers = (wretch: Http, queryClient: QueryClient) => {
	const queryFn = async (
		params: { clubId: number; type: string },
		signal?: AbortSignal | undefined,
	): Promise<ApiResponse<getClubPlayersResponse200>> => {
		const args = z
			.object({ clubId: z.number().int(), type: z.string() })
			.parse(params);
		const { clubId, type } = args;
		try {
			const res = await wretch
				.options({ signal })
				.url(`/admin/api/${clubId}/players`)
				.query(qs.stringify({ type }))
				.get()
				.res();
			const json = await res.json();
			switch (res.status) {
				case 200: {
					const data = getClubPlayersResponse200.parse(json);
					return {
						status: res.status,
						data,
						headers: res.headers,
						url: res.url,
					};
				}
			}
		} catch (e) {
			const err = error(e);
			throw err;
		}

		invariant(false);
	};

	return {
		query: queryFn,
		useQuery: (
			params: { clubId: number; type: string },
			options?: Omit<
				UseQueryOptions<
					ApiResponse<getClubPlayersResponse200>,
					GenericWretchError<null>
				>,
				"queryFn"
			>,
		): UseQueryResult<
			ApiResponse<getClubPlayersResponse200>,
			GenericWretchError<null>
		> => {
			const key = ["getClubPlayers", params, ...(options?.queryKey || [])];
			const query: QueryFunction<ApiResponse<getClubPlayersResponse200>> =
				async ({ meta, queryKey, pageParam, signal }) => {
					return queryFn(params, signal);
				};
			const invalidate = () => queryClient.invalidateQueries(key);
			const queryResult = useQuery<
				ApiResponse<getClubPlayersResponse200>,
				GenericWretchError<null>
			>(key, query, options || {}) as UseQueryResult<
				ApiResponse<getClubPlayersResponse200>,
				GenericWretchError<null>
			>;
			queryResult.invalidate = invalidate;
			queryResult.key = key;
			return queryResult;
		},
		useInfiniteQuery: (
			params: { clubId: number; type: string },
			options?: Omit<
				UseInfiniteQueryOptions<
					ApiResponse<getClubPlayersResponse200>,
					GenericWretchError<null>
				>,
				"queryFn"
			>,
		): UseInfiniteQueryResult<
			ApiResponse<getClubPlayersResponse200>,
			GenericWretchError<null>
		> => {
			const key = ["getClubPlayers", params, ...(options?.queryKey || [])];
			const query: QueryFunction<ApiResponse<getClubPlayersResponse200>> =
				async ({ meta, queryKey, pageParam, signal }) => {
					return queryFn(params, signal);
				};
			const invalidate = () => queryClient.invalidateQueries(key);
			const queryResult = useInfiniteQuery<
				ApiResponse<getClubPlayersResponse200>,
				GenericWretchError<null>
			>(key, query, options || {}) as UseInfiniteQueryResult<
				ApiResponse<getClubPlayersResponse200>,
				GenericWretchError<null>
			>;
			queryResult.invalidate = invalidate;
			queryResult.key = key;
			return queryResult;
		},
	};
};

const executeNPClubMigration = (wretch: Http) => {
	const mutation = async (params: { body: executeNPClubMigration_Body }) => {
		const args = z.object({ body: z.any() }).parse(params);
		const { body } = args;

		try {
			const res = await wretch
				.url("/admin/api/executeNonPartnerClubMigration")
				.json(body)
				.post()
				.res();
			const json = await res.json();
			switch (res.status) {
				case 200: {
					const data = executeNPClubMigrationResponse200.parse(json);
					return {
						status: res.status,
						data,
						headers: res.headers,
						url: res.url,
					};
				}
			}
		} catch (e) {
			const err = error(e);
			const json = err.json;
			switch (err.status) {
				case 403: {
					err.json = executeNPClubMigrationResponse403.parse(json);
					break;
				}
			}
			throw err;
		}

		invariant(false);
	};

	return {
		mutation,
		useMutation: (
			options?: Omit<
				UseMutationOptions<
					ApiResponse<executeNPClubMigrationResponse200> | undefined,
					GenericWretchError<executeNPClubMigrationResponse403>,
					{ body: executeNPClubMigration_Body }
				>,
				"mutationFn"
			>,
		) => {
			return useMutation<
				ApiResponse<executeNPClubMigrationResponse200> | undefined,
				GenericWretchError<executeNPClubMigrationResponse403>,
				{ body: executeNPClubMigration_Body }
			>(mutation, options || {});
		},
	};
};

const getTeamsByTargetClub = (wretch: Http, queryClient: QueryClient) => {
	const queryFn = async (
		params: { clubId: number },
		signal?: AbortSignal | undefined,
	): Promise<ApiResponse<getTeamsByTargetClubResponse200>> => {
		const args = z.object({ clubId: z.number().int() }).parse(params);
		const { clubId } = args;
		try {
			const res = await wretch
				.options({ signal })
				.url(`/admin/api/getTeamsByTargetClub/${clubId}`)
				.get()
				.res();
			const json = await res.json();
			switch (res.status) {
				case 200: {
					const data = getTeamsByTargetClubResponse200.parse(json);
					return {
						status: res.status,
						data,
						headers: res.headers,
						url: res.url,
					};
				}
			}
		} catch (e) {
			const err = error(e);
			const json = err.json;
			switch (err.status) {
				case 403: {
					err.json = getTeamsByTargetClubResponse403.parse(json);
					break;
				}
			}
			throw err;
		}

		invariant(false);
	};

	return {
		query: queryFn,
		useQuery: (
			params: { clubId: number },
			options?: Omit<
				UseQueryOptions<
					ApiResponse<getTeamsByTargetClubResponse200>,
					GenericWretchError<getTeamsByTargetClubResponse403>
				>,
				"queryFn"
			>,
		): UseQueryResult<
			ApiResponse<getTeamsByTargetClubResponse200>,
			GenericWretchError<getTeamsByTargetClubResponse403>
		> => {
			const key = [
				"getTeamsByTargetClub",
				params,
				...(options?.queryKey || []),
			];
			const query: QueryFunction<ApiResponse<getTeamsByTargetClubResponse200>> =
				async ({ meta, queryKey, pageParam, signal }) => {
					return queryFn(params, signal);
				};
			const invalidate = () => queryClient.invalidateQueries(key);
			const queryResult = useQuery<
				ApiResponse<getTeamsByTargetClubResponse200>,
				GenericWretchError<getTeamsByTargetClubResponse403>
			>(key, query, options || {}) as UseQueryResult<
				ApiResponse<getTeamsByTargetClubResponse200>,
				GenericWretchError<getTeamsByTargetClubResponse403>
			>;
			queryResult.invalidate = invalidate;
			queryResult.key = key;
			return queryResult;
		},
		useInfiniteQuery: (
			params: { clubId: number },
			options?: Omit<
				UseInfiniteQueryOptions<
					ApiResponse<getTeamsByTargetClubResponse200>,
					GenericWretchError<getTeamsByTargetClubResponse403>
				>,
				"queryFn"
			>,
		): UseInfiniteQueryResult<
			ApiResponse<getTeamsByTargetClubResponse200>,
			GenericWretchError<getTeamsByTargetClubResponse403>
		> => {
			const key = [
				"getTeamsByTargetClub",
				params,
				...(options?.queryKey || []),
			];
			const query: QueryFunction<ApiResponse<getTeamsByTargetClubResponse200>> =
				async ({ meta, queryKey, pageParam, signal }) => {
					return queryFn(params, signal);
				};
			const invalidate = () => queryClient.invalidateQueries(key);
			const queryResult = useInfiniteQuery<
				ApiResponse<getTeamsByTargetClubResponse200>,
				GenericWretchError<getTeamsByTargetClubResponse403>
			>(key, query, options || {}) as UseInfiniteQueryResult<
				ApiResponse<getTeamsByTargetClubResponse200>,
				GenericWretchError<getTeamsByTargetClubResponse403>
			>;
			queryResult.invalidate = invalidate;
			queryResult.key = key;
			return queryResult;
		},
	};
};

const playerExistsTargetClub = (wretch: Http) => {
	const mutation = async (params: { body: playerExistsTargetClub_Body }) => {
		const args = z.object({ body: z.any() }).parse(params);
		const { body } = args;

		try {
			const res = await wretch
				.url("/admin/api/playerExistsTargetClub")
				.json(body)
				.post()
				.res();
			const json = await res.json();
			switch (res.status) {
				case 200: {
					const data = playerExistsTargetClubResponse200.parse(json);
					return {
						status: res.status,
						data,
						headers: res.headers,
						url: res.url,
					};
				}
			}
		} catch (e) {
			const err = error(e);
			const json = err.json;
			switch (err.status) {
				case 403: {
					err.json = playerExistsTargetClubResponse403.parse(json);
					break;
				}
			}
			throw err;
		}

		invariant(false);
	};

	return {
		mutation,
		useMutation: (
			options?: Omit<
				UseMutationOptions<
					ApiResponse<playerExistsTargetClubResponse200> | undefined,
					GenericWretchError<playerExistsTargetClubResponse403>,
					{ body: playerExistsTargetClub_Body }
				>,
				"mutationFn"
			>,
		) => {
			return useMutation<
				ApiResponse<playerExistsTargetClubResponse200> | undefined,
				GenericWretchError<playerExistsTargetClubResponse403>,
				{ body: playerExistsTargetClub_Body }
			>(mutation, options || {});
		},
	};
};

const getMigrationStatus = (wretch: Http) => {
	const mutation = async (params: { body: getMigrationStatus_Body }) => {
		const args = z.object({ body: z.any() }).parse(params);
		const { body } = args;

		try {
			const res = await wretch
				.url("/admin/api/getMigrationStatus")
				.json(body)
				.post()
				.res();
			const json = await res.json();
			switch (res.status) {
				case 200: {
					const data = getMigrationStatusResponse200.parse(json);
					return {
						status: res.status,
						data,
						headers: res.headers,
						url: res.url,
					};
				}
			}
		} catch (e) {
			const err = error(e);
			const json = err.json;
			switch (err.status) {
				case 403: {
					err.json = getMigrationStatusResponse403.parse(json);
					break;
				}
			}
			throw err;
		}

		invariant(false);
	};

	return {
		mutation,
		useMutation: (
			options?: Omit<
				UseMutationOptions<
					ApiResponse<getMigrationStatusResponse200> | undefined,
					GenericWretchError<getMigrationStatusResponse403>,
					{ body: getMigrationStatus_Body }
				>,
				"mutationFn"
			>,
		) => {
			return useMutation<
				ApiResponse<getMigrationStatusResponse200> | undefined,
				GenericWretchError<getMigrationStatusResponse403>,
				{ body: getMigrationStatus_Body }
			>(mutation, options || {});
		},
	};
};

const executePartnerClubMigration = (wretch: Http) => {
	const mutation = async (params: { body: executeNPClubMigration_Body }) => {
		const args = z.object({ body: z.any() }).parse(params);
		const { body } = args;

		try {
			const res = await wretch
				.url("/admin/api/executePartnerClubMigration")
				.json(body)
				.post()
				.res();
			const json = await res.json();
			switch (res.status) {
				case 200: {
					const data = executePartnerClubMigrationResponse200.parse(json);
					return {
						status: res.status,
						data,
						headers: res.headers,
						url: res.url,
					};
				}
			}
		} catch (e) {
			const err = error(e);
			const json = err.json;
			switch (err.status) {
				case 403: {
					err.json = executePartnerClubMigrationResponse403.parse(json);
					break;
				}
			}
			throw err;
		}

		invariant(false);
	};

	return {
		mutation,
		useMutation: (
			options?: Omit<
				UseMutationOptions<
					ApiResponse<executePartnerClubMigrationResponse200> | undefined,
					GenericWretchError<executePartnerClubMigrationResponse403>,
					{ body: executeNPClubMigration_Body }
				>,
				"mutationFn"
			>,
		) => {
			return useMutation<
				ApiResponse<executePartnerClubMigrationResponse200> | undefined,
				GenericWretchError<executePartnerClubMigrationResponse403>,
				{ body: executeNPClubMigration_Body }
			>(mutation, options || {});
		},
	};
};

const createPartnerPlayerFromNonpartner = (wretch: Http) => {
	const mutation = async (params: {
		body: createPartnerPlayerFromNonpartner_Body;
	}) => {
		const args = z.object({ body: z.any() }).parse(params);
		const { body } = args;

		try {
			const res = await wretch
				.url("/admin/api/createPartnerPlayerFromNonpartner")
				.json(body)
				.post()
				.res();
			const json = await res.json();
			switch (res.status) {
				case 200: {
					const data = createPartnerPlayerFromNonpartnerResponse200.parse(json);
					return {
						status: res.status,
						data,
						headers: res.headers,
						url: res.url,
					};
				}
			}
		} catch (e) {
			const err = error(e);
			throw err;
		}

		invariant(false);
	};

	return {
		mutation,
		useMutation: (
			options?: Omit<
				UseMutationOptions<
					ApiResponse<createPartnerPlayerFromNonpartnerResponse200> | undefined,
					GenericWretchError<null>,
					{ body: createPartnerPlayerFromNonpartner_Body }
				>,
				"mutationFn"
			>,
		) => {
			return useMutation<
				ApiResponse<createPartnerPlayerFromNonpartnerResponse200> | undefined,
				GenericWretchError<null>,
				{ body: createPartnerPlayerFromNonpartner_Body }
			>(mutation, options || {});
		},
	};
};

const partnerPlayerExistsTargetClub = (wretch: Http) => {
	const mutation = async (params: {
		body: partnerPlayerExistsTargetClub_Body;
	}) => {
		const args = z.object({ body: z.any() }).parse(params);
		const { body } = args;

		try {
			const res = await wretch
				.url("/admin/api/partnerPlayerExistsTargetClub")
				.json(body)
				.post()
				.res();
			const json = await res.json();
			switch (res.status) {
				case 200: {
					const data = partnerPlayerExistsTargetClubResponse200.parse(json);
					return {
						status: res.status,
						data,
						headers: res.headers,
						url: res.url,
					};
				}
			}
		} catch (e) {
			const err = error(e);
			const json = err.json;
			switch (err.status) {
				case 403: {
					err.json = partnerPlayerExistsTargetClubResponse403.parse(json);
					break;
				}
			}
			throw err;
		}

		invariant(false);
	};

	return {
		mutation,
		useMutation: (
			options?: Omit<
				UseMutationOptions<
					ApiResponse<partnerPlayerExistsTargetClubResponse200> | undefined,
					GenericWretchError<partnerPlayerExistsTargetClubResponse403>,
					{ body: partnerPlayerExistsTargetClub_Body }
				>,
				"mutationFn"
			>,
		) => {
			return useMutation<
				ApiResponse<partnerPlayerExistsTargetClubResponse200> | undefined,
				GenericWretchError<partnerPlayerExistsTargetClubResponse403>,
				{ body: partnerPlayerExistsTargetClub_Body }
			>(mutation, options || {});
		},
	};
};

const getPartnerMigrationStatus = (wretch: Http) => {
	const mutation = async (params: { body: getPartnerMigrationStatus_Body }) => {
		const args = z.object({ body: z.any() }).parse(params);
		const { body } = args;

		try {
			const res = await wretch
				.url("/admin/api/getPartnerMigrationStatus")
				.json(body)
				.post()
				.res();
			const json = await res.json();
			switch (res.status) {
				case 200: {
					const data = getPartnerMigrationStatusResponse200.parse(json);
					return {
						status: res.status,
						data,
						headers: res.headers,
						url: res.url,
					};
				}
			}
		} catch (e) {
			const err = error(e);
			const json = err.json;
			switch (err.status) {
				case 403: {
					err.json = getPartnerMigrationStatusResponse403.parse(json);
					break;
				}
			}
			throw err;
		}

		invariant(false);
	};

	return {
		mutation,
		useMutation: (
			options?: Omit<
				UseMutationOptions<
					ApiResponse<getPartnerMigrationStatusResponse200> | undefined,
					GenericWretchError<getPartnerMigrationStatusResponse403>,
					{ body: getPartnerMigrationStatus_Body }
				>,
				"mutationFn"
			>,
		) => {
			return useMutation<
				ApiResponse<getPartnerMigrationStatusResponse200> | undefined,
				GenericWretchError<getPartnerMigrationStatusResponse403>,
				{ body: getPartnerMigrationStatus_Body }
			>(mutation, options || {});
		},
	};
};

const updateGameTeam = (wretch: Http) => {
	const mutation = async (params: { body: updateGameTeam_Body }) => {
		const args = z.object({ body: z.any() }).parse(params);
		const { body } = args;

		try {
			const res = await wretch
				.url("/admin/api/updateGameTeam")
				.json(body)
				.post()
				.res();
			const json = await res.json();
			switch (res.status) {
				case 200: {
					const data = updateGameTeamResponse200.parse(json);
					return {
						status: res.status,
						data,
						headers: res.headers,
						url: res.url,
					};
				}
			}
		} catch (e) {
			const err = error(e);
			const json = err.json;
			switch (err.status) {
				case 403: {
					err.json = updateGameTeamResponse403.parse(json);
					break;
				}
			}
			throw err;
		}

		invariant(false);
	};

	return {
		mutation,
		useMutation: (
			options?: Omit<
				UseMutationOptions<
					ApiResponse<updateGameTeamResponse200> | undefined,
					GenericWretchError<updateGameTeamResponse403>,
					{ body: updateGameTeam_Body }
				>,
				"mutationFn"
			>,
		) => {
			return useMutation<
				ApiResponse<updateGameTeamResponse200> | undefined,
				GenericWretchError<updateGameTeamResponse403>,
				{ body: updateGameTeam_Body }
			>(mutation, options || {});
		},
	};
};

const migrateSourceClubNoPlayers = (wretch: Http) => {
	const mutation = async (params: {
		sourceClubId: number;
		targetClubId: number;
		type: string;
	}) => {
		const args = z
			.object({
				sourceClubId: z.number().int(),
				targetClubId: z.number().int(),
				type: z.string(),
			})
			.parse(params);
		const { sourceClubId, targetClubId, type } = args;

		try {
			const res = await wretch
				.url("/admin/api/migrateSourceClubNoPlayers")
				.query(qs.stringify({ sourceClubId, targetClubId, type }))
				.delete()
				.res();
			const json = await res.json();
			switch (res.status) {
				case 200: {
					const data = migrateSourceClubNoPlayersResponse200.parse(json);
					return {
						status: res.status,
						data,
						headers: res.headers,
						url: res.url,
					};
				}
			}
		} catch (e) {
			const err = error(e);
			const json = err.json;
			switch (err.status) {
				case 403: {
					err.json = migrateSourceClubNoPlayersResponse403.parse(json);
					break;
				}
			}
			throw err;
		}

		invariant(false);
	};

	return {
		mutation,
		useMutation: (
			options?: Omit<
				UseMutationOptions<
					ApiResponse<migrateSourceClubNoPlayersResponse200> | undefined,
					GenericWretchError<migrateSourceClubNoPlayersResponse403>,
					{ sourceClubId: number; targetClubId: number; type: string }
				>,
				"mutationFn"
			>,
		) => {
			return useMutation<
				ApiResponse<migrateSourceClubNoPlayersResponse200> | undefined,
				GenericWretchError<migrateSourceClubNoPlayersResponse403>,
				{ sourceClubId: number; targetClubId: number; type: string }
			>(mutation, options || {});
		},
	};
};

const beginMigration = (wretch: Http) => {
	const mutation = async (params: { body: beginMigration_Body }) => {
		const args = z.object({ body: z.any() }).parse(params);
		const { body } = args;

		try {
			const res = await wretch
				.url("/admin/api/beginMigration")
				.json(body)
				.post()
				.res();
			const json = await res.json();
			switch (res.status) {
				case 200: {
					const data = BeginMigration.parse(json);
					return {
						status: res.status,
						data,
						headers: res.headers,
						url: res.url,
					};
				}
			}
		} catch (e) {
			const err = error(e);
			throw err;
		}

		invariant(false);
	};

	return {
		mutation,
		useMutation: (
			options?: Omit<
				UseMutationOptions<
					ApiResponse<BeginMigration> | undefined,
					GenericWretchError<null>,
					{ body: beginMigration_Body }
				>,
				"mutationFn"
			>,
		) => {
			return useMutation<
				ApiResponse<BeginMigration> | undefined,
				GenericWretchError<null>,
				{ body: beginMigration_Body }
			>(mutation, options || {});
		},
	};
};

const clubMigrationWithZeroPlayers = (wretch: Http) => {
	const mutation = async (params: {
		body: clubMigrationWithZeroPlayers_Body;
	}) => {
		const args = z.object({ body: z.any() }).parse(params);
		const { body } = args;

		try {
			const res = await wretch
				.url("/admin/api/clubMigrationWithZeroPlayers")
				.json(body)
				.post()
				.res();
			const json = await res.json();
			switch (res.status) {
				case 200: {
					const data = clubMigrationWithZeroPlayersResponse200.parse(json);
					return {
						status: res.status,
						data,
						headers: res.headers,
						url: res.url,
					};
				}
			}
		} catch (e) {
			const err = error(e);
			throw err;
		}

		invariant(false);
	};

	return {
		mutation,
		useMutation: (
			options?: Omit<
				UseMutationOptions<
					ApiResponse<clubMigrationWithZeroPlayersResponse200> | undefined,
					GenericWretchError<null>,
					{ body: clubMigrationWithZeroPlayers_Body }
				>,
				"mutationFn"
			>,
		) => {
			return useMutation<
				ApiResponse<clubMigrationWithZeroPlayersResponse200> | undefined,
				GenericWretchError<null>,
				{ body: clubMigrationWithZeroPlayers_Body }
			>(mutation, options || {});
		},
	};
};

const completeMigration = (wretch: Http) => {
	const mutation = async (params: { body: completeMigration_Body }) => {
		const args = z.object({ body: z.any() }).parse(params);
		const { body } = args;

		try {
			const res = await wretch
				.url("/admin/api/completeMigration")
				.json(body)
				.post()
				.res();
			const json = await res.json();
			switch (res.status) {
				case 200: {
					const data = CompleteMigration.parse(json);
					return {
						status: res.status,
						data,
						headers: res.headers,
						url: res.url,
					};
				}
			}
		} catch (e) {
			const err = error(e);
			throw err;
		}

		invariant(false);
	};

	return {
		mutation,
		useMutation: (
			options?: Omit<
				UseMutationOptions<
					ApiResponse<CompleteMigration> | undefined,
					GenericWretchError<null>,
					{ body: completeMigration_Body }
				>,
				"mutationFn"
			>,
		) => {
			return useMutation<
				ApiResponse<CompleteMigration> | undefined,
				GenericWretchError<null>,
				{ body: completeMigration_Body }
			>(mutation, options || {});
		},
	};
};

const playerMigrationToPartner = (wretch: Http) => {
	const mutation = async (params: { body: playerMigrationToPartner_Body }) => {
		const args = z.object({ body: z.any() }).parse(params);
		const { body } = args;

		try {
			const res = await wretch
				.url("/admin/api/playerMigrationToPartner")
				.json(body)
				.post()
				.res();
			const json = await res.json();
			switch (res.status) {
				case 200: {
					const data = playerMigrationToPartnerResponse200.parse(json);
					return {
						status: res.status,
						data,
						headers: res.headers,
						url: res.url,
					};
				}
			}
		} catch (e) {
			const err = error(e);
			throw err;
		}

		invariant(false);
	};

	return {
		mutation,
		useMutation: (
			options?: Omit<
				UseMutationOptions<
					ApiResponse<playerMigrationToPartnerResponse200> | undefined,
					GenericWretchError<null>,
					{ body: playerMigrationToPartner_Body }
				>,
				"mutationFn"
			>,
		) => {
			return useMutation<
				ApiResponse<playerMigrationToPartnerResponse200> | undefined,
				GenericWretchError<null>,
				{ body: playerMigrationToPartner_Body }
			>(mutation, options || {});
		},
	};
};

const playerMigrationConflict = (wretch: Http) => {
	const mutation = async (params: { body: playerMigrationConflict_Body }) => {
		const args = z.object({ body: z.any().optional() }).parse(params);
		const { body } = args;

		try {
			const res = await wretch
				.url("/admin/api/playerMigrationConflict")
				.json(body)
				.post()
				.res();
			const json = await res.json();
			switch (res.status) {
				case 200: {
					const data = playerMigrationConflictResponse200.parse(json);
					return {
						status: res.status,
						data,
						headers: res.headers,
						url: res.url,
					};
				}
			}
		} catch (e) {
			const err = error(e);
			throw err;
		}

		invariant(false);
	};

	return {
		mutation,
		useMutation: (
			options?: Omit<
				UseMutationOptions<
					ApiResponse<playerMigrationConflictResponse200> | undefined,
					GenericWretchError<null>,
					{ body: playerMigrationConflict_Body }
				>,
				"mutationFn"
			>,
		) => {
			return useMutation<
				ApiResponse<playerMigrationConflictResponse200> | undefined,
				GenericWretchError<null>,
				{ body: playerMigrationConflict_Body }
			>(mutation, options || {});
		},
	};
};

const getAllCountriesTooltip = (wretch: Http, queryClient: QueryClient) => {
	const queryFn = async (
		params: {
			start: number;
			length: number;
			search?: string;
			country?: string;
			enabled?: string;
		},
		signal?: AbortSignal | undefined,
	): Promise<ApiResponse<getAllCountriesTooltipResponse200>> => {
		const args = z
			.object({
				start: z.number().int(),
				length: z.number().int(),
				search: z.string().optional(),
				country: z.string().optional(),
				enabled: z.string().optional(),
			})
			.parse(params);
		const { start, length, search, country, enabled } = args;
		try {
			const res = await wretch
				.options({ signal })
				.url("/admin/api/countries/tooltip")
				.query(qs.stringify({ start, length, search, country, enabled }))
				.get()
				.res();
			const json = await res.json();
			switch (res.status) {
				case 200: {
					const data = getAllCountriesTooltipResponse200.parse(json);
					return {
						status: res.status,
						data,
						headers: res.headers,
						url: res.url,
					};
				}
			}
		} catch (e) {
			const err = error(e);
			const json = err.json;
			switch (err.status) {
				case 403: {
					err.json = getAllCountriesTooltipResponse403.parse(json);
					break;
				}
			}
			throw err;
		}

		invariant(false);
	};

	return {
		query: queryFn,
		useQuery: (
			params: {
				start: number;
				length: number;
				search?: string;
				country?: string;
				enabled?: string;
			},
			options?: Omit<
				UseQueryOptions<
					ApiResponse<getAllCountriesTooltipResponse200>,
					GenericWretchError<getAllCountriesTooltipResponse403>
				>,
				"queryFn"
			>,
		): UseQueryResult<
			ApiResponse<getAllCountriesTooltipResponse200>,
			GenericWretchError<getAllCountriesTooltipResponse403>
		> => {
			const key = [
				"getAllCountriesTooltip",
				params,
				...(options?.queryKey || []),
			];
			const query: QueryFunction<
				ApiResponse<getAllCountriesTooltipResponse200>
			> = async ({ meta, queryKey, pageParam, signal }) => {
				return queryFn(params, signal);
			};
			const invalidate = () => queryClient.invalidateQueries(key);
			const queryResult = useQuery<
				ApiResponse<getAllCountriesTooltipResponse200>,
				GenericWretchError<getAllCountriesTooltipResponse403>
			>(key, query, options || {}) as UseQueryResult<
				ApiResponse<getAllCountriesTooltipResponse200>,
				GenericWretchError<getAllCountriesTooltipResponse403>
			>;
			queryResult.invalidate = invalidate;
			queryResult.key = key;
			return queryResult;
		},
		useInfiniteQuery: (
			params: {
				start: number;
				length: number;
				search?: string;
				country?: string;
				enabled?: string;
			},
			options?: Omit<
				UseInfiniteQueryOptions<
					ApiResponse<getAllCountriesTooltipResponse200>,
					GenericWretchError<getAllCountriesTooltipResponse403>
				>,
				"queryFn"
			>,
		): UseInfiniteQueryResult<
			ApiResponse<getAllCountriesTooltipResponse200>,
			GenericWretchError<getAllCountriesTooltipResponse403>
		> => {
			const key = [
				"getAllCountriesTooltip",
				params,
				...(options?.queryKey || []),
			];
			const query: QueryFunction<
				ApiResponse<getAllCountriesTooltipResponse200>
			> = async ({ meta, queryKey, pageParam, signal }) => {
				return queryFn(params, signal);
			};
			const invalidate = () => queryClient.invalidateQueries(key);
			const queryResult = useInfiniteQuery<
				ApiResponse<getAllCountriesTooltipResponse200>,
				GenericWretchError<getAllCountriesTooltipResponse403>
			>(key, query, options || {}) as UseInfiniteQueryResult<
				ApiResponse<getAllCountriesTooltipResponse200>,
				GenericWretchError<getAllCountriesTooltipResponse403>
			>;
			queryResult.invalidate = invalidate;
			queryResult.key = key;
			return queryResult;
		},
	};
};

const addUpdateCountry = (wretch: Http) => {
	const mutation = async (params: { body: addUpdateCountry_Body }) => {
		const args = z.object({ body: z.any() }).parse(params);
		const { body } = args;

		try {
			const res = await wretch
				.url("/admin/api/countries")
				.json(body)
				.post()
				.res();
			const json = await res.json();
			switch (res.status) {
				case 200: {
					const data = addUpdateCountryResponse200.parse(json);
					return {
						status: res.status,
						data,
						headers: res.headers,
						url: res.url,
					};
				}
			}
		} catch (e) {
			const err = error(e);
			const json = err.json;
			switch (err.status) {
				case 403: {
					err.json = addUpdateCountryResponse403.parse(json);
					break;
				}
			}
			throw err;
		}

		invariant(false);
	};

	return {
		mutation,
		useMutation: (
			options?: Omit<
				UseMutationOptions<
					ApiResponse<addUpdateCountryResponse200> | undefined,
					GenericWretchError<addUpdateCountryResponse403>,
					{ body: addUpdateCountry_Body }
				>,
				"mutationFn"
			>,
		) => {
			return useMutation<
				ApiResponse<addUpdateCountryResponse200> | undefined,
				GenericWretchError<addUpdateCountryResponse403>,
				{ body: addUpdateCountry_Body }
			>(mutation, options || {});
		},
	};
};

const deleteCountry = (wretch: Http) => {
	const mutation = async (params: { id: number }) => {
		const args = z.object({ id: z.number().int() }).parse(params);
		const { id } = args;

		try {
			const res = await wretch.url(`/admin/api/countries/${id}`).delete().res();
			const json = await res.json();
			switch (res.status) {
				case 200: {
					const data = deleteCountryResponse200.parse(json);
					return {
						status: res.status,
						data,
						headers: res.headers,
						url: res.url,
					};
				}
			}
		} catch (e) {
			const err = error(e);
			const json = err.json;
			switch (err.status) {
				case 400: {
					err.json = deleteCountryResponse400.parse(json);
					break;
				}
				case 403: {
					err.json = deleteCountryResponse403.parse(json);
					break;
				}
			}
			throw err;
		}

		invariant(false);
	};

	return {
		mutation,
		useMutation: (
			options?: Omit<
				UseMutationOptions<
					ApiResponse<deleteCountryResponse200> | undefined,
					GenericWretchError<
						deleteCountryResponse400 | deleteCountryResponse403
					>,
					{ id: number }
				>,
				"mutationFn"
			>,
		) => {
			return useMutation<
				ApiResponse<deleteCountryResponse200> | undefined,
				GenericWretchError<deleteCountryResponse400 | deleteCountryResponse403>,
				{ id: number }
			>(mutation, options || {});
		},
	};
};

const allCountries = (wretch: Http, queryClient: QueryClient) => {
	const queryFn = async (
		signal?: AbortSignal | undefined,
	): Promise<ApiResponse<allCountriesResponse200>> => {
		try {
			const res = await wretch
				.options({ signal })
				.url("/admin/api/all-countries")
				.get()
				.res();
			const json = await res.json();
			switch (res.status) {
				case 200: {
					const data = allCountriesResponse200.parse(json);
					return {
						status: res.status,
						data,
						headers: res.headers,
						url: res.url,
					};
				}
			}
		} catch (e) {
			const err = error(e);
			const json = err.json;
			switch (err.status) {
				case 403: {
					err.json = allCountriesResponse403.parse(json);
					break;
				}
			}
			throw err;
		}

		invariant(false);
	};

	return {
		query: queryFn,
		useQuery: (
			options?: Omit<
				UseQueryOptions<
					ApiResponse<allCountriesResponse200>,
					GenericWretchError<allCountriesResponse403>
				>,
				"queryFn"
			>,
		): UseQueryResult<
			ApiResponse<allCountriesResponse200>,
			GenericWretchError<allCountriesResponse403>
		> => {
			const key = ["allCountries", ...(options?.queryKey || [])];
			const query: QueryFunction<ApiResponse<allCountriesResponse200>> =
				async ({ meta, queryKey, pageParam, signal }) => {
					return queryFn(signal);
				};
			const invalidate = () => queryClient.invalidateQueries(key);
			const queryResult = useQuery<
				ApiResponse<allCountriesResponse200>,
				GenericWretchError<allCountriesResponse403>
			>(key, query, options || {}) as UseQueryResult<
				ApiResponse<allCountriesResponse200>,
				GenericWretchError<allCountriesResponse403>
			>;
			queryResult.invalidate = invalidate;
			queryResult.key = key;
			return queryResult;
		},
		useInfiniteQuery: (
			options?: Omit<
				UseInfiniteQueryOptions<
					ApiResponse<allCountriesResponse200>,
					GenericWretchError<allCountriesResponse403>
				>,
				"queryFn"
			>,
		): UseInfiniteQueryResult<
			ApiResponse<allCountriesResponse200>,
			GenericWretchError<allCountriesResponse403>
		> => {
			const key = ["allCountries", ...(options?.queryKey || [])];
			const query: QueryFunction<ApiResponse<allCountriesResponse200>> =
				async ({ meta, queryKey, pageParam, signal }) => {
					return queryFn(signal);
				};
			const invalidate = () => queryClient.invalidateQueries(key);
			const queryResult = useInfiniteQuery<
				ApiResponse<allCountriesResponse200>,
				GenericWretchError<allCountriesResponse403>
			>(key, query, options || {}) as UseInfiniteQueryResult<
				ApiResponse<allCountriesResponse200>,
				GenericWretchError<allCountriesResponse403>
			>;
			queryResult.invalidate = invalidate;
			queryResult.key = key;
			return queryResult;
		},
	};
};

const enableDisableCountry = (wretch: Http) => {
	const mutation = async (params: {
		id: number;
		body: enableDisableCountryRequestBody;
	}) => {
		const args = z
			.object({ id: z.number().int(), body: z.any() })
			.parse(params);
		const { id, body } = args;

		try {
			const res = await wretch
				.url(`/admin/api/countries/${id}/activate`)
				.json(body)
				.put()
				.res();
			const json = await res.json();
			switch (res.status) {
				case 200: {
					const data = enableDisableCountryResponse200.parse(json);
					return {
						status: res.status,
						data,
						headers: res.headers,
						url: res.url,
					};
				}
			}
		} catch (e) {
			const err = error(e);
			const json = err.json;
			switch (err.status) {
				case 403: {
					err.json = enableDisableCountryResponse403.parse(json);
					break;
				}
			}
			throw err;
		}

		invariant(false);
	};

	return {
		mutation,
		useMutation: (
			options?: Omit<
				UseMutationOptions<
					ApiResponse<enableDisableCountryResponse200> | undefined,
					GenericWretchError<enableDisableCountryResponse403>,
					{ id: number; body: enableDisableCountryRequestBody }
				>,
				"mutationFn"
			>,
		) => {
			return useMutation<
				ApiResponse<enableDisableCountryResponse200> | undefined,
				GenericWretchError<enableDisableCountryResponse403>,
				{ id: number; body: enableDisableCountryRequestBody }
			>(mutation, options || {});
		},
	};
};

const loadSubscriptionData = (wretch: Http) => {
	const mutation = async (params: { body: SubscriptionRequest }) => {
		const args = z.object({ body: z.any() }).parse(params);
		const { body } = args;

		try {
			const res = await wretch
				.url("/admin/api/subscriptions")
				.json(body)
				.post()
				.res();
			const json = await res.json();
			switch (res.status) {
				case 200: {
					const data = loadSubscriptionDataResponse200.parse(json);
					return {
						status: res.status,
						data,
						headers: res.headers,
						url: res.url,
					};
				}
			}
		} catch (e) {
			const err = error(e);
			const json = err.json;
			switch (err.status) {
				case 403: {
					err.json = loadSubscriptionDataResponse403.parse(json);
					break;
				}
			}
			throw err;
		}

		invariant(false);
	};

	return {
		mutation,
		useMutation: (
			options?: Omit<
				UseMutationOptions<
					ApiResponse<loadSubscriptionDataResponse200> | undefined,
					GenericWretchError<loadSubscriptionDataResponse403>,
					{ body: SubscriptionRequest }
				>,
				"mutationFn"
			>,
		) => {
			return useMutation<
				ApiResponse<loadSubscriptionDataResponse200> | undefined,
				GenericWretchError<loadSubscriptionDataResponse403>,
				{ body: SubscriptionRequest }
			>(mutation, options || {});
		},
	};
};

const loadOwnerships = (wretch: Http, queryClient: QueryClient) => {
	const queryFn = async (
		signal?: AbortSignal | undefined,
	): Promise<ApiResponse<loadOwnershipsResponse200>> => {
		try {
			const res = await wretch
				.options({ signal })
				.url("/admin/api/ownerships")
				.get()
				.res();
			const json = await res.json();
			switch (res.status) {
				case 200: {
					const data = loadOwnershipsResponse200.parse(json);
					return {
						status: res.status,
						data,
						headers: res.headers,
						url: res.url,
					};
				}
			}
		} catch (e) {
			const err = error(e);
			const json = err.json;
			switch (err.status) {
				case 403: {
					err.json = loadOwnershipsResponse403.parse(json);
					break;
				}
			}
			throw err;
		}

		invariant(false);
	};

	return {
		query: queryFn,
		useQuery: (
			options?: Omit<
				UseQueryOptions<
					ApiResponse<loadOwnershipsResponse200>,
					GenericWretchError<loadOwnershipsResponse403>
				>,
				"queryFn"
			>,
		): UseQueryResult<
			ApiResponse<loadOwnershipsResponse200>,
			GenericWretchError<loadOwnershipsResponse403>
		> => {
			const key = ["loadOwnerships", ...(options?.queryKey || [])];
			const query: QueryFunction<ApiResponse<loadOwnershipsResponse200>> =
				async ({ meta, queryKey, pageParam, signal }) => {
					return queryFn(signal);
				};
			const invalidate = () => queryClient.invalidateQueries(key);
			const queryResult = useQuery<
				ApiResponse<loadOwnershipsResponse200>,
				GenericWretchError<loadOwnershipsResponse403>
			>(key, query, options || {}) as UseQueryResult<
				ApiResponse<loadOwnershipsResponse200>,
				GenericWretchError<loadOwnershipsResponse403>
			>;
			queryResult.invalidate = invalidate;
			queryResult.key = key;
			return queryResult;
		},
		useInfiniteQuery: (
			options?: Omit<
				UseInfiniteQueryOptions<
					ApiResponse<loadOwnershipsResponse200>,
					GenericWretchError<loadOwnershipsResponse403>
				>,
				"queryFn"
			>,
		): UseInfiniteQueryResult<
			ApiResponse<loadOwnershipsResponse200>,
			GenericWretchError<loadOwnershipsResponse403>
		> => {
			const key = ["loadOwnerships", ...(options?.queryKey || [])];
			const query: QueryFunction<ApiResponse<loadOwnershipsResponse200>> =
				async ({ meta, queryKey, pageParam, signal }) => {
					return queryFn(signal);
				};
			const invalidate = () => queryClient.invalidateQueries(key);
			const queryResult = useInfiniteQuery<
				ApiResponse<loadOwnershipsResponse200>,
				GenericWretchError<loadOwnershipsResponse403>
			>(key, query, options || {}) as UseInfiniteQueryResult<
				ApiResponse<loadOwnershipsResponse200>,
				GenericWretchError<loadOwnershipsResponse403>
			>;
			queryResult.invalidate = invalidate;
			queryResult.key = key;
			return queryResult;
		},
	};
};

const getSubscriptionFilterData = (wretch: Http, queryClient: QueryClient) => {
	const queryFn = async (
		signal?: AbortSignal | undefined,
	): Promise<ApiResponse<getSubscriptionFilterDataResponse200>> => {
		try {
			const res = await wretch
				.options({ signal })
				.url("/admin/api/subscriptionsFilters")
				.get()
				.res();
			const json = await res.json();
			switch (res.status) {
				case 200: {
					const data = getSubscriptionFilterDataResponse200.parse(json);
					return {
						status: res.status,
						data,
						headers: res.headers,
						url: res.url,
					};
				}
			}
		} catch (e) {
			const err = error(e);
			const json = err.json;
			switch (err.status) {
				case 403: {
					err.json = getSubscriptionFilterDataResponse403.parse(json);
					break;
				}
			}
			throw err;
		}

		invariant(false);
	};

	return {
		query: queryFn,
		useQuery: (
			options?: Omit<
				UseQueryOptions<
					ApiResponse<getSubscriptionFilterDataResponse200>,
					GenericWretchError<getSubscriptionFilterDataResponse403>
				>,
				"queryFn"
			>,
		): UseQueryResult<
			ApiResponse<getSubscriptionFilterDataResponse200>,
			GenericWretchError<getSubscriptionFilterDataResponse403>
		> => {
			const key = ["getSubscriptionFilterData", ...(options?.queryKey || [])];
			const query: QueryFunction<
				ApiResponse<getSubscriptionFilterDataResponse200>
			> = async ({ meta, queryKey, pageParam, signal }) => {
				return queryFn(signal);
			};
			const invalidate = () => queryClient.invalidateQueries(key);
			const queryResult = useQuery<
				ApiResponse<getSubscriptionFilterDataResponse200>,
				GenericWretchError<getSubscriptionFilterDataResponse403>
			>(key, query, options || {}) as UseQueryResult<
				ApiResponse<getSubscriptionFilterDataResponse200>,
				GenericWretchError<getSubscriptionFilterDataResponse403>
			>;
			queryResult.invalidate = invalidate;
			queryResult.key = key;
			return queryResult;
		},
		useInfiniteQuery: (
			options?: Omit<
				UseInfiniteQueryOptions<
					ApiResponse<getSubscriptionFilterDataResponse200>,
					GenericWretchError<getSubscriptionFilterDataResponse403>
				>,
				"queryFn"
			>,
		): UseInfiniteQueryResult<
			ApiResponse<getSubscriptionFilterDataResponse200>,
			GenericWretchError<getSubscriptionFilterDataResponse403>
		> => {
			const key = ["getSubscriptionFilterData", ...(options?.queryKey || [])];
			const query: QueryFunction<
				ApiResponse<getSubscriptionFilterDataResponse200>
			> = async ({ meta, queryKey, pageParam, signal }) => {
				return queryFn(signal);
			};
			const invalidate = () => queryClient.invalidateQueries(key);
			const queryResult = useInfiniteQuery<
				ApiResponse<getSubscriptionFilterDataResponse200>,
				GenericWretchError<getSubscriptionFilterDataResponse403>
			>(key, query, options || {}) as UseInfiniteQueryResult<
				ApiResponse<getSubscriptionFilterDataResponse200>,
				GenericWretchError<getSubscriptionFilterDataResponse403>
			>;
			queryResult.invalidate = invalidate;
			queryResult.key = key;
			return queryResult;
		},
	};
};

const addUpdateSubscription = (wretch: Http) => {
	const mutation = async (params: { body: addUpdateSubscription_Body }) => {
		const args = z.object({ body: z.any() }).parse(params);
		const { body } = args;

		try {
			const res = await wretch
				.url("/admin/api/addUpdateSubscription")
				.json(body)
				.post()
				.res();
			const json = await res.json();
			switch (res.status) {
				case 200: {
					const data = addUpdateSubscriptionResponse200.parse(json);
					return {
						status: res.status,
						data,
						headers: res.headers,
						url: res.url,
					};
				}
			}
		} catch (e) {
			const err = error(e);
			const json = err.json;
			switch (err.status) {
				case 403: {
					err.json = addUpdateSubscriptionResponse403.parse(json);
					break;
				}
			}
			throw err;
		}

		invariant(false);
	};

	return {
		mutation,
		useMutation: (
			options?: Omit<
				UseMutationOptions<
					ApiResponse<addUpdateSubscriptionResponse200> | undefined,
					GenericWretchError<addUpdateSubscriptionResponse403>,
					{ body: addUpdateSubscription_Body }
				>,
				"mutationFn"
			>,
		) => {
			return useMutation<
				ApiResponse<addUpdateSubscriptionResponse200> | undefined,
				GenericWretchError<addUpdateSubscriptionResponse403>,
				{ body: addUpdateSubscription_Body }
			>(mutation, options || {});
		},
	};
};

const assignSubscriptionToClub = (wretch: Http) => {
	const mutation = async (params: {
		clubId: number;
		subscriptionId: number;
		unAssign?: number;
	}) => {
		const args = z
			.object({
				clubId: z.number().int(),
				subscriptionId: z.number().int(),
				unAssign: z.number().int().optional(),
			})
			.parse(params);
		const { clubId, subscriptionId, unAssign } = args;

		try {
			const res = await wretch
				.url("/admin/api/assignSubscriptionToClub")
				.query(qs.stringify({ clubId, subscriptionId, unAssign }))
				.put()
				.res();
			const json = await res.json();
			switch (res.status) {
				case 200: {
					const data = assignSubscriptionToClubResponse200.parse(json);
					return {
						status: res.status,
						data,
						headers: res.headers,
						url: res.url,
					};
				}
			}
		} catch (e) {
			const err = error(e);
			const json = err.json;
			switch (err.status) {
				case 403: {
					err.json = assignSubscriptionToClubResponse403.parse(json);
					break;
				}
			}
			throw err;
		}

		invariant(false);
	};

	return {
		mutation,
		useMutation: (
			options?: Omit<
				UseMutationOptions<
					ApiResponse<assignSubscriptionToClubResponse200> | undefined,
					GenericWretchError<assignSubscriptionToClubResponse403>,
					{ clubId: number; subscriptionId: number; unAssign?: number }
				>,
				"mutationFn"
			>,
		) => {
			return useMutation<
				ApiResponse<assignSubscriptionToClubResponse200> | undefined,
				GenericWretchError<assignSubscriptionToClubResponse403>,
				{ clubId: number; subscriptionId: number; unAssign?: number }
			>(mutation, options || {});
		},
	};
};

const deleteSubscription = (wretch: Http) => {
	const mutation = async (params: { id: number }) => {
		const args = z.object({ id: z.number().int() }).parse(params);
		const { id } = args;

		try {
			const res = await wretch
				.url(`/admin/api/subscription/${id}`)
				.delete()
				.res();
			const json = await res.json();
			switch (res.status) {
				case 200: {
					const data = deleteSubscriptionResponse200.parse(json);
					return {
						status: res.status,
						data,
						headers: res.headers,
						url: res.url,
					};
				}
			}
		} catch (e) {
			const err = error(e);
			const json = err.json;
			switch (err.status) {
				case 403: {
					err.json = deleteSubscriptionResponse403.parse(json);
					break;
				}
			}
			throw err;
		}

		invariant(false);
	};

	return {
		mutation,
		useMutation: (
			options?: Omit<
				UseMutationOptions<
					ApiResponse<deleteSubscriptionResponse200> | undefined,
					GenericWretchError<deleteSubscriptionResponse403>,
					{ id: number }
				>,
				"mutationFn"
			>,
		) => {
			return useMutation<
				ApiResponse<deleteSubscriptionResponse200> | undefined,
				GenericWretchError<deleteSubscriptionResponse403>,
				{ id: number }
			>(mutation, options || {});
		},
	};
};

const getAllSpecificGenericNotifications = (
	wretch: Http,
	queryClient: QueryClient,
) => {
	const queryFn = async (
		params: { start: number; length: number; search?: string },
		signal?: AbortSignal | undefined,
	): Promise<ApiResponse<getAllSpecificGenericNotificationsResponse200>> => {
		const args = z
			.object({
				start: z.number().int(),
				length: z.number().int(),
				search: z.string().optional(),
			})
			.parse(params);
		const { start, length, search } = args;
		try {
			const res = await wretch
				.options({ signal })
				.url("/admin/api/specific-generic-notifications")
				.query(qs.stringify({ start, length, search }))
				.get()
				.res();
			const json = await res.json();
			switch (res.status) {
				case 200: {
					const data =
						getAllSpecificGenericNotificationsResponse200.parse(json);
					return {
						status: res.status,
						data,
						headers: res.headers,
						url: res.url,
					};
				}
			}
		} catch (e) {
			const err = error(e);
			const json = err.json;
			switch (err.status) {
				case 403: {
					err.json = getAllSpecificGenericNotificationsResponse403.parse(json);
					break;
				}
			}
			throw err;
		}

		invariant(false);
	};

	return {
		query: queryFn,
		useQuery: (
			params: { start: number; length: number; search?: string },
			options?: Omit<
				UseQueryOptions<
					ApiResponse<getAllSpecificGenericNotificationsResponse200>,
					GenericWretchError<getAllSpecificGenericNotificationsResponse403>
				>,
				"queryFn"
			>,
		): UseQueryResult<
			ApiResponse<getAllSpecificGenericNotificationsResponse200>,
			GenericWretchError<getAllSpecificGenericNotificationsResponse403>
		> => {
			const key = [
				"getAllSpecificGenericNotifications",
				params,
				...(options?.queryKey || []),
			];
			const query: QueryFunction<
				ApiResponse<getAllSpecificGenericNotificationsResponse200>
			> = async ({ meta, queryKey, pageParam, signal }) => {
				return queryFn(params, signal);
			};
			const invalidate = () => queryClient.invalidateQueries(key);
			const queryResult = useQuery<
				ApiResponse<getAllSpecificGenericNotificationsResponse200>,
				GenericWretchError<getAllSpecificGenericNotificationsResponse403>
			>(key, query, options || {}) as UseQueryResult<
				ApiResponse<getAllSpecificGenericNotificationsResponse200>,
				GenericWretchError<getAllSpecificGenericNotificationsResponse403>
			>;
			queryResult.invalidate = invalidate;
			queryResult.key = key;
			return queryResult;
		},
		useInfiniteQuery: (
			params: { start: number; length: number; search?: string },
			options?: Omit<
				UseInfiniteQueryOptions<
					ApiResponse<getAllSpecificGenericNotificationsResponse200>,
					GenericWretchError<getAllSpecificGenericNotificationsResponse403>
				>,
				"queryFn"
			>,
		): UseInfiniteQueryResult<
			ApiResponse<getAllSpecificGenericNotificationsResponse200>,
			GenericWretchError<getAllSpecificGenericNotificationsResponse403>
		> => {
			const key = [
				"getAllSpecificGenericNotifications",
				params,
				...(options?.queryKey || []),
			];
			const query: QueryFunction<
				ApiResponse<getAllSpecificGenericNotificationsResponse200>
			> = async ({ meta, queryKey, pageParam, signal }) => {
				return queryFn(params, signal);
			};
			const invalidate = () => queryClient.invalidateQueries(key);
			const queryResult = useInfiniteQuery<
				ApiResponse<getAllSpecificGenericNotificationsResponse200>,
				GenericWretchError<getAllSpecificGenericNotificationsResponse403>
			>(key, query, options || {}) as UseInfiniteQueryResult<
				ApiResponse<getAllSpecificGenericNotificationsResponse200>,
				GenericWretchError<getAllSpecificGenericNotificationsResponse403>
			>;
			queryResult.invalidate = invalidate;
			queryResult.key = key;
			return queryResult;
		},
	};
};

const getNotificationInstants = (wretch: Http, queryClient: QueryClient) => {
	const queryFn = async (
		params: { jobId: number; start: number; length: number; search?: string },
		signal?: AbortSignal | undefined,
	): Promise<ApiResponse<getNotificationInstantsResponse200>> => {
		const args = z
			.object({
				jobId: z.number().int(),
				start: z.number().int(),
				length: z.number().int(),
				search: z.string().optional(),
			})
			.parse(params);
		const { jobId, start, length, search } = args;
		try {
			const res = await wretch
				.options({ signal })
				.url(`/admin/api/notification-instants/${jobId}`)
				.query(qs.stringify({ start, length, search }))
				.get()
				.res();
			const json = await res.json();
			switch (res.status) {
				case 200: {
					const data = getNotificationInstantsResponse200.parse(json);
					return {
						status: res.status,
						data,
						headers: res.headers,
						url: res.url,
					};
				}
			}
		} catch (e) {
			const err = error(e);
			const json = err.json;
			switch (err.status) {
				case 403: {
					err.json = getNotificationInstantsResponse403.parse(json);
					break;
				}
			}
			throw err;
		}

		invariant(false);
	};

	return {
		query: queryFn,
		useQuery: (
			params: { jobId: number; start: number; length: number; search?: string },
			options?: Omit<
				UseQueryOptions<
					ApiResponse<getNotificationInstantsResponse200>,
					GenericWretchError<getNotificationInstantsResponse403>
				>,
				"queryFn"
			>,
		): UseQueryResult<
			ApiResponse<getNotificationInstantsResponse200>,
			GenericWretchError<getNotificationInstantsResponse403>
		> => {
			const key = [
				"getNotificationInstants",
				params,
				...(options?.queryKey || []),
			];
			const query: QueryFunction<
				ApiResponse<getNotificationInstantsResponse200>
			> = async ({ meta, queryKey, pageParam, signal }) => {
				return queryFn(params, signal);
			};
			const invalidate = () => queryClient.invalidateQueries(key);
			const queryResult = useQuery<
				ApiResponse<getNotificationInstantsResponse200>,
				GenericWretchError<getNotificationInstantsResponse403>
			>(key, query, options || {}) as UseQueryResult<
				ApiResponse<getNotificationInstantsResponse200>,
				GenericWretchError<getNotificationInstantsResponse403>
			>;
			queryResult.invalidate = invalidate;
			queryResult.key = key;
			return queryResult;
		},
		useInfiniteQuery: (
			params: { jobId: number; start: number; length: number; search?: string },
			options?: Omit<
				UseInfiniteQueryOptions<
					ApiResponse<getNotificationInstantsResponse200>,
					GenericWretchError<getNotificationInstantsResponse403>
				>,
				"queryFn"
			>,
		): UseInfiniteQueryResult<
			ApiResponse<getNotificationInstantsResponse200>,
			GenericWretchError<getNotificationInstantsResponse403>
		> => {
			const key = [
				"getNotificationInstants",
				params,
				...(options?.queryKey || []),
			];
			const query: QueryFunction<
				ApiResponse<getNotificationInstantsResponse200>
			> = async ({ meta, queryKey, pageParam, signal }) => {
				return queryFn(params, signal);
			};
			const invalidate = () => queryClient.invalidateQueries(key);
			const queryResult = useInfiniteQuery<
				ApiResponse<getNotificationInstantsResponse200>,
				GenericWretchError<getNotificationInstantsResponse403>
			>(key, query, options || {}) as UseInfiniteQueryResult<
				ApiResponse<getNotificationInstantsResponse200>,
				GenericWretchError<getNotificationInstantsResponse403>
			>;
			queryResult.invalidate = invalidate;
			queryResult.key = key;
			return queryResult;
		},
	};
};

const retryFailedNotification = (wretch: Http, queryClient: QueryClient) => {
	const queryFn = async (
		params: { jobId: number },
		signal?: AbortSignal | undefined,
	): Promise<ApiResponse<retryFailedNotificationResponse200>> => {
		const args = z.object({ jobId: z.number().int() }).parse(params);
		const { jobId } = args;
		try {
			const res = await wretch
				.options({ signal })
				.url(`/admin/api/retry-failed/${jobId}`)
				.get()
				.res();
			const json = await res.json();
			switch (res.status) {
				case 200: {
					const data = retryFailedNotificationResponse200.parse(json);
					return {
						status: res.status,
						data,
						headers: res.headers,
						url: res.url,
					};
				}
			}
		} catch (e) {
			const err = error(e);
			const json = err.json;
			switch (err.status) {
				case 400: {
					err.json = retryFailedNotificationResponse400.parse(json);
					break;
				}
				case 403: {
					err.json = retryFailedNotificationResponse403.parse(json);
					break;
				}
			}
			throw err;
		}

		invariant(false);
	};

	return {
		query: queryFn,
		useQuery: (
			params: { jobId: number },
			options?: Omit<
				UseQueryOptions<
					ApiResponse<retryFailedNotificationResponse200>,
					GenericWretchError<
						| retryFailedNotificationResponse400
						| retryFailedNotificationResponse403
					>
				>,
				"queryFn"
			>,
		): UseQueryResult<
			ApiResponse<retryFailedNotificationResponse200>,
			GenericWretchError<
				retryFailedNotificationResponse400 | retryFailedNotificationResponse403
			>
		> => {
			const key = [
				"retryFailedNotification",
				params,
				...(options?.queryKey || []),
			];
			const query: QueryFunction<
				ApiResponse<retryFailedNotificationResponse200>
			> = async ({ meta, queryKey, pageParam, signal }) => {
				return queryFn(params, signal);
			};
			const invalidate = () => queryClient.invalidateQueries(key);
			const queryResult = useQuery<
				ApiResponse<retryFailedNotificationResponse200>,
				GenericWretchError<
					| retryFailedNotificationResponse400
					| retryFailedNotificationResponse403
				>
			>(key, query, options || {}) as UseQueryResult<
				ApiResponse<retryFailedNotificationResponse200>,
				GenericWretchError<
					| retryFailedNotificationResponse400
					| retryFailedNotificationResponse403
				>
			>;
			queryResult.invalidate = invalidate;
			queryResult.key = key;
			return queryResult;
		},
		useInfiniteQuery: (
			params: { jobId: number },
			options?: Omit<
				UseInfiniteQueryOptions<
					ApiResponse<retryFailedNotificationResponse200>,
					GenericWretchError<
						| retryFailedNotificationResponse400
						| retryFailedNotificationResponse403
					>
				>,
				"queryFn"
			>,
		): UseInfiniteQueryResult<
			ApiResponse<retryFailedNotificationResponse200>,
			GenericWretchError<
				retryFailedNotificationResponse400 | retryFailedNotificationResponse403
			>
		> => {
			const key = [
				"retryFailedNotification",
				params,
				...(options?.queryKey || []),
			];
			const query: QueryFunction<
				ApiResponse<retryFailedNotificationResponse200>
			> = async ({ meta, queryKey, pageParam, signal }) => {
				return queryFn(params, signal);
			};
			const invalidate = () => queryClient.invalidateQueries(key);
			const queryResult = useInfiniteQuery<
				ApiResponse<retryFailedNotificationResponse200>,
				GenericWretchError<
					| retryFailedNotificationResponse400
					| retryFailedNotificationResponse403
				>
			>(key, query, options || {}) as UseInfiniteQueryResult<
				ApiResponse<retryFailedNotificationResponse200>,
				GenericWretchError<
					| retryFailedNotificationResponse400
					| retryFailedNotificationResponse403
				>
			>;
			queryResult.invalidate = invalidate;
			queryResult.key = key;
			return queryResult;
		},
	};
};

const addSpecificGenericNotification = (wretch: Http) => {
	const mutation = async (params: {
		body: addSpecificGenericNotification_Body;
	}) => {
		const args = z.object({ body: z.any() }).parse(params);
		const { body } = args;

		try {
			const res = await wretch
				.url("/admin/api/specific-generic-notification")
				.json(body)
				.post()
				.res();
			const json = await res.json();
			switch (res.status) {
				case 200: {
					const data = addSpecificGenericNotificationResponse200.parse(json);
					return {
						status: res.status,
						data,
						headers: res.headers,
						url: res.url,
					};
				}
			}
		} catch (e) {
			const err = error(e);
			const json = err.json;
			switch (err.status) {
				case 403: {
					err.json = addSpecificGenericNotificationResponse403.parse(json);
					break;
				}
			}
			throw err;
		}

		invariant(false);
	};

	return {
		mutation,
		useMutation: (
			options?: Omit<
				UseMutationOptions<
					ApiResponse<addSpecificGenericNotificationResponse200> | undefined,
					GenericWretchError<
						| addSpecificGenericNotificationResponse403
						| addSpecificGenericNotificationResponse500
					>,
					{ body: addSpecificGenericNotification_Body }
				>,
				"mutationFn"
			>,
		) => {
			return useMutation<
				ApiResponse<addSpecificGenericNotificationResponse200> | undefined,
				GenericWretchError<
					| addSpecificGenericNotificationResponse403
					| addSpecificGenericNotificationResponse500
				>,
				{ body: addSpecificGenericNotification_Body }
			>(mutation, options || {});
		},
	};
};

const testSpecificGenericNotification = (wretch: Http) => {
	const mutation = async (params: {
		body: addSpecificGenericNotification_Body;
	}) => {
		const args = z.object({ body: z.any() }).parse(params);
		const { body } = args;

		try {
			const res = await wretch
				.url("/admin/api/test-specific-generic-notification")
				.json(body)
				.post()
				.res();
			const json = await res.json();
			switch (res.status) {
				case 200: {
					const data = testSpecificGenericNotificationResponse200.parse(json);
					return {
						status: res.status,
						data,
						headers: res.headers,
						url: res.url,
					};
				}
			}
		} catch (e) {
			const err = error(e);
			const json = err.json;
			switch (err.status) {
				case 403: {
					err.json = testSpecificGenericNotificationResponse403.parse(json);
					break;
				}
			}
			throw err;
		}

		invariant(false);
	};

	return {
		mutation,
		useMutation: (
			options?: Omit<
				UseMutationOptions<
					ApiResponse<testSpecificGenericNotificationResponse200> | undefined,
					GenericWretchError<
						| testSpecificGenericNotificationResponse403
						| testSpecificGenericNotificationResponse500
					>,
					{ body: addSpecificGenericNotification_Body }
				>,
				"mutationFn"
			>,
		) => {
			return useMutation<
				ApiResponse<testSpecificGenericNotificationResponse200> | undefined,
				GenericWretchError<
					| testSpecificGenericNotificationResponse403
					| testSpecificGenericNotificationResponse500
				>,
				{ body: addSpecificGenericNotification_Body }
			>(mutation, options || {});
		},
	};
};

const refreshJobNotification = (wretch: Http, queryClient: QueryClient) => {
	const queryFn = async (
		params: { jobId: number },
		signal?: AbortSignal | undefined,
	): Promise<ApiResponse<refreshJobNotificationResponse200>> => {
		const args = z.object({ jobId: z.number().int() }).parse(params);
		const { jobId } = args;
		try {
			const res = await wretch
				.options({ signal })
				.url(`/admin/api/refresh-job/${jobId}`)
				.get()
				.res();
			const json = await res.json();
			switch (res.status) {
				case 200: {
					const data = refreshJobNotificationResponse200.parse(json);
					return {
						status: res.status,
						data,
						headers: res.headers,
						url: res.url,
					};
				}
			}
		} catch (e) {
			const err = error(e);
			const json = err.json;
			switch (err.status) {
				case 400: {
					err.json = refreshJobNotificationResponse400.parse(json);
					break;
				}
				case 403: {
					err.json = refreshJobNotificationResponse403.parse(json);
					break;
				}
			}
			throw err;
		}

		invariant(false);
	};

	return {
		query: queryFn,
		useQuery: (
			params: { jobId: number },
			options?: Omit<
				UseQueryOptions<
					ApiResponse<refreshJobNotificationResponse200>,
					GenericWretchError<
						| refreshJobNotificationResponse400
						| refreshJobNotificationResponse403
					>
				>,
				"queryFn"
			>,
		): UseQueryResult<
			ApiResponse<refreshJobNotificationResponse200>,
			GenericWretchError<
				refreshJobNotificationResponse400 | refreshJobNotificationResponse403
			>
		> => {
			const key = [
				"refreshJobNotification",
				params,
				...(options?.queryKey || []),
			];
			const query: QueryFunction<
				ApiResponse<refreshJobNotificationResponse200>
			> = async ({ meta, queryKey, pageParam, signal }) => {
				return queryFn(params, signal);
			};
			const invalidate = () => queryClient.invalidateQueries(key);
			const queryResult = useQuery<
				ApiResponse<refreshJobNotificationResponse200>,
				GenericWretchError<
					refreshJobNotificationResponse400 | refreshJobNotificationResponse403
				>
			>(key, query, options || {}) as UseQueryResult<
				ApiResponse<refreshJobNotificationResponse200>,
				GenericWretchError<
					refreshJobNotificationResponse400 | refreshJobNotificationResponse403
				>
			>;
			queryResult.invalidate = invalidate;
			queryResult.key = key;
			return queryResult;
		},
		useInfiniteQuery: (
			params: { jobId: number },
			options?: Omit<
				UseInfiniteQueryOptions<
					ApiResponse<refreshJobNotificationResponse200>,
					GenericWretchError<
						| refreshJobNotificationResponse400
						| refreshJobNotificationResponse403
					>
				>,
				"queryFn"
			>,
		): UseInfiniteQueryResult<
			ApiResponse<refreshJobNotificationResponse200>,
			GenericWretchError<
				refreshJobNotificationResponse400 | refreshJobNotificationResponse403
			>
		> => {
			const key = [
				"refreshJobNotification",
				params,
				...(options?.queryKey || []),
			];
			const query: QueryFunction<
				ApiResponse<refreshJobNotificationResponse200>
			> = async ({ meta, queryKey, pageParam, signal }) => {
				return queryFn(params, signal);
			};
			const invalidate = () => queryClient.invalidateQueries(key);
			const queryResult = useInfiniteQuery<
				ApiResponse<refreshJobNotificationResponse200>,
				GenericWretchError<
					refreshJobNotificationResponse400 | refreshJobNotificationResponse403
				>
			>(key, query, options || {}) as UseInfiniteQueryResult<
				ApiResponse<refreshJobNotificationResponse200>,
				GenericWretchError<
					refreshJobNotificationResponse400 | refreshJobNotificationResponse403
				>
			>;
			queryResult.invalidate = invalidate;
			queryResult.key = key;
			return queryResult;
		},
	};
};

const getAllLanguages = (wretch: Http, queryClient: QueryClient) => {
	const queryFn = async (
		signal?: AbortSignal | undefined,
	): Promise<ApiResponse<getAllLanguagesResponse200>> => {
		try {
			const res = await wretch
				.options({ signal })
				.url("/admin/api/languages")
				.get()
				.res();
			const json = await res.json();
			switch (res.status) {
				case 200: {
					const data = getAllLanguagesResponse200.parse(json);
					return {
						status: res.status,
						data,
						headers: res.headers,
						url: res.url,
					};
				}
			}
		} catch (e) {
			const err = error(e);
			throw err;
		}

		invariant(false);
	};

	return {
		query: queryFn,
		useQuery: (
			options?: Omit<
				UseQueryOptions<
					ApiResponse<getAllLanguagesResponse200>,
					GenericWretchError<null>
				>,
				"queryFn"
			>,
		): UseQueryResult<
			ApiResponse<getAllLanguagesResponse200>,
			GenericWretchError<null>
		> => {
			const key = ["getAllLanguages", ...(options?.queryKey || [])];
			const query: QueryFunction<ApiResponse<getAllLanguagesResponse200>> =
				async ({ meta, queryKey, pageParam, signal }) => {
					return queryFn(signal);
				};
			const invalidate = () => queryClient.invalidateQueries(key);
			const queryResult = useQuery<
				ApiResponse<getAllLanguagesResponse200>,
				GenericWretchError<null>
			>(key, query, options || {}) as UseQueryResult<
				ApiResponse<getAllLanguagesResponse200>,
				GenericWretchError<null>
			>;
			queryResult.invalidate = invalidate;
			queryResult.key = key;
			return queryResult;
		},
		useInfiniteQuery: (
			options?: Omit<
				UseInfiniteQueryOptions<
					ApiResponse<getAllLanguagesResponse200>,
					GenericWretchError<null>
				>,
				"queryFn"
			>,
		): UseInfiniteQueryResult<
			ApiResponse<getAllLanguagesResponse200>,
			GenericWretchError<null>
		> => {
			const key = ["getAllLanguages", ...(options?.queryKey || [])];
			const query: QueryFunction<ApiResponse<getAllLanguagesResponse200>> =
				async ({ meta, queryKey, pageParam, signal }) => {
					return queryFn(signal);
				};
			const invalidate = () => queryClient.invalidateQueries(key);
			const queryResult = useInfiniteQuery<
				ApiResponse<getAllLanguagesResponse200>,
				GenericWretchError<null>
			>(key, query, options || {}) as UseInfiniteQueryResult<
				ApiResponse<getAllLanguagesResponse200>,
				GenericWretchError<null>
			>;
			queryResult.invalidate = invalidate;
			queryResult.key = key;
			return queryResult;
		},
	};
};

const userSearch = (wretch: Http, queryClient: QueryClient) => {
	const queryFn = async (
		params: { search: string },
		signal?: AbortSignal | undefined,
	): Promise<ApiResponse<userSearchResponse200>> => {
		const args = z.object({ search: z.string() }).parse(params);
		const { search } = args;
		try {
			const res = await wretch
				.options({ signal })
				.url("/admin/api/user/search")
				.query(qs.stringify({ search }))
				.get()
				.res();
			const json = await res.json();
			switch (res.status) {
				case 200: {
					const data = userSearchResponse200.parse(json);
					return {
						status: res.status,
						data,
						headers: res.headers,
						url: res.url,
					};
				}
			}
		} catch (e) {
			const err = error(e);
			const json = err.json;
			switch (err.status) {
				case 403: {
					err.json = userSearchResponse403.parse(json);
					break;
				}
			}
			throw err;
		}

		invariant(false);
	};

	return {
		query: queryFn,
		useQuery: (
			params: { search: string },
			options?: Omit<
				UseQueryOptions<
					ApiResponse<userSearchResponse200>,
					GenericWretchError<userSearchResponse403>
				>,
				"queryFn"
			>,
		): UseQueryResult<
			ApiResponse<userSearchResponse200>,
			GenericWretchError<userSearchResponse403>
		> => {
			const key = ["userSearch", params, ...(options?.queryKey || [])];
			const query: QueryFunction<ApiResponse<userSearchResponse200>> = async ({
				meta,
				queryKey,
				pageParam,
				signal,
			}) => {
				return queryFn(params, signal);
			};
			const invalidate = () => queryClient.invalidateQueries(key);
			const queryResult = useQuery<
				ApiResponse<userSearchResponse200>,
				GenericWretchError<userSearchResponse403>
			>(key, query, options || {}) as UseQueryResult<
				ApiResponse<userSearchResponse200>,
				GenericWretchError<userSearchResponse403>
			>;
			queryResult.invalidate = invalidate;
			queryResult.key = key;
			return queryResult;
		},
		useInfiniteQuery: (
			params: { search: string },
			options?: Omit<
				UseInfiniteQueryOptions<
					ApiResponse<userSearchResponse200>,
					GenericWretchError<userSearchResponse403>
				>,
				"queryFn"
			>,
		): UseInfiniteQueryResult<
			ApiResponse<userSearchResponse200>,
			GenericWretchError<userSearchResponse403>
		> => {
			const key = ["userSearch", params, ...(options?.queryKey || [])];
			const query: QueryFunction<ApiResponse<userSearchResponse200>> = async ({
				meta,
				queryKey,
				pageParam,
				signal,
			}) => {
				return queryFn(params, signal);
			};
			const invalidate = () => queryClient.invalidateQueries(key);
			const queryResult = useInfiniteQuery<
				ApiResponse<userSearchResponse200>,
				GenericWretchError<userSearchResponse403>
			>(key, query, options || {}) as UseInfiniteQueryResult<
				ApiResponse<userSearchResponse200>,
				GenericWretchError<userSearchResponse403>
			>;
			queryResult.invalidate = invalidate;
			queryResult.key = key;
			return queryResult;
		},
	};
};

const clubAmateurSearch = (wretch: Http, queryClient: QueryClient) => {
	const queryFn = async (
		params: { search: string; checkUserExists: number },
		signal?: AbortSignal | undefined,
	): Promise<ApiResponse<clubAmateurSearchResponse200>> => {
		const args = z
			.object({ search: z.string(), checkUserExists: z.number().int() })
			.parse(params);
		const { search, checkUserExists } = args;
		try {
			const res = await wretch
				.options({ signal })
				.url("/admin/api/club-amateur/search")
				.query(qs.stringify({ search, checkUserExists }))
				.get()
				.res();
			const json = await res.json();
			switch (res.status) {
				case 200: {
					const data = clubAmateurSearchResponse200.parse(json);
					return {
						status: res.status,
						data,
						headers: res.headers,
						url: res.url,
					};
				}
			}
		} catch (e) {
			const err = error(e);
			const json = err.json;
			switch (err.status) {
				case 403: {
					err.json = clubAmateurSearchResponse403.parse(json);
					break;
				}
			}
			throw err;
		}

		invariant(false);
	};

	return {
		query: queryFn,
		useQuery: (
			params: { search: string; checkUserExists: number },
			options?: Omit<
				UseQueryOptions<
					ApiResponse<clubAmateurSearchResponse200>,
					GenericWretchError<clubAmateurSearchResponse403>
				>,
				"queryFn"
			>,
		): UseQueryResult<
			ApiResponse<clubAmateurSearchResponse200>,
			GenericWretchError<clubAmateurSearchResponse403>
		> => {
			const key = ["clubAmateurSearch", params, ...(options?.queryKey || [])];
			const query: QueryFunction<ApiResponse<clubAmateurSearchResponse200>> =
				async ({ meta, queryKey, pageParam, signal }) => {
					return queryFn(params, signal);
				};
			const invalidate = () => queryClient.invalidateQueries(key);
			const queryResult = useQuery<
				ApiResponse<clubAmateurSearchResponse200>,
				GenericWretchError<clubAmateurSearchResponse403>
			>(key, query, options || {}) as UseQueryResult<
				ApiResponse<clubAmateurSearchResponse200>,
				GenericWretchError<clubAmateurSearchResponse403>
			>;
			queryResult.invalidate = invalidate;
			queryResult.key = key;
			return queryResult;
		},
		useInfiniteQuery: (
			params: { search: string; checkUserExists: number },
			options?: Omit<
				UseInfiniteQueryOptions<
					ApiResponse<clubAmateurSearchResponse200>,
					GenericWretchError<clubAmateurSearchResponse403>
				>,
				"queryFn"
			>,
		): UseInfiniteQueryResult<
			ApiResponse<clubAmateurSearchResponse200>,
			GenericWretchError<clubAmateurSearchResponse403>
		> => {
			const key = ["clubAmateurSearch", params, ...(options?.queryKey || [])];
			const query: QueryFunction<ApiResponse<clubAmateurSearchResponse200>> =
				async ({ meta, queryKey, pageParam, signal }) => {
					return queryFn(params, signal);
				};
			const invalidate = () => queryClient.invalidateQueries(key);
			const queryResult = useInfiniteQuery<
				ApiResponse<clubAmateurSearchResponse200>,
				GenericWretchError<clubAmateurSearchResponse403>
			>(key, query, options || {}) as UseInfiniteQueryResult<
				ApiResponse<clubAmateurSearchResponse200>,
				GenericWretchError<clubAmateurSearchResponse403>
			>;
			queryResult.invalidate = invalidate;
			queryResult.key = key;
			return queryResult;
		},
	};
};

const clubProSearch = (wretch: Http, queryClient: QueryClient) => {
	const queryFn = async (
		params: { search: string; checkUserExists?: number },
		signal?: AbortSignal | undefined,
	): Promise<ApiResponse<clubProSearchResponse200>> => {
		const args = z
			.object({
				search: z.string(),
				checkUserExists: z.number().int().optional(),
			})
			.parse(params);
		const { search, checkUserExists } = args;
		try {
			const res = await wretch
				.options({ signal })
				.url("/admin/api/club-pro/search")
				.query(qs.stringify({ search, checkUserExists }))
				.get()
				.res();
			const json = await res.json();
			switch (res.status) {
				case 200: {
					const data = clubProSearchResponse200.parse(json);
					return {
						status: res.status,
						data,
						headers: res.headers,
						url: res.url,
					};
				}
			}
		} catch (e) {
			const err = error(e);
			const json = err.json;
			switch (err.status) {
				case 403: {
					err.json = clubProSearchResponse403.parse(json);
					break;
				}
			}
			throw err;
		}

		invariant(false);
	};

	return {
		query: queryFn,
		useQuery: (
			params: { search: string; checkUserExists?: number },
			options?: Omit<
				UseQueryOptions<
					ApiResponse<clubProSearchResponse200>,
					GenericWretchError<clubProSearchResponse403>
				>,
				"queryFn"
			>,
		): UseQueryResult<
			ApiResponse<clubProSearchResponse200>,
			GenericWretchError<clubProSearchResponse403>
		> => {
			const key = ["clubProSearch", params, ...(options?.queryKey || [])];
			const query: QueryFunction<ApiResponse<clubProSearchResponse200>> =
				async ({ meta, queryKey, pageParam, signal }) => {
					return queryFn(params, signal);
				};
			const invalidate = () => queryClient.invalidateQueries(key);
			const queryResult = useQuery<
				ApiResponse<clubProSearchResponse200>,
				GenericWretchError<clubProSearchResponse403>
			>(key, query, options || {}) as UseQueryResult<
				ApiResponse<clubProSearchResponse200>,
				GenericWretchError<clubProSearchResponse403>
			>;
			queryResult.invalidate = invalidate;
			queryResult.key = key;
			return queryResult;
		},
		useInfiniteQuery: (
			params: { search: string; checkUserExists?: number },
			options?: Omit<
				UseInfiniteQueryOptions<
					ApiResponse<clubProSearchResponse200>,
					GenericWretchError<clubProSearchResponse403>
				>,
				"queryFn"
			>,
		): UseInfiniteQueryResult<
			ApiResponse<clubProSearchResponse200>,
			GenericWretchError<clubProSearchResponse403>
		> => {
			const key = ["clubProSearch", params, ...(options?.queryKey || [])];
			const query: QueryFunction<ApiResponse<clubProSearchResponse200>> =
				async ({ meta, queryKey, pageParam, signal }) => {
					return queryFn(params, signal);
				};
			const invalidate = () => queryClient.invalidateQueries(key);
			const queryResult = useInfiniteQuery<
				ApiResponse<clubProSearchResponse200>,
				GenericWretchError<clubProSearchResponse403>
			>(key, query, options || {}) as UseInfiniteQueryResult<
				ApiResponse<clubProSearchResponse200>,
				GenericWretchError<clubProSearchResponse403>
			>;
			queryResult.invalidate = invalidate;
			queryResult.key = key;
			return queryResult;
		},
	};
};

const getAllSubscriptionProducts = (wretch: Http, queryClient: QueryClient) => {
	const queryFn = async (
		signal?: AbortSignal | undefined,
	): Promise<ApiResponse<getAllSubscriptionProductsResponse200>> => {
		try {
			const res = await wretch
				.options({ signal })
				.url("/admin/api/subscriptionProducts")
				.get()
				.res();
			const json = await res.json();
			switch (res.status) {
				case 200: {
					const data = getAllSubscriptionProductsResponse200.parse(json);
					return {
						status: res.status,
						data,
						headers: res.headers,
						url: res.url,
					};
				}
			}
		} catch (e) {
			const err = error(e);
			throw err;
		}

		invariant(false);
	};

	return {
		query: queryFn,
		useQuery: (
			options?: Omit<
				UseQueryOptions<
					ApiResponse<getAllSubscriptionProductsResponse200>,
					GenericWretchError<null>
				>,
				"queryFn"
			>,
		): UseQueryResult<
			ApiResponse<getAllSubscriptionProductsResponse200>,
			GenericWretchError<null>
		> => {
			const key = ["getAllSubscriptionProducts", ...(options?.queryKey || [])];
			const query: QueryFunction<
				ApiResponse<getAllSubscriptionProductsResponse200>
			> = async ({ meta, queryKey, pageParam, signal }) => {
				return queryFn(signal);
			};
			const invalidate = () => queryClient.invalidateQueries(key);
			const queryResult = useQuery<
				ApiResponse<getAllSubscriptionProductsResponse200>,
				GenericWretchError<null>
			>(key, query, options || {}) as UseQueryResult<
				ApiResponse<getAllSubscriptionProductsResponse200>,
				GenericWretchError<null>
			>;
			queryResult.invalidate = invalidate;
			queryResult.key = key;
			return queryResult;
		},
		useInfiniteQuery: (
			options?: Omit<
				UseInfiniteQueryOptions<
					ApiResponse<getAllSubscriptionProductsResponse200>,
					GenericWretchError<null>
				>,
				"queryFn"
			>,
		): UseInfiniteQueryResult<
			ApiResponse<getAllSubscriptionProductsResponse200>,
			GenericWretchError<null>
		> => {
			const key = ["getAllSubscriptionProducts", ...(options?.queryKey || [])];
			const query: QueryFunction<
				ApiResponse<getAllSubscriptionProductsResponse200>
			> = async ({ meta, queryKey, pageParam, signal }) => {
				return queryFn(signal);
			};
			const invalidate = () => queryClient.invalidateQueries(key);
			const queryResult = useInfiniteQuery<
				ApiResponse<getAllSubscriptionProductsResponse200>,
				GenericWretchError<null>
			>(key, query, options || {}) as UseInfiniteQueryResult<
				ApiResponse<getAllSubscriptionProductsResponse200>,
				GenericWretchError<null>
			>;
			queryResult.invalidate = invalidate;
			queryResult.key = key;
			return queryResult;
		},
	};
};

const getUserDetail = (wretch: Http, queryClient: QueryClient) => {
	const queryFn = async (
		params: { id: number },
		signal?: AbortSignal | undefined,
	): Promise<ApiResponse<UserInfo>> => {
		const args = z.object({ id: z.number().int() }).parse(params);
		const { id } = args;
		try {
			const res = await wretch
				.options({ signal })
				.url(`/admin/user/${id}`)
				.get()
				.res();
			const json = await res.json();
			switch (res.status) {
				case 200: {
					const data = UserInfo.parse(json);
					return {
						status: res.status,
						data,
						headers: res.headers,
						url: res.url,
					};
				}
			}
		} catch (e) {
			const err = error(e);
			throw err;
		}

		invariant(false);
	};

	return {
		query: queryFn,
		useQuery: (
			params: { id: number },
			options?: Omit<
				UseQueryOptions<ApiResponse<UserInfo>, GenericWretchError<null>>,
				"queryFn"
			>,
		): UseQueryResult<ApiResponse<UserInfo>, GenericWretchError<null>> => {
			const key = ["getUserDetail", params, ...(options?.queryKey || [])];
			const query: QueryFunction<ApiResponse<UserInfo>> = async ({
				meta,
				queryKey,
				pageParam,
				signal,
			}) => {
				return queryFn(params, signal);
			};
			const invalidate = () => queryClient.invalidateQueries(key);
			const queryResult = useQuery<
				ApiResponse<UserInfo>,
				GenericWretchError<null>
			>(key, query, options || {}) as UseQueryResult<
				ApiResponse<UserInfo>,
				GenericWretchError<null>
			>;
			queryResult.invalidate = invalidate;
			queryResult.key = key;
			return queryResult;
		},
		useInfiniteQuery: (
			params: { id: number },
			options?: Omit<
				UseInfiniteQueryOptions<
					ApiResponse<UserInfo>,
					GenericWretchError<null>
				>,
				"queryFn"
			>,
		): UseInfiniteQueryResult<
			ApiResponse<UserInfo>,
			GenericWretchError<null>
		> => {
			const key = ["getUserDetail", params, ...(options?.queryKey || [])];
			const query: QueryFunction<ApiResponse<UserInfo>> = async ({
				meta,
				queryKey,
				pageParam,
				signal,
			}) => {
				return queryFn(params, signal);
			};
			const invalidate = () => queryClient.invalidateQueries(key);
			const queryResult = useInfiniteQuery<
				ApiResponse<UserInfo>,
				GenericWretchError<null>
			>(key, query, options || {}) as UseInfiniteQueryResult<
				ApiResponse<UserInfo>,
				GenericWretchError<null>
			>;
			queryResult.invalidate = invalidate;
			queryResult.key = key;
			return queryResult;
		},
	};
};

const getUserSessions = (wretch: Http, queryClient: QueryClient) => {
	const queryFn = async (
		params: { id: number },
		signal?: AbortSignal | undefined,
	): Promise<ApiResponse<getUserSessionsResponse200>> => {
		const args = z.object({ id: z.number().int() }).parse(params);
		const { id } = args;
		try {
			const res = await wretch
				.options({ signal })
				.url(`/admin/user/${id}/sessions`)
				.get()
				.res();
			const json = await res.json();
			switch (res.status) {
				case 200: {
					const data = getUserSessionsResponse200.parse(json);
					return {
						status: res.status,
						data,
						headers: res.headers,
						url: res.url,
					};
				}
			}
		} catch (e) {
			const err = error(e);
			throw err;
		}

		invariant(false);
	};

	return {
		query: queryFn,
		useQuery: (
			params: { id: number },
			options?: Omit<
				UseQueryOptions<
					ApiResponse<getUserSessionsResponse200>,
					GenericWretchError<null>
				>,
				"queryFn"
			>,
		): UseQueryResult<
			ApiResponse<getUserSessionsResponse200>,
			GenericWretchError<null>
		> => {
			const key = ["getUserSessions", params, ...(options?.queryKey || [])];
			const query: QueryFunction<ApiResponse<getUserSessionsResponse200>> =
				async ({ meta, queryKey, pageParam, signal }) => {
					return queryFn(params, signal);
				};
			const invalidate = () => queryClient.invalidateQueries(key);
			const queryResult = useQuery<
				ApiResponse<getUserSessionsResponse200>,
				GenericWretchError<null>
			>(key, query, options || {}) as UseQueryResult<
				ApiResponse<getUserSessionsResponse200>,
				GenericWretchError<null>
			>;
			queryResult.invalidate = invalidate;
			queryResult.key = key;
			return queryResult;
		},
		useInfiniteQuery: (
			params: { id: number },
			options?: Omit<
				UseInfiniteQueryOptions<
					ApiResponse<getUserSessionsResponse200>,
					GenericWretchError<null>
				>,
				"queryFn"
			>,
		): UseInfiniteQueryResult<
			ApiResponse<getUserSessionsResponse200>,
			GenericWretchError<null>
		> => {
			const key = ["getUserSessions", params, ...(options?.queryKey || [])];
			const query: QueryFunction<ApiResponse<getUserSessionsResponse200>> =
				async ({ meta, queryKey, pageParam, signal }) => {
					return queryFn(params, signal);
				};
			const invalidate = () => queryClient.invalidateQueries(key);
			const queryResult = useInfiniteQuery<
				ApiResponse<getUserSessionsResponse200>,
				GenericWretchError<null>
			>(key, query, options || {}) as UseInfiniteQueryResult<
				ApiResponse<getUserSessionsResponse200>,
				GenericWretchError<null>
			>;
			queryResult.invalidate = invalidate;
			queryResult.key = key;
			return queryResult;
		},
	};
};

const revokeSessions = (wretch: Http) => {
	const mutation = async (params: { id: number }) => {
		const args = z.object({ id: z.number().int() }).parse(params);
		const { id } = args;

		try {
			const res = await wretch.url(`/admin/user/${id}/sessions`).delete().res();
			const json = await res.json();
			switch (res.status) {
				case 200: {
					const data = revokeSessionsResponse200.parse(json);
					return {
						status: res.status,
						data,
						headers: res.headers,
						url: res.url,
					};
				}
			}
		} catch (e) {
			const err = error(e);
			throw err;
		}

		invariant(false);
	};

	return {
		mutation,
		useMutation: (
			options?: Omit<
				UseMutationOptions<
					ApiResponse<revokeSessionsResponse200> | undefined,
					GenericWretchError<null>,
					{ id: number }
				>,
				"mutationFn"
			>,
		) => {
			return useMutation<
				ApiResponse<revokeSessionsResponse200> | undefined,
				GenericWretchError<null>,
				{ id: number }
			>(mutation, options || {});
		},
	};
};

const getUserSignIns = (wretch: Http, queryClient: QueryClient) => {
	const queryFn = async (
		params: { id: number },
		signal?: AbortSignal | undefined,
	): Promise<ApiResponse<getUserSignInsResponse200>> => {
		const args = z.object({ id: z.number().int() }).parse(params);
		const { id } = args;
		try {
			const res = await wretch
				.options({ signal })
				.url(`/admin/user/${id}/signins`)
				.get()
				.res();
			const json = await res.json();
			switch (res.status) {
				case 200: {
					const data = getUserSignInsResponse200.parse(json);
					return {
						status: res.status,
						data,
						headers: res.headers,
						url: res.url,
					};
				}
			}
		} catch (e) {
			const err = error(e);
			throw err;
		}

		invariant(false);
	};

	return {
		query: queryFn,
		useQuery: (
			params: { id: number },
			options?: Omit<
				UseQueryOptions<
					ApiResponse<getUserSignInsResponse200>,
					GenericWretchError<null>
				>,
				"queryFn"
			>,
		): UseQueryResult<
			ApiResponse<getUserSignInsResponse200>,
			GenericWretchError<null>
		> => {
			const key = ["getUserSignIns", params, ...(options?.queryKey || [])];
			const query: QueryFunction<ApiResponse<getUserSignInsResponse200>> =
				async ({ meta, queryKey, pageParam, signal }) => {
					return queryFn(params, signal);
				};
			const invalidate = () => queryClient.invalidateQueries(key);
			const queryResult = useQuery<
				ApiResponse<getUserSignInsResponse200>,
				GenericWretchError<null>
			>(key, query, options || {}) as UseQueryResult<
				ApiResponse<getUserSignInsResponse200>,
				GenericWretchError<null>
			>;
			queryResult.invalidate = invalidate;
			queryResult.key = key;
			return queryResult;
		},
		useInfiniteQuery: (
			params: { id: number },
			options?: Omit<
				UseInfiniteQueryOptions<
					ApiResponse<getUserSignInsResponse200>,
					GenericWretchError<null>
				>,
				"queryFn"
			>,
		): UseInfiniteQueryResult<
			ApiResponse<getUserSignInsResponse200>,
			GenericWretchError<null>
		> => {
			const key = ["getUserSignIns", params, ...(options?.queryKey || [])];
			const query: QueryFunction<ApiResponse<getUserSignInsResponse200>> =
				async ({ meta, queryKey, pageParam, signal }) => {
					return queryFn(params, signal);
				};
			const invalidate = () => queryClient.invalidateQueries(key);
			const queryResult = useInfiniteQuery<
				ApiResponse<getUserSignInsResponse200>,
				GenericWretchError<null>
			>(key, query, options || {}) as UseInfiniteQueryResult<
				ApiResponse<getUserSignInsResponse200>,
				GenericWretchError<null>
			>;
			queryResult.invalidate = invalidate;
			queryResult.key = key;
			return queryResult;
		},
	};
};

const logUserActivity = (wretch: Http) => {
	const mutation = async (params: { body: UserActivityLogRequest }) => {
		const args = z.object({ body: z.any() }).parse(params);
		const { body } = args;

		try {
			const res = await wretch.url("/admin/telemetry").json(body).post().res();
			switch (res.status) {
				case 201: {
					return {
						status: res.status,
						data: null,
						headers: res.headers,
						url: res.url,
					};
				}
			}
		} catch (e) {
			const err = error(e);
			const json = err.json;
			switch (err.status) {
				case 400: {
					err.json = logUserActivityResponse400.parse(json);
					break;
				}
			}
			throw err;
		}

		invariant(false);
	};

	return {
		mutation,
		useMutation: (
			options?: Omit<
				UseMutationOptions<
					ApiResponse<null> | undefined,
					GenericWretchError<logUserActivityResponse400>,
					{ body: UserActivityLogRequest }
				>,
				"mutationFn"
			>,
		) => {
			return useMutation<
				ApiResponse<null> | undefined,
				GenericWretchError<logUserActivityResponse400>,
				{ body: UserActivityLogRequest }
			>(mutation, options || {});
		},
	};
};

const getClubAllTeams = (wretch: Http, queryClient: QueryClient) => {
	const queryFn = async (
		params: { id: number; page: number; limit: number },
		signal?: AbortSignal | undefined,
	): Promise<ApiResponse<TeamResponse>> => {
		const args = z
			.object({
				id: z.number().int(),
				page: z.number().int(),
				limit: z.number().int(),
			})
			.parse(params);
		const { id, page, limit } = args;
		try {
			const res = await wretch
				.options({ signal })
				.url(`/admin/api/club/${id}/teams`)
				.query(qs.stringify({ page, limit }))
				.get()
				.res();
			const json = await res.json();
			switch (res.status) {
				case 200: {
					const data = TeamResponse.parse(json);
					return {
						status: res.status,
						data,
						headers: res.headers,
						url: res.url,
					};
				}
			}
		} catch (e) {
			const err = error(e);
			const json = err.json;
			switch (err.status) {
				case 400: {
					err.json = getClubAllTeamsResponse400.parse(json);
					break;
				}
				case 403: {
					err.json = getClubAllTeamsResponse403.parse(json);
					break;
				}
			}
			throw err;
		}

		invariant(false);
	};

	return {
		query: queryFn,
		useQuery: (
			params: { id: number; page: number; limit: number },
			options?: Omit<
				UseQueryOptions<
					ApiResponse<TeamResponse>,
					GenericWretchError<
						getClubAllTeamsResponse400 | getClubAllTeamsResponse403
					>
				>,
				"queryFn"
			>,
		): UseQueryResult<
			ApiResponse<TeamResponse>,
			GenericWretchError<
				getClubAllTeamsResponse400 | getClubAllTeamsResponse403
			>
		> => {
			const key = ["getClubAllTeams", params, ...(options?.queryKey || [])];
			const query: QueryFunction<ApiResponse<TeamResponse>> = async ({
				meta,
				queryKey,
				pageParam,
				signal,
			}) => {
				return queryFn(params, signal);
			};
			const invalidate = () => queryClient.invalidateQueries(key);
			const queryResult = useQuery<
				ApiResponse<TeamResponse>,
				GenericWretchError<
					getClubAllTeamsResponse400 | getClubAllTeamsResponse403
				>
			>(key, query, options || {}) as UseQueryResult<
				ApiResponse<TeamResponse>,
				GenericWretchError<
					getClubAllTeamsResponse400 | getClubAllTeamsResponse403
				>
			>;
			queryResult.invalidate = invalidate;
			queryResult.key = key;
			return queryResult;
		},
		useInfiniteQuery: (
			params: { id: number; page: number; limit: number },
			options?: Omit<
				UseInfiniteQueryOptions<
					ApiResponse<TeamResponse>,
					GenericWretchError<
						getClubAllTeamsResponse400 | getClubAllTeamsResponse403
					>
				>,
				"queryFn"
			>,
		): UseInfiniteQueryResult<
			ApiResponse<TeamResponse>,
			GenericWretchError<
				getClubAllTeamsResponse400 | getClubAllTeamsResponse403
			>
		> => {
			const key = ["getClubAllTeams", params, ...(options?.queryKey || [])];
			const query: QueryFunction<ApiResponse<TeamResponse>> = async ({
				meta,
				queryKey,
				pageParam,
				signal,
			}) => {
				return queryFn(params, signal);
			};
			const invalidate = () => queryClient.invalidateQueries(key);
			const queryResult = useInfiniteQuery<
				ApiResponse<TeamResponse>,
				GenericWretchError<
					getClubAllTeamsResponse400 | getClubAllTeamsResponse403
				>
			>(key, query, options || {}) as UseInfiniteQueryResult<
				ApiResponse<TeamResponse>,
				GenericWretchError<
					getClubAllTeamsResponse400 | getClubAllTeamsResponse403
				>
			>;
			queryResult.invalidate = invalidate;
			queryResult.key = key;
			return queryResult;
		},
	};
};

const getTeamByIdAndCoachId = (wretch: Http, queryClient: QueryClient) => {
	const queryFn = async (
		params: { clubId: number; id?: number; coachId?: number },
		signal?: AbortSignal | undefined,
	): Promise<ApiResponse<getTeamByIdAndCoachIdResponse200>> => {
		const args = z
			.object({
				clubId: z.number().int(),
				id: z.number().int().optional(),
				coachId: z.number().int().optional(),
			})
			.parse(params);
		const { clubId, id, coachId } = args;
		try {
			const res = await wretch
				.options({ signal })
				.url(`/admin/club/${clubId}/team`)
				.query(qs.stringify({ id, coachId }))
				.get()
				.res();
			const json = await res.json();
			switch (res.status) {
				case 200: {
					const data = getTeamByIdAndCoachIdResponse200.parse(json);
					return {
						status: res.status,
						data,
						headers: res.headers,
						url: res.url,
					};
				}
			}
		} catch (e) {
			const err = error(e);
			const json = err.json;
			switch (err.status) {
				case 403: {
					err.json = getTeamByIdAndCoachIdResponse403.parse(json);
					break;
				}
			}
			throw err;
		}

		invariant(false);
	};

	return {
		query: queryFn,
		useQuery: (
			params: { clubId: number; id?: number; coachId?: number },
			options?: Omit<
				UseQueryOptions<
					ApiResponse<getTeamByIdAndCoachIdResponse200>,
					GenericWretchError<getTeamByIdAndCoachIdResponse403>
				>,
				"queryFn"
			>,
		): UseQueryResult<
			ApiResponse<getTeamByIdAndCoachIdResponse200>,
			GenericWretchError<getTeamByIdAndCoachIdResponse403>
		> => {
			const key = [
				"getTeamByIdAndCoachId",
				params,
				...(options?.queryKey || []),
			];
			const query: QueryFunction<
				ApiResponse<getTeamByIdAndCoachIdResponse200>
			> = async ({ meta, queryKey, pageParam, signal }) => {
				return queryFn(params, signal);
			};
			const invalidate = () => queryClient.invalidateQueries(key);
			const queryResult = useQuery<
				ApiResponse<getTeamByIdAndCoachIdResponse200>,
				GenericWretchError<getTeamByIdAndCoachIdResponse403>
			>(key, query, options || {}) as UseQueryResult<
				ApiResponse<getTeamByIdAndCoachIdResponse200>,
				GenericWretchError<getTeamByIdAndCoachIdResponse403>
			>;
			queryResult.invalidate = invalidate;
			queryResult.key = key;
			return queryResult;
		},
		useInfiniteQuery: (
			params: { clubId: number; id?: number; coachId?: number },
			options?: Omit<
				UseInfiniteQueryOptions<
					ApiResponse<getTeamByIdAndCoachIdResponse200>,
					GenericWretchError<getTeamByIdAndCoachIdResponse403>
				>,
				"queryFn"
			>,
		): UseInfiniteQueryResult<
			ApiResponse<getTeamByIdAndCoachIdResponse200>,
			GenericWretchError<getTeamByIdAndCoachIdResponse403>
		> => {
			const key = [
				"getTeamByIdAndCoachId",
				params,
				...(options?.queryKey || []),
			];
			const query: QueryFunction<
				ApiResponse<getTeamByIdAndCoachIdResponse200>
			> = async ({ meta, queryKey, pageParam, signal }) => {
				return queryFn(params, signal);
			};
			const invalidate = () => queryClient.invalidateQueries(key);
			const queryResult = useInfiniteQuery<
				ApiResponse<getTeamByIdAndCoachIdResponse200>,
				GenericWretchError<getTeamByIdAndCoachIdResponse403>
			>(key, query, options || {}) as UseInfiniteQueryResult<
				ApiResponse<getTeamByIdAndCoachIdResponse200>,
				GenericWretchError<getTeamByIdAndCoachIdResponse403>
			>;
			queryResult.invalidate = invalidate;
			queryResult.key = key;
			return queryResult;
		},
	};
};

const addUpdateTeam = (wretch: Http) => {
	const mutation = async (params: {
		clubId: number;
		body: addUpdateTeam_Body;
	}) => {
		const args = z
			.object({ clubId: z.number().int(), body: z.any() })
			.parse(params);
		const { clubId, body } = args;

		try {
			const res = await wretch
				.url(`/admin/club/${clubId}/team`)
				.json(body)
				.post()
				.res();
			const json = await res.json();
			switch (res.status) {
				case 200: {
					const data = addUpdateTeamResponse200.parse(json);
					return {
						status: res.status,
						data,
						headers: res.headers,
						url: res.url,
					};
				}
			}
		} catch (e) {
			const err = error(e);
			const json = err.json;
			switch (err.status) {
				case 400: {
					err.json = addUpdateTeamResponse400.parse(json);
					break;
				}
				case 403: {
					err.json = addUpdateTeamResponse403.parse(json);
					break;
				}
				case 409: {
					err.json = addUpdateTeamResponse409.parse(json);
					break;
				}
			}
			throw err;
		}

		invariant(false);
	};

	return {
		mutation,
		useMutation: (
			options?: Omit<
				UseMutationOptions<
					ApiResponse<addUpdateTeamResponse200> | undefined,
					GenericWretchError<
						| addUpdateTeamResponse400
						| addUpdateTeamResponse403
						| addUpdateTeamResponse409
					>,
					{ clubId: number; body: addUpdateTeam_Body }
				>,
				"mutationFn"
			>,
		) => {
			return useMutation<
				ApiResponse<addUpdateTeamResponse200> | undefined,
				GenericWretchError<
					| addUpdateTeamResponse400
					| addUpdateTeamResponse403
					| addUpdateTeamResponse409
				>,
				{ clubId: number; body: addUpdateTeam_Body }
			>(mutation, options || {});
		},
	};
};

const deleteTeam = (wretch: Http) => {
	const mutation = async (params: { id: number }) => {
		const args = z.object({ id: z.number().int() }).parse(params);
		const { id } = args;

		try {
			const res = await wretch.url(`/admin/team/${id}`).delete().res();
			const json = await res.json();
			switch (res.status) {
				case 200: {
					const data = deleteTeamResponse200.parse(json);
					return {
						status: res.status,
						data,
						headers: res.headers,
						url: res.url,
					};
				}
			}
		} catch (e) {
			const err = error(e);
			const json = err.json;
			switch (err.status) {
				case 403: {
					err.json = deleteTeamResponse403.parse(json);
					break;
				}
				case 404: {
					err.json = deleteTeamResponse404.parse(json);
					break;
				}
			}
			throw err;
		}

		invariant(false);
	};

	return {
		mutation,
		useMutation: (
			options?: Omit<
				UseMutationOptions<
					ApiResponse<deleteTeamResponse200> | undefined,
					GenericWretchError<deleteTeamResponse403 | deleteTeamResponse404>,
					{ id: number }
				>,
				"mutationFn"
			>,
		) => {
			return useMutation<
				ApiResponse<deleteTeamResponse200> | undefined,
				GenericWretchError<deleteTeamResponse403 | deleteTeamResponse404>,
				{ id: number }
			>(mutation, options || {});
		},
	};
};

const clubCoaches = (wretch: Http, queryClient: QueryClient) => {
	const queryFn = async (
		params: { id: number },
		signal?: AbortSignal | undefined,
	): Promise<ApiResponse<clubCoachesResponse200>> => {
		const args = z.object({ id: z.number().int() }).parse(params);
		const { id } = args;
		try {
			const res = await wretch
				.options({ signal })
				.url(`/admin/club/${id}/coaches`)
				.get()
				.res();
			const json = await res.json();
			switch (res.status) {
				case 200: {
					const data = clubCoachesResponse200.parse(json);
					return {
						status: res.status,
						data,
						headers: res.headers,
						url: res.url,
					};
				}
			}
		} catch (e) {
			const err = error(e);
			const json = err.json;
			switch (err.status) {
				case 403: {
					err.json = clubCoachesResponse403.parse(json);
					break;
				}
			}
			throw err;
		}

		invariant(false);
	};

	return {
		query: queryFn,
		useQuery: (
			params: { id: number },
			options?: Omit<
				UseQueryOptions<
					ApiResponse<clubCoachesResponse200>,
					GenericWretchError<clubCoachesResponse403>
				>,
				"queryFn"
			>,
		): UseQueryResult<
			ApiResponse<clubCoachesResponse200>,
			GenericWretchError<clubCoachesResponse403>
		> => {
			const key = ["clubCoaches", params, ...(options?.queryKey || [])];
			const query: QueryFunction<ApiResponse<clubCoachesResponse200>> = async ({
				meta,
				queryKey,
				pageParam,
				signal,
			}) => {
				return queryFn(params, signal);
			};
			const invalidate = () => queryClient.invalidateQueries(key);
			const queryResult = useQuery<
				ApiResponse<clubCoachesResponse200>,
				GenericWretchError<clubCoachesResponse403>
			>(key, query, options || {}) as UseQueryResult<
				ApiResponse<clubCoachesResponse200>,
				GenericWretchError<clubCoachesResponse403>
			>;
			queryResult.invalidate = invalidate;
			queryResult.key = key;
			return queryResult;
		},
		useInfiniteQuery: (
			params: { id: number },
			options?: Omit<
				UseInfiniteQueryOptions<
					ApiResponse<clubCoachesResponse200>,
					GenericWretchError<clubCoachesResponse403>
				>,
				"queryFn"
			>,
		): UseInfiniteQueryResult<
			ApiResponse<clubCoachesResponse200>,
			GenericWretchError<clubCoachesResponse403>
		> => {
			const key = ["clubCoaches", params, ...(options?.queryKey || [])];
			const query: QueryFunction<ApiResponse<clubCoachesResponse200>> = async ({
				meta,
				queryKey,
				pageParam,
				signal,
			}) => {
				return queryFn(params, signal);
			};
			const invalidate = () => queryClient.invalidateQueries(key);
			const queryResult = useInfiniteQuery<
				ApiResponse<clubCoachesResponse200>,
				GenericWretchError<clubCoachesResponse403>
			>(key, query, options || {}) as UseInfiniteQueryResult<
				ApiResponse<clubCoachesResponse200>,
				GenericWretchError<clubCoachesResponse403>
			>;
			queryResult.invalidate = invalidate;
			queryResult.key = key;
			return queryResult;
		},
	};
};

const getClubTeamsList = (wretch: Http, queryClient: QueryClient) => {
	const queryFn = async (
		params: { id: number },
		signal?: AbortSignal | undefined,
	): Promise<ApiResponse<getClubTeamsListResponse200>> => {
		const args = z.object({ id: z.number().int() }).parse(params);
		const { id } = args;
		try {
			const res = await wretch
				.options({ signal })
				.url(`/admin/club/${id}/club-teams`)
				.get()
				.res();
			const json = await res.json();
			switch (res.status) {
				case 200: {
					const data = getClubTeamsListResponse200.parse(json);
					return {
						status: res.status,
						data,
						headers: res.headers,
						url: res.url,
					};
				}
			}
		} catch (e) {
			const err = error(e);
			const json = err.json;
			switch (err.status) {
				case 403: {
					err.json = getClubTeamsListResponse403.parse(json);
					break;
				}
			}
			throw err;
		}

		invariant(false);
	};

	return {
		query: queryFn,
		useQuery: (
			params: { id: number },
			options?: Omit<
				UseQueryOptions<
					ApiResponse<getClubTeamsListResponse200>,
					GenericWretchError<getClubTeamsListResponse403>
				>,
				"queryFn"
			>,
		): UseQueryResult<
			ApiResponse<getClubTeamsListResponse200>,
			GenericWretchError<getClubTeamsListResponse403>
		> => {
			const key = ["getClubTeamsList", params, ...(options?.queryKey || [])];
			const query: QueryFunction<ApiResponse<getClubTeamsListResponse200>> =
				async ({ meta, queryKey, pageParam, signal }) => {
					return queryFn(params, signal);
				};
			const invalidate = () => queryClient.invalidateQueries(key);
			const queryResult = useQuery<
				ApiResponse<getClubTeamsListResponse200>,
				GenericWretchError<getClubTeamsListResponse403>
			>(key, query, options || {}) as UseQueryResult<
				ApiResponse<getClubTeamsListResponse200>,
				GenericWretchError<getClubTeamsListResponse403>
			>;
			queryResult.invalidate = invalidate;
			queryResult.key = key;
			return queryResult;
		},
		useInfiniteQuery: (
			params: { id: number },
			options?: Omit<
				UseInfiniteQueryOptions<
					ApiResponse<getClubTeamsListResponse200>,
					GenericWretchError<getClubTeamsListResponse403>
				>,
				"queryFn"
			>,
		): UseInfiniteQueryResult<
			ApiResponse<getClubTeamsListResponse200>,
			GenericWretchError<getClubTeamsListResponse403>
		> => {
			const key = ["getClubTeamsList", params, ...(options?.queryKey || [])];
			const query: QueryFunction<ApiResponse<getClubTeamsListResponse200>> =
				async ({ meta, queryKey, pageParam, signal }) => {
					return queryFn(params, signal);
				};
			const invalidate = () => queryClient.invalidateQueries(key);
			const queryResult = useInfiniteQuery<
				ApiResponse<getClubTeamsListResponse200>,
				GenericWretchError<getClubTeamsListResponse403>
			>(key, query, options || {}) as UseInfiniteQueryResult<
				ApiResponse<getClubTeamsListResponse200>,
				GenericWretchError<getClubTeamsListResponse403>
			>;
			queryResult.invalidate = invalidate;
			queryResult.key = key;
			return queryResult;
		},
	};
};

const teamPlayers = (wretch: Http, queryClient: QueryClient) => {
	const queryFn = async (
		params: {
			clubId: number;
			id: number;
			page: number;
			limit: number;
			order?: object;
			position?: string;
			search?: string;
			strongestFoot?: string;
			nationality?: string;
		},
		signal?: AbortSignal | undefined,
	): Promise<ApiResponse<ClubPlayersResponse>> => {
		const args = z
			.object({
				clubId: z.number().int(),
				page: z.number().int(),
				limit: z.number().int(),
				id: z.number().int(),
				order: z
					.object({
						name: z.string().describe("Sorting column name").optional(),
						sort: z.string().describe("Sorting column type").optional(),
					})
					.optional(),
				position: z.string().optional(),
				search: z.string().optional(),
				strongestFoot: z.string().optional(),
				nationality: z.string().optional(),
			})
			.parse(params);
		const {
			clubId,
			id,
			page,
			limit,
			order,
			position,
			search,
			strongestFoot,
			nationality,
		} = args;
		try {
			const res = await wretch
				.options({ signal })
				.url(`/admin/club/${clubId}/team/${id}/players`)
				.query(
					qs.stringify({
						page,
						limit,
						order,
						position,
						search,
						strongestFoot,
						nationality,
					}),
				)
				.get()
				.res();
			const json = await res.json();
			switch (res.status) {
				case 200: {
					const data = ClubPlayersResponse.parse(json);
					return {
						status: res.status,
						data,
						headers: res.headers,
						url: res.url,
					};
				}
			}
		} catch (e) {
			const err = error(e);
			const json = err.json;
			switch (err.status) {
				case 400: {
					err.json = teamPlayersResponse400.parse(json);
					break;
				}
				case 403: {
					err.json = teamPlayersResponse403.parse(json);
					break;
				}
			}
			throw err;
		}

		invariant(false);
	};

	return {
		query: queryFn,
		useQuery: (
			params: {
				clubId: number;
				id: number;
				page: number;
				limit: number;
				order?: object;
				position?: string;
				search?: string;
				strongestFoot?: string;
				nationality?: string;
			},
			options?: Omit<
				UseQueryOptions<
					ApiResponse<ClubPlayersResponse>,
					GenericWretchError<teamPlayersResponse400 | teamPlayersResponse403>
				>,
				"queryFn"
			>,
		): UseQueryResult<
			ApiResponse<ClubPlayersResponse>,
			GenericWretchError<teamPlayersResponse400 | teamPlayersResponse403>
		> => {
			const key = ["teamPlayers", params, ...(options?.queryKey || [])];
			const query: QueryFunction<ApiResponse<ClubPlayersResponse>> = async ({
				meta,
				queryKey,
				pageParam,
				signal,
			}) => {
				return queryFn(params, signal);
			};
			const invalidate = () => queryClient.invalidateQueries(key);
			const queryResult = useQuery<
				ApiResponse<ClubPlayersResponse>,
				GenericWretchError<teamPlayersResponse400 | teamPlayersResponse403>
			>(key, query, options || {}) as UseQueryResult<
				ApiResponse<ClubPlayersResponse>,
				GenericWretchError<teamPlayersResponse400 | teamPlayersResponse403>
			>;
			queryResult.invalidate = invalidate;
			queryResult.key = key;
			return queryResult;
		},
		useInfiniteQuery: (
			params: {
				clubId: number;
				id: number;
				page: number;
				limit: number;
				order?: object;
				position?: string;
				search?: string;
				strongestFoot?: string;
				nationality?: string;
			},
			options?: Omit<
				UseInfiniteQueryOptions<
					ApiResponse<ClubPlayersResponse>,
					GenericWretchError<teamPlayersResponse400 | teamPlayersResponse403>
				>,
				"queryFn"
			>,
		): UseInfiniteQueryResult<
			ApiResponse<ClubPlayersResponse>,
			GenericWretchError<teamPlayersResponse400 | teamPlayersResponse403>
		> => {
			const key = ["teamPlayers", params, ...(options?.queryKey || [])];
			const query: QueryFunction<ApiResponse<ClubPlayersResponse>> = async ({
				meta,
				queryKey,
				pageParam,
				signal,
			}) => {
				return queryFn(params, signal);
			};
			const invalidate = () => queryClient.invalidateQueries(key);
			const queryResult = useInfiniteQuery<
				ApiResponse<ClubPlayersResponse>,
				GenericWretchError<teamPlayersResponse400 | teamPlayersResponse403>
			>(key, query, options || {}) as UseInfiniteQueryResult<
				ApiResponse<ClubPlayersResponse>,
				GenericWretchError<teamPlayersResponse400 | teamPlayersResponse403>
			>;
			queryResult.invalidate = invalidate;
			queryResult.key = key;
			return queryResult;
		},
	};
};

const addUpdatePlayer = (wretch: Http) => {
	const mutation = async (params: {
		clubId: number;
		body: addUpdatePlayer_Body;
	}) => {
		const args = z
			.object({ clubId: z.number().int(), body: z.any() })
			.parse(params);
		const { clubId, body } = args;

		try {
			const res = await wretch
				.url(`/admin/club/${clubId}/player`)
				.json(body)
				.post()
				.res();
			const json = await res.json();
			switch (res.status) {
				case 200: {
					const data = addUpdatePlayerResponse200.parse(json);
					return {
						status: res.status,
						data,
						headers: res.headers,
						url: res.url,
					};
				}
			}
		} catch (e) {
			const err = error(e);
			const json = err.json;
			switch (err.status) {
				case 400: {
					err.json = addUpdatePlayerResponse400.parse(json);
					break;
				}
				case 403: {
					err.json = addUpdatePlayerResponse403.parse(json);
					break;
				}
				case 409: {
					err.json = addUpdatePlayerResponse409.parse(json);
					break;
				}
			}
			throw err;
		}

		invariant(false);
	};

	return {
		mutation,
		useMutation: (
			options?: Omit<
				UseMutationOptions<
					ApiResponse<addUpdatePlayerResponse200> | undefined,
					GenericWretchError<
						| addUpdatePlayerResponse400
						| addUpdatePlayerResponse403
						| addUpdatePlayerResponse409
					>,
					{ clubId: number; body: addUpdatePlayer_Body }
				>,
				"mutationFn"
			>,
		) => {
			return useMutation<
				ApiResponse<addUpdatePlayerResponse200> | undefined,
				GenericWretchError<
					| addUpdatePlayerResponse400
					| addUpdatePlayerResponse403
					| addUpdatePlayerResponse409
				>,
				{ clubId: number; body: addUpdatePlayer_Body }
			>(mutation, options || {});
		},
	};
};

const updateVip = (wretch: Http) => {
	const mutation = async (params: { id: number; vip: boolean }) => {
		const args = z
			.object({ id: z.number().int(), vip: z.boolean() })
			.parse(params);
		const { id, vip } = args;

		try {
			const res = await wretch
				.url(`/admin/player/${id}/update-vip`)
				.query(qs.stringify({ vip }))
				.put()
				.res();
			const json = await res.json();
			switch (res.status) {
				case 200: {
					const data = updateVipResponse200.parse(json);
					return {
						status: res.status,
						data,
						headers: res.headers,
						url: res.url,
					};
				}
			}
		} catch (e) {
			const err = error(e);
			const json = err.json;
			switch (err.status) {
				case 403: {
					err.json = updateVipResponse403.parse(json);
					break;
				}
				case 404: {
					err.json = updateVipResponse404.parse(json);
					break;
				}
			}
			throw err;
		}

		invariant(false);
	};

	return {
		mutation,
		useMutation: (
			options?: Omit<
				UseMutationOptions<
					ApiResponse<updateVipResponse200> | undefined,
					GenericWretchError<updateVipResponse403 | updateVipResponse404>,
					{ id: number; vip: boolean }
				>,
				"mutationFn"
			>,
		) => {
			return useMutation<
				ApiResponse<updateVipResponse200> | undefined,
				GenericWretchError<updateVipResponse403 | updateVipResponse404>,
				{ id: number; vip: boolean }
			>(mutation, options || {});
		},
	};
};

const updateLevel = (wretch: Http) => {
	const mutation = async (params: { id: number; level: number }) => {
		const args = z
			.object({ id: z.number().int(), level: z.number().int() })
			.parse(params);
		const { id, level } = args;

		try {
			const res = await wretch
				.url(`/admin/team/${id}/update-level`)
				.query(qs.stringify({ level }))
				.put()
				.res();
			const json = await res.json();
			switch (res.status) {
				case 200: {
					const data = updateLevelResponse200.parse(json);
					return {
						status: res.status,
						data,
						headers: res.headers,
						url: res.url,
					};
				}
			}
		} catch (e) {
			const err = error(e);
			const json = err.json;
			switch (err.status) {
				case 403: {
					err.json = updateLevelResponse403.parse(json);
					break;
				}
				case 404: {
					err.json = updateLevelResponse404.parse(json);
					break;
				}
			}
			throw err;
		}

		invariant(false);
	};

	return {
		mutation,
		useMutation: (
			options?: Omit<
				UseMutationOptions<
					ApiResponse<updateLevelResponse200> | undefined,
					GenericWretchError<updateLevelResponse403 | updateLevelResponse404>,
					{ id: number; level: number }
				>,
				"mutationFn"
			>,
		) => {
			return useMutation<
				ApiResponse<updateLevelResponse200> | undefined,
				GenericWretchError<updateLevelResponse403 | updateLevelResponse404>,
				{ id: number; level: number }
			>(mutation, options || {});
		},
	};
};

const deletePlayer = (wretch: Http) => {
	const mutation = async (params: { id: number }) => {
		const args = z.object({ id: z.number().int() }).parse(params);
		const { id } = args;

		try {
			const res = await wretch.url(`/admin/player/${id}`).delete().res();
			const json = await res.json();
			switch (res.status) {
				case 200: {
					const data = deletePlayerResponse200.parse(json);
					return {
						status: res.status,
						data,
						headers: res.headers,
						url: res.url,
					};
				}
			}
		} catch (e) {
			const err = error(e);
			const json = err.json;
			switch (err.status) {
				case 403: {
					err.json = deletePlayerResponse403.parse(json);
					break;
				}
				case 404: {
					err.json = deletePlayerResponse404.parse(json);
					break;
				}
			}
			throw err;
		}

		invariant(false);
	};

	return {
		mutation,
		useMutation: (
			options?: Omit<
				UseMutationOptions<
					ApiResponse<deletePlayerResponse200> | undefined,
					GenericWretchError<deletePlayerResponse403 | deletePlayerResponse404>,
					{ id: number }
				>,
				"mutationFn"
			>,
		) => {
			return useMutation<
				ApiResponse<deletePlayerResponse200> | undefined,
				GenericWretchError<deletePlayerResponse403 | deletePlayerResponse404>,
				{ id: number }
			>(mutation, options || {});
		},
	};
};

const formerPlayers = (wretch: Http, queryClient: QueryClient) => {
	const queryFn = async (
		params: {
			clubId: number;
			page: number;
			limit: number;
			position?: string;
			search?: string;
			strongestFoot?: string;
			nationality?: string;
			order?: object;
		},
		signal?: AbortSignal | undefined,
	): Promise<ApiResponse<ClubPlayersResponse>> => {
		const args = z
			.object({
				clubId: z.number().int(),
				page: z.number().int(),
				limit: z.number().int(),
				position: z.string().optional(),
				search: z.string().optional(),
				strongestFoot: z.string().optional(),
				nationality: z.string().optional(),
				order: z
					.object({
						name: z.string().describe("Sorting column name").optional(),
						sort: z.string().describe("Sorting column type").optional(),
					})
					.optional(),
			})
			.parse(params);
		const {
			clubId,
			page,
			limit,
			position,
			search,
			strongestFoot,
			nationality,
			order,
		} = args;
		try {
			const res = await wretch
				.options({ signal })
				.url(`/admin/club/${clubId}/former-players`)
				.query(
					qs.stringify({
						page,
						limit,
						position,
						search,
						strongestFoot,
						nationality,
						order,
					}),
				)
				.get()
				.res();
			const json = await res.json();
			switch (res.status) {
				case 200: {
					const data = ClubPlayersResponse.parse(json);
					return {
						status: res.status,
						data,
						headers: res.headers,
						url: res.url,
					};
				}
			}
		} catch (e) {
			const err = error(e);
			const json = err.json;
			switch (err.status) {
				case 400: {
					err.json = formerPlayersResponse400.parse(json);
					break;
				}
				case 403: {
					err.json = formerPlayersResponse403.parse(json);
					break;
				}
			}
			throw err;
		}

		invariant(false);
	};

	return {
		query: queryFn,
		useQuery: (
			params: {
				clubId: number;
				page: number;
				limit: number;
				position?: string;
				search?: string;
				strongestFoot?: string;
				nationality?: string;
				order?: object;
			},
			options?: Omit<
				UseQueryOptions<
					ApiResponse<ClubPlayersResponse>,
					GenericWretchError<
						formerPlayersResponse400 | formerPlayersResponse403
					>
				>,
				"queryFn"
			>,
		): UseQueryResult<
			ApiResponse<ClubPlayersResponse>,
			GenericWretchError<formerPlayersResponse400 | formerPlayersResponse403>
		> => {
			const key = ["formerPlayers", params, ...(options?.queryKey || [])];
			const query: QueryFunction<ApiResponse<ClubPlayersResponse>> = async ({
				meta,
				queryKey,
				pageParam,
				signal,
			}) => {
				return queryFn(params, signal);
			};
			const invalidate = () => queryClient.invalidateQueries(key);
			const queryResult = useQuery<
				ApiResponse<ClubPlayersResponse>,
				GenericWretchError<formerPlayersResponse400 | formerPlayersResponse403>
			>(key, query, options || {}) as UseQueryResult<
				ApiResponse<ClubPlayersResponse>,
				GenericWretchError<formerPlayersResponse400 | formerPlayersResponse403>
			>;
			queryResult.invalidate = invalidate;
			queryResult.key = key;
			return queryResult;
		},
		useInfiniteQuery: (
			params: {
				clubId: number;
				page: number;
				limit: number;
				position?: string;
				search?: string;
				strongestFoot?: string;
				nationality?: string;
				order?: object;
			},
			options?: Omit<
				UseInfiniteQueryOptions<
					ApiResponse<ClubPlayersResponse>,
					GenericWretchError<
						formerPlayersResponse400 | formerPlayersResponse403
					>
				>,
				"queryFn"
			>,
		): UseInfiniteQueryResult<
			ApiResponse<ClubPlayersResponse>,
			GenericWretchError<formerPlayersResponse400 | formerPlayersResponse403>
		> => {
			const key = ["formerPlayers", params, ...(options?.queryKey || [])];
			const query: QueryFunction<ApiResponse<ClubPlayersResponse>> = async ({
				meta,
				queryKey,
				pageParam,
				signal,
			}) => {
				return queryFn(params, signal);
			};
			const invalidate = () => queryClient.invalidateQueries(key);
			const queryResult = useInfiniteQuery<
				ApiResponse<ClubPlayersResponse>,
				GenericWretchError<formerPlayersResponse400 | formerPlayersResponse403>
			>(key, query, options || {}) as UseInfiniteQueryResult<
				ApiResponse<ClubPlayersResponse>,
				GenericWretchError<formerPlayersResponse400 | formerPlayersResponse403>
			>;
			queryResult.invalidate = invalidate;
			queryResult.key = key;
			return queryResult;
		},
	};
};

const uploadPlayerPhoto = (wretch: Http) => {
	const mutation = async (params: { body: uploadPlayerPhoto_Body }) => {
		const args = z.object({ body: z.any() }).parse(params);
		const { body } = args;

		try {
			const res = await wretch
				.url("/admin/player/upload-profile-picture")
				.json(body)
				.put()
				.res();
			const json = await res.json();
			switch (res.status) {
				case 200: {
					const data = uploadPlayerPhotoResponse200.parse(json);
					return {
						status: res.status,
						data,
						headers: res.headers,
						url: res.url,
					};
				}
			}
		} catch (e) {
			const err = error(e);
			const json = err.json;
			switch (err.status) {
				case 400: {
					err.json = uploadPlayerPhotoResponse400.parse(json);
					break;
				}
				case 403: {
					err.json = uploadPlayerPhotoResponse403.parse(json);
					break;
				}
			}
			throw err;
		}

		invariant(false);
	};

	return {
		mutation,
		useMutation: (
			options?: Omit<
				UseMutationOptions<
					ApiResponse<uploadPlayerPhotoResponse200> | undefined,
					GenericWretchError<
						uploadPlayerPhotoResponse400 | uploadPlayerPhotoResponse403
					>,
					{ body: uploadPlayerPhoto_Body }
				>,
				"mutationFn"
			>,
		) => {
			return useMutation<
				ApiResponse<uploadPlayerPhotoResponse200> | undefined,
				GenericWretchError<
					uploadPlayerPhotoResponse400 | uploadPlayerPhotoResponse403
				>,
				{ body: uploadPlayerPhoto_Body }
			>(mutation, options || {});
		},
	};
};

const assignCoachesToTeam = (wretch: Http) => {
	const mutation = async (params: {
		id: number;
		body: assignCoachesToTeam_Body;
	}) => {
		const args = z
			.object({ id: z.number().int(), body: z.any() })
			.parse(params);
		const { id, body } = args;

		try {
			const res = await wretch
				.url(`/admin/team/${id}/assign-coaches`)
				.json(body)
				.post()
				.res();
			const json = await res.json();
			switch (res.status) {
				case 200: {
					const data = assignCoachesToTeamResponse200.parse(json);
					return {
						status: res.status,
						data,
						headers: res.headers,
						url: res.url,
					};
				}
			}
		} catch (e) {
			const err = error(e);
			const json = err.json;
			switch (err.status) {
				case 403: {
					err.json = assignCoachesToTeamResponse403.parse(json);
					break;
				}
			}
			throw err;
		}

		invariant(false);
	};

	return {
		mutation,
		useMutation: (
			options?: Omit<
				UseMutationOptions<
					ApiResponse<assignCoachesToTeamResponse200> | undefined,
					GenericWretchError<assignCoachesToTeamResponse403>,
					{ id: number; body: assignCoachesToTeam_Body }
				>,
				"mutationFn"
			>,
		) => {
			return useMutation<
				ApiResponse<assignCoachesToTeamResponse200> | undefined,
				GenericWretchError<assignCoachesToTeamResponse403>,
				{ id: number; body: assignCoachesToTeam_Body }
			>(mutation, options || {});
		},
	};
};

const getNationalities = (wretch: Http, queryClient: QueryClient) => {
	const queryFn = async (
		signal?: AbortSignal | undefined,
	): Promise<ApiResponse<getNationalitiesResponse200>> => {
		try {
			const res = await wretch
				.options({ signal })
				.url("/admin/api/nationalities")
				.get()
				.res();
			const json = await res.json();
			switch (res.status) {
				case 200: {
					const data = getNationalitiesResponse200.parse(json);
					return {
						status: res.status,
						data,
						headers: res.headers,
						url: res.url,
					};
				}
			}
		} catch (e) {
			const err = error(e);
			throw err;
		}

		invariant(false);
	};

	return {
		query: queryFn,
		useQuery: (
			options?: Omit<
				UseQueryOptions<
					ApiResponse<getNationalitiesResponse200>,
					GenericWretchError<null>
				>,
				"queryFn"
			>,
		): UseQueryResult<
			ApiResponse<getNationalitiesResponse200>,
			GenericWretchError<null>
		> => {
			const key = ["getNationalities", ...(options?.queryKey || [])];
			const query: QueryFunction<ApiResponse<getNationalitiesResponse200>> =
				async ({ meta, queryKey, pageParam, signal }) => {
					return queryFn(signal);
				};
			const invalidate = () => queryClient.invalidateQueries(key);
			const queryResult = useQuery<
				ApiResponse<getNationalitiesResponse200>,
				GenericWretchError<null>
			>(key, query, options || {}) as UseQueryResult<
				ApiResponse<getNationalitiesResponse200>,
				GenericWretchError<null>
			>;
			queryResult.invalidate = invalidate;
			queryResult.key = key;
			return queryResult;
		},
		useInfiniteQuery: (
			options?: Omit<
				UseInfiniteQueryOptions<
					ApiResponse<getNationalitiesResponse200>,
					GenericWretchError<null>
				>,
				"queryFn"
			>,
		): UseInfiniteQueryResult<
			ApiResponse<getNationalitiesResponse200>,
			GenericWretchError<null>
		> => {
			const key = ["getNationalities", ...(options?.queryKey || [])];
			const query: QueryFunction<ApiResponse<getNationalitiesResponse200>> =
				async ({ meta, queryKey, pageParam, signal }) => {
					return queryFn(signal);
				};
			const invalidate = () => queryClient.invalidateQueries(key);
			const queryResult = useInfiniteQuery<
				ApiResponse<getNationalitiesResponse200>,
				GenericWretchError<null>
			>(key, query, options || {}) as UseInfiniteQueryResult<
				ApiResponse<getNationalitiesResponse200>,
				GenericWretchError<null>
			>;
			queryResult.invalidate = invalidate;
			queryResult.key = key;
			return queryResult;
		},
	};
};

const getCategories = (wretch: Http, queryClient: QueryClient) => {
	const queryFn = async (
		signal?: AbortSignal | undefined,
	): Promise<ApiResponse<getCategoriesResponse200>> => {
		try {
			const res = await wretch
				.options({ signal })
				.url("/admin/api/categories")
				.get()
				.res();
			const json = await res.json();
			switch (res.status) {
				case 200: {
					const data = getCategoriesResponse200.parse(json);
					return {
						status: res.status,
						data,
						headers: res.headers,
						url: res.url,
					};
				}
			}
		} catch (e) {
			const err = error(e);
			throw err;
		}

		invariant(false);
	};

	return {
		query: queryFn,
		useQuery: (
			options?: Omit<
				UseQueryOptions<
					ApiResponse<getCategoriesResponse200>,
					GenericWretchError<null>
				>,
				"queryFn"
			>,
		): UseQueryResult<
			ApiResponse<getCategoriesResponse200>,
			GenericWretchError<null>
		> => {
			const key = ["getCategories", ...(options?.queryKey || [])];
			const query: QueryFunction<ApiResponse<getCategoriesResponse200>> =
				async ({ meta, queryKey, pageParam, signal }) => {
					return queryFn(signal);
				};
			const invalidate = () => queryClient.invalidateQueries(key);
			const queryResult = useQuery<
				ApiResponse<getCategoriesResponse200>,
				GenericWretchError<null>
			>(key, query, options || {}) as UseQueryResult<
				ApiResponse<getCategoriesResponse200>,
				GenericWretchError<null>
			>;
			queryResult.invalidate = invalidate;
			queryResult.key = key;
			return queryResult;
		},
		useInfiniteQuery: (
			options?: Omit<
				UseInfiniteQueryOptions<
					ApiResponse<getCategoriesResponse200>,
					GenericWretchError<null>
				>,
				"queryFn"
			>,
		): UseInfiniteQueryResult<
			ApiResponse<getCategoriesResponse200>,
			GenericWretchError<null>
		> => {
			const key = ["getCategories", ...(options?.queryKey || [])];
			const query: QueryFunction<ApiResponse<getCategoriesResponse200>> =
				async ({ meta, queryKey, pageParam, signal }) => {
					return queryFn(signal);
				};
			const invalidate = () => queryClient.invalidateQueries(key);
			const queryResult = useInfiniteQuery<
				ApiResponse<getCategoriesResponse200>,
				GenericWretchError<null>
			>(key, query, options || {}) as UseInfiniteQueryResult<
				ApiResponse<getCategoriesResponse200>,
				GenericWretchError<null>
			>;
			queryResult.invalidate = invalidate;
			queryResult.key = key;
			return queryResult;
		},
	};
};

const batchUpdate = (wretch: Http) => {
	const mutation = async (params: { body: batchUpdate_Body }) => {
		const args = z.object({ body: z.any() }).parse(params);
		const { body } = args;

		try {
			const res = await wretch
				.url("/admin/api/player/batch-update")
				.json(body)
				.post()
				.res();
			const json = await res.json();
			switch (res.status) {
				case 200: {
					const data = batchUpdateResponse200.parse(json);
					return {
						status: res.status,
						data,
						headers: res.headers,
						url: res.url,
					};
				}
			}
		} catch (e) {
			const err = error(e);
			const json = err.json;
			switch (err.status) {
				case 400: {
					err.json = batchUpdateResponse400.parse(json);
					break;
				}
				case 403: {
					err.json = batchUpdateResponse403.parse(json);
					break;
				}
			}
			throw err;
		}

		invariant(false);
	};

	return {
		mutation,
		useMutation: (
			options?: Omit<
				UseMutationOptions<
					ApiResponse<batchUpdateResponse200> | undefined,
					GenericWretchError<batchUpdateResponse400 | batchUpdateResponse403>,
					{ body: batchUpdate_Body }
				>,
				"mutationFn"
			>,
		) => {
			return useMutation<
				ApiResponse<batchUpdateResponse200> | undefined,
				GenericWretchError<batchUpdateResponse400 | batchUpdateResponse403>,
				{ body: batchUpdate_Body }
			>(mutation, options || {});
		},
	};
};

const generateSignUpLink = (wretch: Http) => {
	const mutation = async (params: { body: generateSignUpLinkRequestBody }) => {
		const args = z.object({ body: z.any() }).parse(params);
		const { body } = args;

		try {
			const res = await wretch
				.url("/admin/sign-up/player/generate-link")
				.json(body)
				.post()
				.res();
			const json = await res.json();
			switch (res.status) {
				case 200: {
					const data = generateSignUpLinkResponse200.parse(json);
					return {
						status: res.status,
						data,
						headers: res.headers,
						url: res.url,
					};
				}
			}
		} catch (e) {
			const err = error(e);
			const json = err.json;
			switch (err.status) {
				case 400: {
					err.json = generateSignUpLinkResponse400.parse(json);
					break;
				}
				case 403: {
					err.json = generateSignUpLinkResponse403.parse(json);
					break;
				}
				case 404: {
					err.json = generateSignUpLinkResponse404.parse(json);
					break;
				}
				case 409: {
					err.json = generateSignUpLinkResponse409.parse(json);
					break;
				}
			}
			throw err;
		}

		invariant(false);
	};

	return {
		mutation,
		useMutation: (
			options?: Omit<
				UseMutationOptions<
					ApiResponse<generateSignUpLinkResponse200> | undefined,
					GenericWretchError<
						| generateSignUpLinkResponse400
						| generateSignUpLinkResponse403
						| generateSignUpLinkResponse404
						| generateSignUpLinkResponse409
					>,
					{ body: generateSignUpLinkRequestBody }
				>,
				"mutationFn"
			>,
		) => {
			return useMutation<
				ApiResponse<generateSignUpLinkResponse200> | undefined,
				GenericWretchError<
					| generateSignUpLinkResponse400
					| generateSignUpLinkResponse403
					| generateSignUpLinkResponse404
					| generateSignUpLinkResponse409
				>,
				{ body: generateSignUpLinkRequestBody }
			>(mutation, options || {});
		},
	};
};

const getPlayerLatestLogEntries = (wretch: Http, queryClient: QueryClient) => {
	const queryFn = async (
		params: { id: number; type: string },
		signal?: AbortSignal | undefined,
	): Promise<ApiResponse<getPlayerLatestLogEntriesResponse200>> => {
		const args = z
			.object({ id: z.number().int(), type: z.string() })
			.parse(params);
		const { id, type } = args;
		try {
			const res = await wretch
				.options({ signal })
				.url("/admin/player/latest-updates")
				.query(qs.stringify({ id, type }))
				.get()
				.res();
			const json = await res.json();
			switch (res.status) {
				case 200: {
					const data = getPlayerLatestLogEntriesResponse200.parse(json);
					return {
						status: res.status,
						data,
						headers: res.headers,
						url: res.url,
					};
				}
			}
		} catch (e) {
			const err = error(e);
			throw err;
		}

		invariant(false);
	};

	return {
		query: queryFn,
		useQuery: (
			params: { id: number; type: string },
			options?: Omit<
				UseQueryOptions<
					ApiResponse<getPlayerLatestLogEntriesResponse200>,
					GenericWretchError<null>
				>,
				"queryFn"
			>,
		): UseQueryResult<
			ApiResponse<getPlayerLatestLogEntriesResponse200>,
			GenericWretchError<null>
		> => {
			const key = [
				"getPlayerLatestLogEntries",
				params,
				...(options?.queryKey || []),
			];
			const query: QueryFunction<
				ApiResponse<getPlayerLatestLogEntriesResponse200>
			> = async ({ meta, queryKey, pageParam, signal }) => {
				return queryFn(params, signal);
			};
			const invalidate = () => queryClient.invalidateQueries(key);
			const queryResult = useQuery<
				ApiResponse<getPlayerLatestLogEntriesResponse200>,
				GenericWretchError<null>
			>(key, query, options || {}) as UseQueryResult<
				ApiResponse<getPlayerLatestLogEntriesResponse200>,
				GenericWretchError<null>
			>;
			queryResult.invalidate = invalidate;
			queryResult.key = key;
			return queryResult;
		},
		useInfiniteQuery: (
			params: { id: number; type: string },
			options?: Omit<
				UseInfiniteQueryOptions<
					ApiResponse<getPlayerLatestLogEntriesResponse200>,
					GenericWretchError<null>
				>,
				"queryFn"
			>,
		): UseInfiniteQueryResult<
			ApiResponse<getPlayerLatestLogEntriesResponse200>,
			GenericWretchError<null>
		> => {
			const key = [
				"getPlayerLatestLogEntries",
				params,
				...(options?.queryKey || []),
			];
			const query: QueryFunction<
				ApiResponse<getPlayerLatestLogEntriesResponse200>
			> = async ({ meta, queryKey, pageParam, signal }) => {
				return queryFn(params, signal);
			};
			const invalidate = () => queryClient.invalidateQueries(key);
			const queryResult = useInfiniteQuery<
				ApiResponse<getPlayerLatestLogEntriesResponse200>,
				GenericWretchError<null>
			>(key, query, options || {}) as UseInfiniteQueryResult<
				ApiResponse<getPlayerLatestLogEntriesResponse200>,
				GenericWretchError<null>
			>;
			queryResult.invalidate = invalidate;
			queryResult.key = key;
			return queryResult;
		},
	};
};

const getVideoUploads = (wretch: Http, queryClient: QueryClient) => {
	const queryFn = async (
		params: {
			preset: string;
			page?: number;
			limit?: number;
			order?: object;
			filters?: object[];
		},
		signal?: AbortSignal | undefined,
	): Promise<ApiResponse<VideoUploadsPaginatedResponse>> => {
		const args = z
			.object({
				page: z.number().int().optional(),
				limit: z.number().int().optional(),
				order: z
					.object({
						name: z.enum(["name", "duration", "size"]).optional(),
						sort: z.enum(["asc", "desc"]).optional(),
					})
					.strict()
					.optional(),
				filters: z
					.array(
						z.object({
							field: z.string().describe("Field to filter on").optional(),
							type: z.string().describe("Type of filter").optional(),
							value: z
								.any()
								.superRefine((x, ctx) => {
									const schemas = [
										z.string(),
										z.number().int(),
										z.object({
											start: z.string().datetime({ offset: true }).optional(),
											end: z.string().datetime({ offset: true }).optional(),
										}),
									];
									const errors = schemas.reduce<z.ZodError[]>(
										(errors, schema) =>
											((result) =>
												result.error ? [...errors, result.error] : errors)(
												schema.safeParse(x),
											),
										[],
									);
									if (schemas.length - errors.length !== 1) {
										ctx.addIssue({
											path: ctx.path,
											code: "invalid_union",
											unionErrors: errors,
											message: "Invalid input: Should pass single schema",
										});
									}
								})
								.optional(),
						}),
					)
					.optional(),
				preset: z
					.string()
					.regex(
						new RegExp(
							"waiting-for-approval|approved|in-processing|errored|finished|",
						),
					),
			})
			.parse(params);
		const { preset, page, limit, order, filters } = args;
		try {
			const res = await wretch
				.options({ signal })
				.url(`/admin/video-uploads/${preset}`)
				.query(qs.stringify({ page, limit, order, filters }))
				.get()
				.res();
			const json = await res.json();
			switch (res.status) {
				case 200: {
					const data = VideoUploadsPaginatedResponse.parse(json);
					return {
						status: res.status,
						data,
						headers: res.headers,
						url: res.url,
					};
				}
			}
		} catch (e) {
			const err = error(e);
			throw err;
		}

		invariant(false);
	};

	return {
		query: queryFn,
		useQuery: (
			params: {
				preset: string;
				page?: number;
				limit?: number;
				order?: object;
				filters?: object[];
			},
			options?: Omit<
				UseQueryOptions<
					ApiResponse<VideoUploadsPaginatedResponse>,
					GenericWretchError<null>
				>,
				"queryFn"
			>,
		): UseQueryResult<
			ApiResponse<VideoUploadsPaginatedResponse>,
			GenericWretchError<null>
		> => {
			const key = ["getVideoUploads", params, ...(options?.queryKey || [])];
			const query: QueryFunction<ApiResponse<VideoUploadsPaginatedResponse>> =
				async ({ meta, queryKey, pageParam, signal }) => {
					return queryFn(params, signal);
				};
			const invalidate = () => queryClient.invalidateQueries(key);
			const queryResult = useQuery<
				ApiResponse<VideoUploadsPaginatedResponse>,
				GenericWretchError<null>
			>(key, query, options || {}) as UseQueryResult<
				ApiResponse<VideoUploadsPaginatedResponse>,
				GenericWretchError<null>
			>;
			queryResult.invalidate = invalidate;
			queryResult.key = key;
			return queryResult;
		},
		useInfiniteQuery: (
			params: {
				preset: string;
				page?: number;
				limit?: number;
				order?: object;
				filters?: object[];
			},
			options?: Omit<
				UseInfiniteQueryOptions<
					ApiResponse<VideoUploadsPaginatedResponse>,
					GenericWretchError<null>
				>,
				"queryFn"
			>,
		): UseInfiniteQueryResult<
			ApiResponse<VideoUploadsPaginatedResponse>,
			GenericWretchError<null>
		> => {
			const key = ["getVideoUploads", params, ...(options?.queryKey || [])];
			const query: QueryFunction<ApiResponse<VideoUploadsPaginatedResponse>> =
				async ({ meta, queryKey, pageParam, signal }) => {
					return queryFn(params, signal);
				};
			const invalidate = () => queryClient.invalidateQueries(key);
			const queryResult = useInfiniteQuery<
				ApiResponse<VideoUploadsPaginatedResponse>,
				GenericWretchError<null>
			>(key, query, options || {}) as UseInfiniteQueryResult<
				ApiResponse<VideoUploadsPaginatedResponse>,
				GenericWretchError<null>
			>;
			queryResult.invalidate = invalidate;
			queryResult.key = key;
			return queryResult;
		},
	};
};

const getVideo = (wretch: Http, queryClient: QueryClient) => {
	const queryFn = async (
		params: { id: number },
		signal?: AbortSignal | undefined,
	): Promise<ApiResponse<VideoUploadDetail>> => {
		const args = z
			.object({ id: z.number().int().describe("VideoUpload ID") })
			.parse(params);
		const { id } = args;
		try {
			const res = await wretch
				.options({ signal })
				.url(`/admin/video-uploads/${id}`)
				.get()
				.res();
			const json = await res.json();
			switch (res.status) {
				case 200: {
					const data = VideoUploadDetail.parse(json);
					return {
						status: res.status,
						data,
						headers: res.headers,
						url: res.url,
					};
				}
			}
		} catch (e) {
			const err = error(e);
			switch (err.status) {
				case 404: {
					break;
				}
			}
			throw err;
		}

		invariant(false);
	};

	return {
		query: queryFn,
		useQuery: (
			params: { id: number },
			options?: Omit<
				UseQueryOptions<
					ApiResponse<VideoUploadDetail>,
					GenericWretchError<null>
				>,
				"queryFn"
			>,
		): UseQueryResult<
			ApiResponse<VideoUploadDetail>,
			GenericWretchError<null>
		> => {
			const key = ["getVideo", params, ...(options?.queryKey || [])];
			const query: QueryFunction<ApiResponse<VideoUploadDetail>> = async ({
				meta,
				queryKey,
				pageParam,
				signal,
			}) => {
				return queryFn(params, signal);
			};
			const invalidate = () => queryClient.invalidateQueries(key);
			const queryResult = useQuery<
				ApiResponse<VideoUploadDetail>,
				GenericWretchError<null>
			>(key, query, options || {}) as UseQueryResult<
				ApiResponse<VideoUploadDetail>,
				GenericWretchError<null>
			>;
			queryResult.invalidate = invalidate;
			queryResult.key = key;
			return queryResult;
		},
		useInfiniteQuery: (
			params: { id: number },
			options?: Omit<
				UseInfiniteQueryOptions<
					ApiResponse<VideoUploadDetail>,
					GenericWretchError<null>
				>,
				"queryFn"
			>,
		): UseInfiniteQueryResult<
			ApiResponse<VideoUploadDetail>,
			GenericWretchError<null>
		> => {
			const key = ["getVideo", params, ...(options?.queryKey || [])];
			const query: QueryFunction<ApiResponse<VideoUploadDetail>> = async ({
				meta,
				queryKey,
				pageParam,
				signal,
			}) => {
				return queryFn(params, signal);
			};
			const invalidate = () => queryClient.invalidateQueries(key);
			const queryResult = useInfiniteQuery<
				ApiResponse<VideoUploadDetail>,
				GenericWretchError<null>
			>(key, query, options || {}) as UseInfiniteQueryResult<
				ApiResponse<VideoUploadDetail>,
				GenericWretchError<null>
			>;
			queryResult.invalidate = invalidate;
			queryResult.key = key;
			return queryResult;
		},
	};
};

const deleteVideoUpload = (wretch: Http) => {
	const mutation = async (params: { id: number }) => {
		const args = z
			.object({ id: z.number().int().describe("VideoUpload ID") })
			.parse(params);
		const { id } = args;

		try {
			const res = await wretch.url(`/admin/video-uploads/${id}`).delete().res();
			switch (res.status) {
				case 200: {
					return {
						status: res.status,
						data: null,
						headers: res.headers,
						url: res.url,
					};
				}
			}
		} catch (e) {
			const err = error(e);
			switch (err.status) {
				case 404: {
					break;
				}
			}
			throw err;
		}

		invariant(false);
	};

	return {
		mutation,
		useMutation: (
			options?: Omit<
				UseMutationOptions<
					ApiResponse<null> | undefined,
					GenericWretchError<null>,
					{ id: number }
				>,
				"mutationFn"
			>,
		) => {
			return useMutation<
				ApiResponse<null> | undefined,
				GenericWretchError<null>,
				{ id: number }
			>(mutation, options || {});
		},
	};
};

const addVideoMetadata = (wretch: Http) => {
	const mutation = async (params: {
		id: number;
		body: VideoMetadataRequest;
	}) => {
		const args = z
			.object({
				id: z.number().int().describe("VideoUpload ID"),
				body: z.any(),
			})
			.parse(params);
		const { id, body } = args;

		try {
			const res = await wretch
				.url(`/admin/video-uploads/${id}/add-metadata`)
				.json(body)
				.post()
				.res();
			switch (res.status) {
				case 200: {
					return {
						status: res.status,
						data: null,
						headers: res.headers,
						url: res.url,
					};
				}
			}
		} catch (e) {
			const err = error(e);
			throw err;
		}

		invariant(false);
	};

	return {
		mutation,
		useMutation: (
			options?: Omit<
				UseMutationOptions<
					ApiResponse<null> | undefined,
					GenericWretchError<null>,
					{ id: number; body: VideoMetadataRequest }
				>,
				"mutationFn"
			>,
		) => {
			return useMutation<
				ApiResponse<null> | undefined,
				GenericWretchError<null>,
				{ id: number; body: VideoMetadataRequest }
			>(mutation, options || {});
		},
	};
};

const approveVideoMetadata = (wretch: Http) => {
	const mutation = async (params: { id: number }) => {
		const args = z
			.object({ id: z.number().int().describe("VideoUpload ID") })
			.parse(params);
		const { id } = args;

		try {
			const res = await wretch
				.url(`/admin/video-uploads/${id}/metadata-approval`)
				.post()
				.res();
			switch (res.status) {
				case 200: {
					return {
						status: res.status,
						data: null,
						headers: res.headers,
						url: res.url,
					};
				}
			}
		} catch (e) {
			const err = error(e);
			throw err;
		}

		invariant(false);
	};

	return {
		mutation,
		useMutation: (
			options?: Omit<
				UseMutationOptions<
					ApiResponse<null> | undefined,
					GenericWretchError<null>,
					{ id: number }
				>,
				"mutationFn"
			>,
		) => {
			return useMutation<
				ApiResponse<null> | undefined,
				GenericWretchError<null>,
				{ id: number }
			>(mutation, options || {});
		},
	};
};

const removeApproveVideoMetadata = (wretch: Http) => {
	const mutation = async (params: { id: number }) => {
		const args = z
			.object({ id: z.number().int().describe("VideoUpload ID") })
			.parse(params);
		const { id } = args;

		try {
			const res = await wretch
				.url(`/admin/video-uploads/${id}/metadata-approval`)
				.delete()
				.res();
			switch (res.status) {
				case 200: {
					return {
						status: res.status,
						data: null,
						headers: res.headers,
						url: res.url,
					};
				}
			}
		} catch (e) {
			const err = error(e);
			throw err;
		}

		invariant(false);
	};

	return {
		mutation,
		useMutation: (
			options?: Omit<
				UseMutationOptions<
					ApiResponse<null> | undefined,
					GenericWretchError<null>,
					{ id: number }
				>,
				"mutationFn"
			>,
		) => {
			return useMutation<
				ApiResponse<null> | undefined,
				GenericWretchError<null>,
				{ id: number }
			>(mutation, options || {});
		},
	};
};

const getGamesWithoutVideo = (wretch: Http, queryClient: QueryClient) => {
	const queryFn = async (
		params: { page?: number; limit?: number; order?: string },
		signal?: AbortSignal | undefined,
	): Promise<ApiResponse<getGamesWithoutVideoResponse200>> => {
		const args = z
			.object({
				page: z.number().int().optional(),
				limit: z.number().int().optional(),
				order: z.enum(["ASC", "DESC"]).optional(),
			})
			.parse(params);
		const { page, limit, order } = args;
		try {
			const res = await wretch
				.options({ signal })
				.url("/admin/video-uploads/games/without-video")
				.query(qs.stringify({ page, limit, order }))
				.get()
				.res();
			const json = await res.json();
			switch (res.status) {
				case 200: {
					const data = getGamesWithoutVideoResponse200.parse(json);
					return {
						status: res.status,
						data,
						headers: res.headers,
						url: res.url,
					};
				}
			}
		} catch (e) {
			const err = error(e);
			throw err;
		}

		invariant(false);
	};

	return {
		query: queryFn,
		useQuery: (
			params: { page?: number; limit?: number; order?: string },
			options?: Omit<
				UseQueryOptions<
					ApiResponse<getGamesWithoutVideoResponse200>,
					GenericWretchError<null>
				>,
				"queryFn"
			>,
		): UseQueryResult<
			ApiResponse<getGamesWithoutVideoResponse200>,
			GenericWretchError<null>
		> => {
			const key = [
				"getGamesWithoutVideo",
				params,
				...(options?.queryKey || []),
			];
			const query: QueryFunction<ApiResponse<getGamesWithoutVideoResponse200>> =
				async ({ meta, queryKey, pageParam, signal }) => {
					return queryFn(params, signal);
				};
			const invalidate = () => queryClient.invalidateQueries(key);
			const queryResult = useQuery<
				ApiResponse<getGamesWithoutVideoResponse200>,
				GenericWretchError<null>
			>(key, query, options || {}) as UseQueryResult<
				ApiResponse<getGamesWithoutVideoResponse200>,
				GenericWretchError<null>
			>;
			queryResult.invalidate = invalidate;
			queryResult.key = key;
			return queryResult;
		},
		useInfiniteQuery: (
			params: { page?: number; limit?: number; order?: string },
			options?: Omit<
				UseInfiniteQueryOptions<
					ApiResponse<getGamesWithoutVideoResponse200>,
					GenericWretchError<null>
				>,
				"queryFn"
			>,
		): UseInfiniteQueryResult<
			ApiResponse<getGamesWithoutVideoResponse200>,
			GenericWretchError<null>
		> => {
			const key = [
				"getGamesWithoutVideo",
				params,
				...(options?.queryKey || []),
			];
			const query: QueryFunction<ApiResponse<getGamesWithoutVideoResponse200>> =
				async ({ meta, queryKey, pageParam, signal }) => {
					return queryFn(params, signal);
				};
			const invalidate = () => queryClient.invalidateQueries(key);
			const queryResult = useInfiniteQuery<
				ApiResponse<getGamesWithoutVideoResponse200>,
				GenericWretchError<null>
			>(key, query, options || {}) as UseInfiniteQueryResult<
				ApiResponse<getGamesWithoutVideoResponse200>,
				GenericWretchError<null>
			>;
			queryResult.invalidate = invalidate;
			queryResult.key = key;
			return queryResult;
		},
	};
};

const getVideoUploadsNotifications = (
	wretch: Http,
	queryClient: QueryClient,
) => {
	const queryFn = async (
		params: { limit?: number },
		signal?: AbortSignal | undefined,
	): Promise<ApiResponse<getVideoUploadsNotificationsResponse200>> => {
		const args = z.object({ limit: z.number().int().optional() }).parse(params);
		const { limit } = args;
		try {
			const res = await wretch
				.options({ signal })
				.url("/admin/video-uploads/notifications")
				.query(qs.stringify({ limit }))
				.get()
				.res();
			const json = await res.json();
			switch (res.status) {
				case 200: {
					const data = getVideoUploadsNotificationsResponse200.parse(json);
					return {
						status: res.status,
						data,
						headers: res.headers,
						url: res.url,
					};
				}
			}
		} catch (e) {
			const err = error(e);
			throw err;
		}

		invariant(false);
	};

	return {
		query: queryFn,
		useQuery: (
			params: { limit?: number },
			options?: Omit<
				UseQueryOptions<
					ApiResponse<getVideoUploadsNotificationsResponse200>,
					GenericWretchError<null>
				>,
				"queryFn"
			>,
		): UseQueryResult<
			ApiResponse<getVideoUploadsNotificationsResponse200>,
			GenericWretchError<null>
		> => {
			const key = [
				"getVideoUploadsNotifications",
				params,
				...(options?.queryKey || []),
			];
			const query: QueryFunction<
				ApiResponse<getVideoUploadsNotificationsResponse200>
			> = async ({ meta, queryKey, pageParam, signal }) => {
				return queryFn(params, signal);
			};
			const invalidate = () => queryClient.invalidateQueries(key);
			const queryResult = useQuery<
				ApiResponse<getVideoUploadsNotificationsResponse200>,
				GenericWretchError<null>
			>(key, query, options || {}) as UseQueryResult<
				ApiResponse<getVideoUploadsNotificationsResponse200>,
				GenericWretchError<null>
			>;
			queryResult.invalidate = invalidate;
			queryResult.key = key;
			return queryResult;
		},
		useInfiniteQuery: (
			params: { limit?: number },
			options?: Omit<
				UseInfiniteQueryOptions<
					ApiResponse<getVideoUploadsNotificationsResponse200>,
					GenericWretchError<null>
				>,
				"queryFn"
			>,
		): UseInfiniteQueryResult<
			ApiResponse<getVideoUploadsNotificationsResponse200>,
			GenericWretchError<null>
		> => {
			const key = [
				"getVideoUploadsNotifications",
				params,
				...(options?.queryKey || []),
			];
			const query: QueryFunction<
				ApiResponse<getVideoUploadsNotificationsResponse200>
			> = async ({ meta, queryKey, pageParam, signal }) => {
				return queryFn(params, signal);
			};
			const invalidate = () => queryClient.invalidateQueries(key);
			const queryResult = useInfiniteQuery<
				ApiResponse<getVideoUploadsNotificationsResponse200>,
				GenericWretchError<null>
			>(key, query, options || {}) as UseInfiniteQueryResult<
				ApiResponse<getVideoUploadsNotificationsResponse200>,
				GenericWretchError<null>
			>;
			queryResult.invalidate = invalidate;
			queryResult.key = key;
			return queryResult;
		},
	};
};

const getTestVideos = (wretch: Http, queryClient: QueryClient) => {
	const queryFn = async (
		signal?: AbortSignal | undefined,
	): Promise<ApiResponse<getTestVideosResponse200>> => {
		try {
			const res = await wretch
				.options({ signal })
				.url("/admin/video-uploads/test")
				.get()
				.res();
			const json = await res.json();
			switch (res.status) {
				case 200: {
					const data = getTestVideosResponse200.parse(json);
					return {
						status: res.status,
						data,
						headers: res.headers,
						url: res.url,
					};
				}
			}
		} catch (e) {
			const err = error(e);
			throw err;
		}

		invariant(false);
	};

	return {
		query: queryFn,
		useQuery: (
			options?: Omit<
				UseQueryOptions<
					ApiResponse<getTestVideosResponse200>,
					GenericWretchError<null>
				>,
				"queryFn"
			>,
		): UseQueryResult<
			ApiResponse<getTestVideosResponse200>,
			GenericWretchError<null>
		> => {
			const key = ["getTestVideos", ...(options?.queryKey || [])];
			const query: QueryFunction<ApiResponse<getTestVideosResponse200>> =
				async ({ meta, queryKey, pageParam, signal }) => {
					return queryFn(signal);
				};
			const invalidate = () => queryClient.invalidateQueries(key);
			const queryResult = useQuery<
				ApiResponse<getTestVideosResponse200>,
				GenericWretchError<null>
			>(key, query, options || {}) as UseQueryResult<
				ApiResponse<getTestVideosResponse200>,
				GenericWretchError<null>
			>;
			queryResult.invalidate = invalidate;
			queryResult.key = key;
			return queryResult;
		},
		useInfiniteQuery: (
			options?: Omit<
				UseInfiniteQueryOptions<
					ApiResponse<getTestVideosResponse200>,
					GenericWretchError<null>
				>,
				"queryFn"
			>,
		): UseInfiniteQueryResult<
			ApiResponse<getTestVideosResponse200>,
			GenericWretchError<null>
		> => {
			const key = ["getTestVideos", ...(options?.queryKey || [])];
			const query: QueryFunction<ApiResponse<getTestVideosResponse200>> =
				async ({ meta, queryKey, pageParam, signal }) => {
					return queryFn(signal);
				};
			const invalidate = () => queryClient.invalidateQueries(key);
			const queryResult = useInfiniteQuery<
				ApiResponse<getTestVideosResponse200>,
				GenericWretchError<null>
			>(key, query, options || {}) as UseInfiniteQueryResult<
				ApiResponse<getTestVideosResponse200>,
				GenericWretchError<null>
			>;
			queryResult.invalidate = invalidate;
			queryResult.key = key;
			return queryResult;
		},
	};
};

const getVideoGameDetail = (wretch: Http, queryClient: QueryClient) => {
	const queryFn = async (
		params: { id: number },
		signal?: AbortSignal | undefined,
	): Promise<ApiResponse<GameDetail>> => {
		const args = z
			.object({ id: z.number().int().describe("Game ID") })
			.parse(params);
		const { id } = args;
		try {
			const res = await wretch
				.options({ signal })
				.url(`/admin/video-uploads/game-detail/${id}`)
				.get()
				.res();
			const json = await res.json();
			switch (res.status) {
				case 200: {
					const data = GameDetail.parse(json);
					return {
						status: res.status,
						data,
						headers: res.headers,
						url: res.url,
					};
				}
			}
		} catch (e) {
			const err = error(e);
			switch (err.status) {
				case 404: {
					break;
				}
			}
			throw err;
		}

		invariant(false);
	};

	return {
		query: queryFn,
		useQuery: (
			params: { id: number },
			options?: Omit<
				UseQueryOptions<ApiResponse<GameDetail>, GenericWretchError<null>>,
				"queryFn"
			>,
		): UseQueryResult<ApiResponse<GameDetail>, GenericWretchError<null>> => {
			const key = ["getVideoGameDetail", params, ...(options?.queryKey || [])];
			const query: QueryFunction<ApiResponse<GameDetail>> = async ({
				meta,
				queryKey,
				pageParam,
				signal,
			}) => {
				return queryFn(params, signal);
			};
			const invalidate = () => queryClient.invalidateQueries(key);
			const queryResult = useQuery<
				ApiResponse<GameDetail>,
				GenericWretchError<null>
			>(key, query, options || {}) as UseQueryResult<
				ApiResponse<GameDetail>,
				GenericWretchError<null>
			>;
			queryResult.invalidate = invalidate;
			queryResult.key = key;
			return queryResult;
		},
		useInfiniteQuery: (
			params: { id: number },
			options?: Omit<
				UseInfiniteQueryOptions<
					ApiResponse<GameDetail>,
					GenericWretchError<null>
				>,
				"queryFn"
			>,
		): UseInfiniteQueryResult<
			ApiResponse<GameDetail>,
			GenericWretchError<null>
		> => {
			const key = ["getVideoGameDetail", params, ...(options?.queryKey || [])];
			const query: QueryFunction<ApiResponse<GameDetail>> = async ({
				meta,
				queryKey,
				pageParam,
				signal,
			}) => {
				return queryFn(params, signal);
			};
			const invalidate = () => queryClient.invalidateQueries(key);
			const queryResult = useInfiniteQuery<
				ApiResponse<GameDetail>,
				GenericWretchError<null>
			>(key, query, options || {}) as UseInfiniteQueryResult<
				ApiResponse<GameDetail>,
				GenericWretchError<null>
			>;
			queryResult.invalidate = invalidate;
			queryResult.key = key;
			return queryResult;
		},
	};
};

const videoProcessingReset = (wretch: Http) => {
	const mutation = async (params: { id: number }) => {
		const args = z
			.object({ id: z.number().int().describe("VideoUpload ID") })
			.parse(params);
		const { id } = args;

		try {
			const res = await wretch
				.url(`/admin/video-uploads/${id}/processing-reset`)
				.post()
				.res();
			switch (res.status) {
				case 200: {
					return {
						status: res.status,
						data: null,
						headers: res.headers,
						url: res.url,
					};
				}
			}
		} catch (e) {
			const err = error(e);
			throw err;
		}

		invariant(false);
	};

	return {
		mutation,
		useMutation: (
			options?: Omit<
				UseMutationOptions<
					ApiResponse<null> | undefined,
					GenericWretchError<null>,
					{ id: number }
				>,
				"mutationFn"
			>,
		) => {
			return useMutation<
				ApiResponse<null> | undefined,
				GenericWretchError<null>,
				{ id: number }
			>(mutation, options || {});
		},
	};
};

const videoProcessingRetry = (wretch: Http) => {
	const mutation = async (params: { id: number }) => {
		const args = z
			.object({ id: z.number().int().describe("VideoUpload ID") })
			.parse(params);
		const { id } = args;

		try {
			const res = await wretch
				.url(`/admin/video-uploads/${id}/processing-retry`)
				.post()
				.res();
			switch (res.status) {
				case 200: {
					return {
						status: res.status,
						data: null,
						headers: res.headers,
						url: res.url,
					};
				}
			}
		} catch (e) {
			const err = error(e);
			throw err;
		}

		invariant(false);
	};

	return {
		mutation,
		useMutation: (
			options?: Omit<
				UseMutationOptions<
					ApiResponse<null> | undefined,
					GenericWretchError<null>,
					{ id: number }
				>,
				"mutationFn"
			>,
		) => {
			return useMutation<
				ApiResponse<null> | undefined,
				GenericWretchError<null>,
				{ id: number }
			>(mutation, options || {});
		},
	};
};

const migratePlayers = (wretch: Http) => {
	const mutation = async (params: {
		id: number;
		body: migratePlayers_Body;
	}) => {
		const args = z
			.object({ id: z.number().int(), body: z.any() })
			.parse(params);
		const { id, body } = args;

		try {
			const res = await wretch
				.url(`/admin/club/${id}/team/migrate-players`)
				.json(body)
				.post()
				.res();
			const json = await res.json();
			switch (res.status) {
				case 200: {
					const data = migratePlayersResponse200.parse(json);
					return {
						status: res.status,
						data,
						headers: res.headers,
						url: res.url,
					};
				}
			}
		} catch (e) {
			const err = error(e);
			const json = err.json;
			switch (err.status) {
				case 400: {
					err.json = migratePlayersResponse400.parse(json);
					break;
				}
				case 403: {
					err.json = migratePlayersResponse403.parse(json);
					break;
				}
			}
			throw err;
		}

		invariant(false);
	};

	return {
		mutation,
		useMutation: (
			options?: Omit<
				UseMutationOptions<
					ApiResponse<migratePlayersResponse200> | undefined,
					GenericWretchError<
						migratePlayersResponse400 | migratePlayersResponse403
					>,
					{ id: number; body: migratePlayers_Body }
				>,
				"mutationFn"
			>,
		) => {
			return useMutation<
				ApiResponse<migratePlayersResponse200> | undefined,
				GenericWretchError<
					migratePlayersResponse400 | migratePlayersResponse403
				>,
				{ id: number; body: migratePlayers_Body }
			>(mutation, options || {});
		},
	};
};

const getClubDetail = (wretch: Http, queryClient: QueryClient) => {
	const queryFn = async (
		params: { clubId: number },
		signal?: AbortSignal | undefined,
	): Promise<ApiResponse<ClubDetailResponseDTO>> => {
		const args = z.object({ clubId: z.number().int() }).parse(params);
		const { clubId } = args;
		try {
			const res = await wretch
				.options({ signal })
				.url(`/admin/api/club/${clubId}`)
				.get()
				.res();
			const json = await res.json();
			switch (res.status) {
				case 200: {
					const data = ClubDetailResponseDTO.parse(json);
					return {
						status: res.status,
						data,
						headers: res.headers,
						url: res.url,
					};
				}
			}
		} catch (e) {
			const err = error(e);
			const json = err.json;
			switch (err.status) {
				case 400: {
					err.json = getClubDetailResponse400.parse(json);
					break;
				}
				case 403: {
					err.json = getClubDetailResponse403.parse(json);
					break;
				}
			}
			throw err;
		}

		invariant(false);
	};

	return {
		query: queryFn,
		useQuery: (
			params: { clubId: number },
			options?: Omit<
				UseQueryOptions<
					ApiResponse<ClubDetailResponseDTO>,
					GenericWretchError<
						getClubDetailResponse400 | getClubDetailResponse403
					>
				>,
				"queryFn"
			>,
		): UseQueryResult<
			ApiResponse<ClubDetailResponseDTO>,
			GenericWretchError<getClubDetailResponse400 | getClubDetailResponse403>
		> => {
			const key = ["getClubDetail", params, ...(options?.queryKey || [])];
			const query: QueryFunction<ApiResponse<ClubDetailResponseDTO>> = async ({
				meta,
				queryKey,
				pageParam,
				signal,
			}) => {
				return queryFn(params, signal);
			};
			const invalidate = () => queryClient.invalidateQueries(key);
			const queryResult = useQuery<
				ApiResponse<ClubDetailResponseDTO>,
				GenericWretchError<getClubDetailResponse400 | getClubDetailResponse403>
			>(key, query, options || {}) as UseQueryResult<
				ApiResponse<ClubDetailResponseDTO>,
				GenericWretchError<getClubDetailResponse400 | getClubDetailResponse403>
			>;
			queryResult.invalidate = invalidate;
			queryResult.key = key;
			return queryResult;
		},
		useInfiniteQuery: (
			params: { clubId: number },
			options?: Omit<
				UseInfiniteQueryOptions<
					ApiResponse<ClubDetailResponseDTO>,
					GenericWretchError<
						getClubDetailResponse400 | getClubDetailResponse403
					>
				>,
				"queryFn"
			>,
		): UseInfiniteQueryResult<
			ApiResponse<ClubDetailResponseDTO>,
			GenericWretchError<getClubDetailResponse400 | getClubDetailResponse403>
		> => {
			const key = ["getClubDetail", params, ...(options?.queryKey || [])];
			const query: QueryFunction<ApiResponse<ClubDetailResponseDTO>> = async ({
				meta,
				queryKey,
				pageParam,
				signal,
			}) => {
				return queryFn(params, signal);
			};
			const invalidate = () => queryClient.invalidateQueries(key);
			const queryResult = useInfiniteQuery<
				ApiResponse<ClubDetailResponseDTO>,
				GenericWretchError<getClubDetailResponse400 | getClubDetailResponse403>
			>(key, query, options || {}) as UseInfiniteQueryResult<
				ApiResponse<ClubDetailResponseDTO>,
				GenericWretchError<getClubDetailResponse400 | getClubDetailResponse403>
			>;
			queryResult.invalidate = invalidate;
			queryResult.key = key;
			return queryResult;
		},
	};
};

type Http = ReturnType<AdminApiClient["createHttp"]>;

export class AdminApiClient {
	// biome-ignore lint/suspicious/noExplicitAny: <explanation>
	private operations: Map<string, any>;

	public http: Http;
	public queryClient: QueryClient;
	private onHttpConfigure: (wretch: Http) => Http;

	/**
				Login user via json
			*/
	declare jsonLogin: ReturnType<typeof jsonLogin>;

	/**
				Get currently logged in user
			*/
	declare getCurrentUser: ReturnType<typeof getCurrentUser>;

	/**
				Logout user via json
			*/
	declare adminJsonLogout: ReturnType<typeof adminJsonLogout>;

	/**
				Update User Locale
			*/
	declare setUserLocale: ReturnType<typeof setUserLocale>;

	/**
				request for changing password
			*/
	declare forgotPassword: ReturnType<typeof forgotPassword>;

	/**
				reset a password
			*/
	declare resetPassword: ReturnType<typeof resetPassword>;

	/**
				check if the user token is valid or not
			*/
	declare validateUserPasswordReset: ReturnType<
		typeof validateUserPasswordReset
	>;

	/**
				Get user&#39;s selected countries
			*/
	declare getSelectedCountries: ReturnType<typeof getSelectedCountries>;

	/**
				Get all countries
			*/
	declare getAllCountries: ReturnType<typeof getAllCountries>;

	declare updateSelectedCountries: ReturnType<typeof updateSelectedCountries>;

	/**
				Get subscription country message
			*/
	declare getSubscriptionCountryMessage: ReturnType<
		typeof getSubscriptionCountryMessage
	>;

	/**
				Remove the countries session details
			*/
	declare removeCountriesSessionDetails: ReturnType<
		typeof removeCountriesSessionDetails
	>;

	/**
				Get the leagues based on category
			*/
	declare searchNationalLeagues: ReturnType<typeof searchNationalLeagues>;

	/**
				Get all permissions
			*/
	declare getPermissionTree: ReturnType<typeof getPermissionTree>;

	/**
				Get the permission by role
			*/
	declare getPermissionByRole: ReturnType<typeof getPermissionByRole>;

	/**
				Assign the permission to role
			*/
	declare assignPermissionToRole: ReturnType<typeof assignPermissionToRole>;

	/**
				Delete the role
			*/
	declare deleteRole: ReturnType<typeof deleteRole>;

	/**
				Get all roles
			*/
	declare getRoles: ReturnType<typeof getRoles>;

	/**
				Get user roles
			*/
	declare getUserRoles: ReturnType<typeof getUserRoles>;

	/**
				Add and update the role
			*/
	declare addUpdateRole: ReturnType<typeof addUpdateRole>;

	/**
				Get all admin users
			*/
	declare getAllAdminUsers: ReturnType<typeof getAllAdminUsers>;

	/**
				create and update admin users
			*/
	declare addUpdateAdminUsers: ReturnType<typeof addUpdateAdminUsers>;

	/**
				Delete admin
			*/
	declare deleteAdminUser: ReturnType<typeof deleteAdminUser>;

	/**
				Upload photo for Admin
			*/
	declare uploadProfilePhoto: ReturnType<typeof uploadProfilePhoto>;

	/**
				Get all poles
			*/
	declare getAllPoles: ReturnType<typeof getAllPoles>;

	/**
				create and update pole
			*/
	declare addUpdatePole: ReturnType<typeof addUpdatePole>;

	/**
				Get timezones
			*/
	declare getTimezones: ReturnType<typeof getTimezones>;

	/**
				delete a pole
			*/
	declare deletePole: ReturnType<typeof deletePole>;

	/**
				get selected poles by club
			*/
	declare getSelectedPoleByClub: ReturnType<typeof getSelectedPoleByClub>;

	/**
				assign pole to club
			*/
	declare assignPole: ReturnType<typeof assignPole>;

	/**
				unassign pole from club
			*/
	declare unAssignPole: ReturnType<typeof unAssignPole>;

	/**
				sync pole
			*/
	declare syncPole: ReturnType<typeof syncPole>;

	/**
				Get club list
			*/
	declare getClubList: ReturnType<typeof getClubList>;

	/**
				Get non partner countries
			*/
	declare getCountries: ReturnType<typeof getCountries>;

	/**
				Get continent country list
			*/
	declare getContinentCountry: ReturnType<typeof getContinentCountry>;

	/**
				Get non partner clubs
			*/
	declare getNPClubs: ReturnType<typeof getNPClubs>;

	/**
				create and update np club
			*/
	declare addUpdateNPClub: ReturnType<typeof addUpdateNPClub>;

	/**
				delete np club
			*/
	declare deleteNPClub: ReturnType<typeof deleteNPClub>;

	/**
				Upload photo for NP Club
			*/
	declare uploadNPClubPhoto: ReturnType<typeof uploadNPClubPhoto>;

	/**
				Upload photo for player
			*/
	declare uploadNPPlayerPicture: ReturnType<typeof uploadNPPlayerPicture>;

	/**
				Get non partner player
			*/
	declare getNPPlayers: ReturnType<typeof getNPPlayers>;

	/**
				Get nationality list
			*/
	declare getNationalityList: ReturnType<typeof getNationalityList>;

	/**
				Get position list
			*/
	declare getPositionList: ReturnType<typeof getPositionList>;

	/**
				Get geographical area list
			*/
	declare getGeographicalAreaList: ReturnType<typeof getGeographicalAreaList>;

	/**
				create and update np player
			*/
	declare addUpdateNPPlayer: ReturnType<typeof addUpdateNPPlayer>;

	/**
				delete np player
			*/
	declare deleteNPPlayer: ReturnType<typeof deleteNPPlayer>;

	/**
				Get non partner clubs
			*/
	declare getAllNPClubs: ReturnType<typeof getAllNPClubs>;

	/**
				Get players by non partner and partner club id
			*/
	declare getClubPlayers: ReturnType<typeof getClubPlayers>;

	/**
				Execute non partner club and player migration 
			*/
	declare executeNPClubMigration: ReturnType<typeof executeNPClubMigration>;

	/**
				get Teams by target club 
			*/
	declare getTeamsByTargetClub: ReturnType<typeof getTeamsByTargetClub>;

	/**
				Check Player Exists or not in Target club and perform migration
			*/
	declare playerExistsTargetClub: ReturnType<typeof playerExistsTargetClub>;

	/**
				get Teams by target club 
			*/
	declare getMigrationStatus: ReturnType<typeof getMigrationStatus>;

	/**
				Execute non partner club and player migration 
			*/
	declare executePartnerClubMigration: ReturnType<
		typeof executePartnerClubMigration
	>;

	/**
				Create Partner Player from Nonpartner player details when migrate
			*/
	declare createPartnerPlayerFromNonpartner: ReturnType<
		typeof createPartnerPlayerFromNonpartner
	>;

	/**
				Check NP Player Exists or not in Target club and perform migration
			*/
	declare partnerPlayerExistsTargetClub: ReturnType<
		typeof partnerPlayerExistsTargetClub
	>;

	/**
				get Teams by target club 
			*/
	declare getPartnerMigrationStatus: ReturnType<
		typeof getPartnerMigrationStatus
	>;

	/**
				get Teams by target club 
			*/
	declare updateGameTeam: ReturnType<typeof updateGameTeam>;

	/**
				Delete source club if no players are available when migrate
			*/
	declare migrateSourceClubNoPlayers: ReturnType<
		typeof migrateSourceClubNoPlayers
	>;

	/**
				Begin Migration
			*/
	declare beginMigration: ReturnType<typeof beginMigration>;

	/**
				Execute Club Migration With Zero Players
			*/
	declare clubMigrationWithZeroPlayers: ReturnType<
		typeof clubMigrationWithZeroPlayers
	>;

	/**
				Execute Complete Migration
			*/
	declare completeMigration: ReturnType<typeof completeMigration>;

	/**
				When Migrating to Partner Club a Method to Create / Update the Player
		is required. This method is called once user selects the Team for the source Player to complete
		the Migration
			*/
	declare playerMigrationToPartner: ReturnType<typeof playerMigrationToPartner>;

	declare playerMigrationConflict: ReturnType<typeof playerMigrationConflict>;

	/**
				Get all Countries Tooltip
			*/
	declare getAllCountriesTooltip: ReturnType<typeof getAllCountriesTooltip>;

	/**
				Add and Update country information
			*/
	declare addUpdateCountry: ReturnType<typeof addUpdateCountry>;

	/**
				Delete country information
			*/
	declare deleteCountry: ReturnType<typeof deleteCountry>;

	/**
				Get all countries
			*/
	declare allCountries: ReturnType<typeof allCountries>;

	/**
				Enabling or disabling the country
			*/
	declare enableDisableCountry: ReturnType<typeof enableDisableCountry>;

	/**
				Get all subscriptions
			*/
	declare loadSubscriptionData: ReturnType<typeof loadSubscriptionData>;

	/**
				Get all ownership
			*/
	declare loadOwnerships: ReturnType<typeof loadOwnerships>;

	/**
				Get subscription filter
			*/
	declare getSubscriptionFilterData: ReturnType<
		typeof getSubscriptionFilterData
	>;

	/**
				Get subscription filter
			*/
	declare addUpdateSubscription: ReturnType<typeof addUpdateSubscription>;

	/**
				assign subscription to club
			*/
	declare assignSubscriptionToClub: ReturnType<typeof assignSubscriptionToClub>;

	/**
				delete subscription
			*/
	declare deleteSubscription: ReturnType<typeof deleteSubscription>;

	/**
				Get all specific and Generic Notifications
			*/
	declare getAllSpecificGenericNotifications: ReturnType<
		typeof getAllSpecificGenericNotifications
	>;

	/**
				Get all notification instants under notification job
			*/
	declare getNotificationInstants: ReturnType<typeof getNotificationInstants>;

	/**
				retry the Failed Notification
			*/
	declare retryFailedNotification: ReturnType<typeof retryFailedNotification>;

	/**
				Add a new specific or generic notification
			*/
	declare addSpecificGenericNotification: ReturnType<
		typeof addSpecificGenericNotification
	>;

	/**
				test a new specific or generic notification
			*/
	declare testSpecificGenericNotification: ReturnType<
		typeof testSpecificGenericNotification
	>;

	/**
				refresh the notification instants by Job
			*/
	declare refreshJobNotification: ReturnType<typeof refreshJobNotification>;

	/**
				Get All Languages
			*/
	declare getAllLanguages: ReturnType<typeof getAllLanguages>;

	/**
				search the user by name or email
			*/
	declare userSearch: ReturnType<typeof userSearch>;

	/**
				search the amateur club by name
			*/
	declare clubAmateurSearch: ReturnType<typeof clubAmateurSearch>;

	/**
				search the pro club by name
			*/
	declare clubProSearch: ReturnType<typeof clubProSearch>;

	/**
				Get All Subscription Products
			*/
	declare getAllSubscriptionProducts: ReturnType<
		typeof getAllSubscriptionProducts
	>;

	declare getUserDetail: ReturnType<typeof getUserDetail>;

	declare getUserSessions: ReturnType<typeof getUserSessions>;

	declare revokeSessions: ReturnType<typeof revokeSessions>;

	declare getUserSignIns: ReturnType<typeof getUserSignIns>;

	/**
				Log User Activity
			*/
	declare logUserActivity: ReturnType<typeof logUserActivity>;

	/**
				Get user club all teams
			*/
	declare getClubAllTeams: ReturnType<typeof getClubAllTeams>;

	/**
				Get team by id and coach id
			*/
	declare getTeamByIdAndCoachId: ReturnType<typeof getTeamByIdAndCoachId>;

	/**
				Add and Update the Team
			*/
	declare addUpdateTeam: ReturnType<typeof addUpdateTeam>;

	/**
				Delete the Team by id
			*/
	declare deleteTeam: ReturnType<typeof deleteTeam>;

	/**
				Get Coaches of the Club
			*/
	declare clubCoaches: ReturnType<typeof clubCoaches>;

	/**
				Get user club teams for dropdown
			*/
	declare getClubTeamsList: ReturnType<typeof getClubTeamsList>;

	/**
				Get Team Players
			*/
	declare teamPlayers: ReturnType<typeof teamPlayers>;

	/**
				Add and Update the Player
			*/
	declare addUpdatePlayer: ReturnType<typeof addUpdatePlayer>;

	/**
				update vip to the Player by id
			*/
	declare updateVip: ReturnType<typeof updateVip>;

	/**
				update level to the team by id
			*/
	declare updateLevel: ReturnType<typeof updateLevel>;

	/**
				Delete the Player by id
			*/
	declare deletePlayer: ReturnType<typeof deletePlayer>;

	/**
				Get old or non team players in the club
			*/
	declare formerPlayers: ReturnType<typeof formerPlayers>;

	/**
				Upload photo for Player
			*/
	declare uploadPlayerPhoto: ReturnType<typeof uploadPlayerPhoto>;

	/**
				Assign and unassign multiple coaches to the team
			*/
	declare assignCoachesToTeam: ReturnType<typeof assignCoachesToTeam>;

	/**
				Get nationality list
			*/
	declare getNationalities: ReturnType<typeof getNationalities>;

	/**
				Get category list
			*/
	declare getCategories: ReturnType<typeof getCategories>;

	/**
				Update Batch Players
			*/
	declare batchUpdate: ReturnType<typeof batchUpdate>;

	/**
				generate sign up link for specific player
			*/
	declare generateSignUpLink: ReturnType<typeof generateSignUpLink>;

	/**
				Retrieve the latest updated values of a player&#39;s height, weight and photo.
			*/
	declare getPlayerLatestLogEntries: ReturnType<
		typeof getPlayerLatestLogEntries
	>;

	declare getVideoUploads: ReturnType<typeof getVideoUploads>;

	declare getVideo: ReturnType<typeof getVideo>;

	declare deleteVideoUpload: ReturnType<typeof deleteVideoUpload>;

	declare addVideoMetadata: ReturnType<typeof addVideoMetadata>;

	declare approveVideoMetadata: ReturnType<typeof approveVideoMetadata>;

	declare removeApproveVideoMetadata: ReturnType<
		typeof removeApproveVideoMetadata
	>;

	declare getGamesWithoutVideo: ReturnType<typeof getGamesWithoutVideo>;

	declare getVideoUploadsNotifications: ReturnType<
		typeof getVideoUploadsNotifications
	>;

	declare getTestVideos: ReturnType<typeof getTestVideos>;

	declare getVideoGameDetail: ReturnType<typeof getVideoGameDetail>;

	declare videoProcessingReset: ReturnType<typeof videoProcessingReset>;

	declare videoProcessingRetry: ReturnType<typeof videoProcessingRetry>;

	/**
				Migrate Players to the selected Team
			*/
	declare migratePlayers: ReturnType<typeof migratePlayers>;

	/**
				Get club detail
			*/
	declare getClubDetail: ReturnType<typeof getClubDetail>;

	constructor(
		baseUrl: string,
		queryClient: QueryClient,
		options: Pick<RequestInit, "headers" | "credentials" | "mode"> = {},
		onHttpConfigure: AdminApiClient["onHttpConfigure"] = (wretch) => wretch,
	) {
		this.queryClient = queryClient;
		this.onHttpConfigure = onHttpConfigure;
		this.operations = new Map();

		this.operations.set("jsonLogin", jsonLogin);

		this.operations.set("getCurrentUser", getCurrentUser);

		this.operations.set("adminJsonLogout", adminJsonLogout);

		this.operations.set("setUserLocale", setUserLocale);

		this.operations.set("forgotPassword", forgotPassword);

		this.operations.set("resetPassword", resetPassword);

		this.operations.set("validateUserPasswordReset", validateUserPasswordReset);

		this.operations.set("getSelectedCountries", getSelectedCountries);

		this.operations.set("getAllCountries", getAllCountries);

		this.operations.set("updateSelectedCountries", updateSelectedCountries);

		this.operations.set(
			"getSubscriptionCountryMessage",
			getSubscriptionCountryMessage,
		);

		this.operations.set(
			"removeCountriesSessionDetails",
			removeCountriesSessionDetails,
		);

		this.operations.set("searchNationalLeagues", searchNationalLeagues);

		this.operations.set("getPermissionTree", getPermissionTree);

		this.operations.set("getPermissionByRole", getPermissionByRole);

		this.operations.set("assignPermissionToRole", assignPermissionToRole);

		this.operations.set("deleteRole", deleteRole);

		this.operations.set("getRoles", getRoles);

		this.operations.set("getUserRoles", getUserRoles);

		this.operations.set("addUpdateRole", addUpdateRole);

		this.operations.set("getAllAdminUsers", getAllAdminUsers);

		this.operations.set("addUpdateAdminUsers", addUpdateAdminUsers);

		this.operations.set("deleteAdminUser", deleteAdminUser);

		this.operations.set("uploadProfilePhoto", uploadProfilePhoto);

		this.operations.set("getAllPoles", getAllPoles);

		this.operations.set("addUpdatePole", addUpdatePole);

		this.operations.set("getTimezones", getTimezones);

		this.operations.set("deletePole", deletePole);

		this.operations.set("getSelectedPoleByClub", getSelectedPoleByClub);

		this.operations.set("assignPole", assignPole);

		this.operations.set("unAssignPole", unAssignPole);

		this.operations.set("syncPole", syncPole);

		this.operations.set("getClubList", getClubList);

		this.operations.set("getCountries", getCountries);

		this.operations.set("getContinentCountry", getContinentCountry);

		this.operations.set("getNPClubs", getNPClubs);

		this.operations.set("addUpdateNPClub", addUpdateNPClub);

		this.operations.set("deleteNPClub", deleteNPClub);

		this.operations.set("uploadNPClubPhoto", uploadNPClubPhoto);

		this.operations.set("uploadNPPlayerPicture", uploadNPPlayerPicture);

		this.operations.set("getNPPlayers", getNPPlayers);

		this.operations.set("getNationalityList", getNationalityList);

		this.operations.set("getPositionList", getPositionList);

		this.operations.set("getGeographicalAreaList", getGeographicalAreaList);

		this.operations.set("addUpdateNPPlayer", addUpdateNPPlayer);

		this.operations.set("deleteNPPlayer", deleteNPPlayer);

		this.operations.set("getAllNPClubs", getAllNPClubs);

		this.operations.set("getClubPlayers", getClubPlayers);

		this.operations.set("executeNPClubMigration", executeNPClubMigration);

		this.operations.set("getTeamsByTargetClub", getTeamsByTargetClub);

		this.operations.set("playerExistsTargetClub", playerExistsTargetClub);

		this.operations.set("getMigrationStatus", getMigrationStatus);

		this.operations.set(
			"executePartnerClubMigration",
			executePartnerClubMigration,
		);

		this.operations.set(
			"createPartnerPlayerFromNonpartner",
			createPartnerPlayerFromNonpartner,
		);

		this.operations.set(
			"partnerPlayerExistsTargetClub",
			partnerPlayerExistsTargetClub,
		);

		this.operations.set("getPartnerMigrationStatus", getPartnerMigrationStatus);

		this.operations.set("updateGameTeam", updateGameTeam);

		this.operations.set(
			"migrateSourceClubNoPlayers",
			migrateSourceClubNoPlayers,
		);

		this.operations.set("beginMigration", beginMigration);

		this.operations.set(
			"clubMigrationWithZeroPlayers",
			clubMigrationWithZeroPlayers,
		);

		this.operations.set("completeMigration", completeMigration);

		this.operations.set("playerMigrationToPartner", playerMigrationToPartner);

		this.operations.set("playerMigrationConflict", playerMigrationConflict);

		this.operations.set("getAllCountriesTooltip", getAllCountriesTooltip);

		this.operations.set("addUpdateCountry", addUpdateCountry);

		this.operations.set("deleteCountry", deleteCountry);

		this.operations.set("allCountries", allCountries);

		this.operations.set("enableDisableCountry", enableDisableCountry);

		this.operations.set("loadSubscriptionData", loadSubscriptionData);

		this.operations.set("loadOwnerships", loadOwnerships);

		this.operations.set("getSubscriptionFilterData", getSubscriptionFilterData);

		this.operations.set("addUpdateSubscription", addUpdateSubscription);

		this.operations.set("assignSubscriptionToClub", assignSubscriptionToClub);

		this.operations.set("deleteSubscription", deleteSubscription);

		this.operations.set(
			"getAllSpecificGenericNotifications",
			getAllSpecificGenericNotifications,
		);

		this.operations.set("getNotificationInstants", getNotificationInstants);

		this.operations.set("retryFailedNotification", retryFailedNotification);

		this.operations.set(
			"addSpecificGenericNotification",
			addSpecificGenericNotification,
		);

		this.operations.set(
			"testSpecificGenericNotification",
			testSpecificGenericNotification,
		);

		this.operations.set("refreshJobNotification", refreshJobNotification);

		this.operations.set("getAllLanguages", getAllLanguages);

		this.operations.set("userSearch", userSearch);

		this.operations.set("clubAmateurSearch", clubAmateurSearch);

		this.operations.set("clubProSearch", clubProSearch);

		this.operations.set(
			"getAllSubscriptionProducts",
			getAllSubscriptionProducts,
		);

		this.operations.set("getUserDetail", getUserDetail);

		this.operations.set("getUserSessions", getUserSessions);

		this.operations.set("revokeSessions", revokeSessions);

		this.operations.set("getUserSignIns", getUserSignIns);

		this.operations.set("logUserActivity", logUserActivity);

		this.operations.set("getClubAllTeams", getClubAllTeams);

		this.operations.set("getTeamByIdAndCoachId", getTeamByIdAndCoachId);

		this.operations.set("addUpdateTeam", addUpdateTeam);

		this.operations.set("deleteTeam", deleteTeam);

		this.operations.set("clubCoaches", clubCoaches);

		this.operations.set("getClubTeamsList", getClubTeamsList);

		this.operations.set("teamPlayers", teamPlayers);

		this.operations.set("addUpdatePlayer", addUpdatePlayer);

		this.operations.set("updateVip", updateVip);

		this.operations.set("updateLevel", updateLevel);

		this.operations.set("deletePlayer", deletePlayer);

		this.operations.set("formerPlayers", formerPlayers);

		this.operations.set("uploadPlayerPhoto", uploadPlayerPhoto);

		this.operations.set("assignCoachesToTeam", assignCoachesToTeam);

		this.operations.set("getNationalities", getNationalities);

		this.operations.set("getCategories", getCategories);

		this.operations.set("batchUpdate", batchUpdate);

		this.operations.set("generateSignUpLink", generateSignUpLink);

		this.operations.set("getPlayerLatestLogEntries", getPlayerLatestLogEntries);

		this.operations.set("getVideoUploads", getVideoUploads);

		this.operations.set("getVideo", getVideo);

		this.operations.set("deleteVideoUpload", deleteVideoUpload);

		this.operations.set("addVideoMetadata", addVideoMetadata);

		this.operations.set("approveVideoMetadata", approveVideoMetadata);

		this.operations.set(
			"removeApproveVideoMetadata",
			removeApproveVideoMetadata,
		);

		this.operations.set("getGamesWithoutVideo", getGamesWithoutVideo);

		this.operations.set(
			"getVideoUploadsNotifications",
			getVideoUploadsNotifications,
		);

		this.operations.set("getTestVideos", getTestVideos);

		this.operations.set("getVideoGameDetail", getVideoGameDetail);

		this.operations.set("videoProcessingReset", videoProcessingReset);

		this.operations.set("videoProcessingRetry", videoProcessingRetry);

		this.operations.set("migratePlayers", migratePlayers);

		this.operations.set("getClubDetail", getClubDetail);

		const http = this.configureHttp(baseUrl, options);
		this.http = http;

		// eslint-disable-next-line
		// biome-ignore lint/correctness/noConstructorReturn: <explanation>
		return new Proxy(this, {
			get(target, name: string) {
				// eslint-disable-next-line
				// biome-ignore lint/suspicious/noPrototypeBuiltins: <explanation>
				if (target.hasOwnProperty(name)) {
					//@ts-ignore
					return target[name];
				}

				return target.operations.get(name)(http, queryClient);
			},
		});
	}

	private createHttp(
		baseUrl: string,
		options: Pick<RequestInit, "headers" | "credentials" | "mode"> = {},
	) {
		return wretch(baseUrl, options).addon(QueryStringAddon);
	}

	private configureHttp(
		baseUrl: string,
		options: Pick<RequestInit, "headers" | "credentials" | "mode"> = {},
	) {
		return this.onHttpConfigure(this.createHttp(baseUrl, options));
	}
}
